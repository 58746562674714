<template>
    <div class="modal-wrapper"
         :class="{hide: loggedIn || modals.register || modals.newPassword || modals.forgotPassword, newDesign: newDesign, 'theme_3-modal-design': theme_3_Design}"
         :style="updateLoginBg()"
    >
        <div class="modal" :class="{hide: modals.otp}">
            <div class="modal-box">
                <a v-if="theme_3_Design"
                   :href="!logoRedirectOff ? website : '#'"
                   :target="!logoRedirectOff ? '_blank' : ''"
                   :style="{cursor: !logoRedirectOff ? '' : 'default', 'pointer-events': hideLogo ? 'none' : 'auto'}"
                   class="theme_3-logo"
                >
                    <img :src="logoComputed" :style="{height: logo.height, opacity: hideLogo ? 0 : 1}" :class="{hide: !logo.url}"/>
                    <span :class="{hide: logo.url}"><span class="brand">{{ tokens.header.cfd }}</span> {{ tokens.header.platform }}</span>
                </a>
                <div class="tabs" v-if="theme_3_Design">
                    <a href="/" :class="{'is-active': !loggedIn}">Login</a>
                    <a v-if="!showRegisterModal" href="/register" :class="{'is-active': modals.register}">Register</a>
                </div>
                <div class="title" v-if="!theme_3_Design">
                    <i v-if="newDesign" class="material-icons">person_outline</i>
                    <span>{{ tokens.loginModal.loginToTradeRoom.toUpperCase() }}</span>
                    <i v-if="!newDesign" class="material-icons">lock</i>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-row input">
                            <label v-if="!theme_3_Design">{{ tokens.loginModal.email }}</label>
                            <input class="form-control" name="email" autocomplete="off" tabindex="1" v-model="email"
                                   :placeholder="tokens.loginModal.enterEmail" @keyup.enter="login"/>
                            <div class="icon-box" v-if="theme_3_Design">
                                <img :src="staticBase+`new-design/phone.png`" alt="">
                            </div>
                        </div>
                        <div v-if="!newDesign" class="form-row input">
                            <label>{{ tokens.loginModal.twoFactor }}</label>
                            <input class="form-control pass" name="twoFactorAuth" autocomplete="off" tabindex="3"
                                   v-model="otp"
                                   :placeholder="tokens.loginModal.twoFactorAuth" @keyup.enter="login"/>
                        </div>
                        <div v-if="!hideForgotPassword && !newDesign" class="form-row input">
                            <span class="forgot pointer" @click="showForgot">{{
                                    tokens.forgotPasswordModal.forgotPassword
                                }}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-row input">
                            <label v-if="!theme_3_Design">{{ tokens.loginModal.password }}</label>
                            <div class="show-hide-password">
                                <input class="form-control pass" ref="password" name="password" :type="passwordVisible ? 'text' : 'password'"
                                       autocomplete="off" tabindex="2"
                                       v-model="password" :placeholder="tokens.loginModal.yourPassword"
                                       @keyup.enter="login"/>
                                <i class="material-icons"
                                   v-if="!theme_3_Design"
                                   @touchstart="passwordVisible = !passwordVisible"
                                   @mousedown="passwordVisible = !passwordVisible">{{passwordVisible ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                            <div class="icon-box" v-if="theme_3_Design">
                                <i class="material-icons" style="color: #fff"
                                   @touchstart="passwordVisible = !passwordVisible"
                                   @mousedown="passwordVisible = !passwordVisible">{{passwordVisible ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                        </div>
                        <div class="form-row" :class="{last: newDesign}">
                            <label>&nbsp;</label>
                            <div class="button green-button" v-on:click="login" style="margin-top: 0">
                                {{ tokens.loginModal.signIn.toUpperCase() }}
                            </div>
                        </div>
                        <div class="form-row footer">
                            <p v-if="!hideForgotPassword">
                                {{
                                    tokens.forgotPasswordModal.forgotPassword
                                }}
                                <span @click="showForgot">{{ tokens.loginModal.restore }}</span>
                            </p>
                            <p v-if="!showRegisterModal && !showRegisterText">
                                {{ tokens.loginModal.dontHaveAccount }}
                                <a href="/register">{{ tokens.fullScreenReferrals.registered }}</a>
                            </p>
                        </div>
                        <div class="apps" v-if="apps">
                            <a :href="item.path" v-for="(item, n) in appsData" :key="n">
                                <img :src="staticBase+`misc/${item.icon}`">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TwoFactorAuthenticationModal v-if="newDesign" :class="{hide: !modals.otp}" :email="this.email"
                                      :password="this.password"/>
    </div>
</template>

<script>
import {
    APPS,
    BRAND,
    HIDE_FORGOT_PASSWORD,
    NEW_DESIGN,
    SHOW_REGISTER_MODAL,
    SHOW_REGISTER_TEXT,
    THEME_3_DESIGN,
    WEBSITE,
    TRADINGVIEW_THEME, LOGO, HIDE_LOGO, STATIC_BASE, LOGO_REDIRECT_OFF, LOGIN_BG
} from "@/common/config";
import {mapGetters} from 'vuex'
import {LOGIN, PRE_LOGIN} from "@/store/actions.type";
import {MODAL} from "@/store/mutations.type";
import TwoFactorAuthenticationModal from "@/components/Modals/TwoFactorAuthenticationModal";
import {hidePass, showPass} from "@/common/helpers";

export default {
    name: "LoginModal",
    components: {
        TwoFactorAuthenticationModal
    },
    data() {
        return {
            newDesign: NEW_DESIGN,
            hideForgotPassword: HIDE_FORGOT_PASSWORD,
            email: '',
            password: '',
            passwordVisible: false,
            otp: '',
            passwordFieldType: 'password',
            showRegisterModal: SHOW_REGISTER_MODAL,
            showRegisterText: SHOW_REGISTER_TEXT,
            apps: APPS,
            brand: BRAND,
            theme_3_Design: THEME_3_DESIGN,
            website: WEBSITE,
            tradingViewTheme: TRADINGVIEW_THEME,
            logo: LOGO,
            hideLogo: HIDE_LOGO,
            staticBase: STATIC_BASE,
            logoRedirectOff: LOGO_REDIRECT_OFF,
            loginBg: LOGIN_BG
        }
    },
    methods: {
        showPass,
        hidePass,
        login() {
            this.newDesign ?
                this.$store.dispatch(PRE_LOGIN, {
                    email: this.email.trim(),
                    password: this.password.trim(),
                    otp: this.otp
                }) : this.$store.dispatch(LOGIN, {
                    email: this.email.trim(),
                    password: this.password.trim(),
                    otp: this.otp
                })
        },
        showForgot() {
            this.$store.commit(MODAL, {
                forgotPassword: true
            })
        },
        updateLoginBg() {
            if (this.newDesign) {
                return this.loginBg ? {backgroundImage: `url(${this.loginBg})`} : {
                    backgroundImage: `url(${this.staticBase}new-design/login-bg.png)`
                }

            }
            return this.loginBg || ''
        }
    },
    computed: {
        ...mapGetters(['loggedIn', 'modals', 'tokens']),
        appsData() {
            let resultingArray = [],
                icons = ['AnyDesk-logo', 'TeamViewer-logo', 'aeroadmin'],
                paths = ['https://anydesk.com/download', 'https://teamviewer.com/download', 'https://www.aeroadmin.com'],
                path = '',
                icon = ''

            for (const item in this.apps) {
                icon = `${icons[item]}.png`
                path = `${paths[item]}`

                resultingArray.push({icon, path})
            }

            return resultingArray
        },
        logoComputed() {
            return this.tradingViewTheme === 'Light' ? this.logo.url.replace(/white/, 'black') : this.logo.url
        }
    },
    watch: {
        loggedIn(val) {
            if (val) {
                this.email = ''
                this.password = ''
                this.otp = ''
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper,
.modal-otp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newDesign .row {
    margin-bottom: 10px;
    flex-direction: column;
}

.newDesign .row .col {
    margin-right: 0;
}

.newDesign .last {
    margin-top: -20px;
}

.newDesign .modal-otp .modal {
    width: 400px;
}

.newDesign .modal-otp .modal label {
    color: var(--white);
}

.newDesign .modal {
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--color-bg-login);
    border-radius: 10px;
    padding: 30px 40px;
    border: 1px solid #CECECE;
    z-index: 1100;
}

.newDesign .form-row {
    margin-bottom: 20px;
}

.newDesign .form-init {
    width: 100%;
    margin-bottom: 10px;
}

.newDesign ::placeholder {
    color: transparent;
}

.newDesign .title {
    justify-content: flex-start;
    gap: 5px;
}

.newDesign label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.newDesign input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.newDesign .button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    cursor: pointer;
    background: var(--color-bg-login-btn);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}

.newDesign .footer {
    align-items: center;
}

.newDesign .footer p {
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.6);
}

.newDesign .footer p:first-child {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
}

.newDesign .footer p:last-child {
    margin-bottom: 0;
    text-align: center;
}

.newDesign .footer span:last-child,
.newDesign .footer p a {
    text-decoration: underline;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--white);
    margin-left: 5px;
    cursor: pointer;
}


.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.forgot {
    border-bottom: 1px dashed var(--font);
}

.apps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
}

.apps img {
    width: 30px;
}

.theme_3-modal-design.modal-wrapper {
    justify-content: flex-end;
}

.theme_3-modal-design .modal {
    width: 660px;
    height: 100%;
    justify-content: center;
    padding: 20px 140px;
    position: relative;
    background: transparent;
    border: none;
}

.theme_3-modal-design .search-country,
.theme_3-modal-design .form-control {
    padding: 0 10px;
}

.theme_3-modal-design .modal::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1E1F25;
    opacity: 0.75;
    backdrop-filter: blur(32px);
    z-index: -1;
}

.theme_3-logo {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0 0 35px;
}

.theme_3-modal-design .show-hide-password {
    background: transparent;
}

.theme_3-modal-design .search-country,
.theme_3-modal-design .form-control{
    background-color: transparent;
}

.theme_3-modal-design .col {
    margin: 0;
}

.theme_3-modal-design .form-icon.form-row,
.theme_3-modal-design .form-row {
    position: relative;
    margin-bottom: 20px;
}

.theme_3-modal-design .form-icon .select {
    font-size: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 2;
}

.theme_3-modal-design .show-hide-password i {
    right: 20px;
}

.theme_3-modal-design input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
}

.theme_3-modal-design .button {
    margin: 55px 0 5px 0;
    padding: 16px 0;
    background: linear-gradient(265.56deg, #30E0A1 -0.27%, #1A82FF -0.26%, #1E02C7 98.59%);
    border-radius: 100px;
}

.theme_3-modal-design .currency {
    margin-left: 35px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
}

.theme_3-modal-design .form-icon img {
    width: 32px;
    height: 25px;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    z-index: 2;
}

.theme_3-modal-design .icon-box img {
    width: auto;
    height: auto;
    position: relative;
    transform: none;
    left: 0;
    top: 0;
}

.theme_3-modal-design .form-image {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 0 48px;
    position: relative;
}

.theme_3-modal-design option {
    color: #fff;
}

.tabs {
    margin-bottom: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.tabs a {
    font-weight: 600;
    font-size: 24px;
    color: var(--white);
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.tabs a.is-active {
    color: #1A82FF;
    font-weight: 600;
}

.tabs a:first-child{
    padding-right: 25px;
}

.tabs a:last-child {
    padding-left: 25px;
}

.tabs a:nth-child(2)::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: var(--white);
}

.theme_3-modal-design .country-list {
    top: 50px;
}

.theme_3-modal-design .country-list,
.theme_3-modal-design .form-row.input,
.theme_3-modal-design .form-image {
    background: #5D6588;
}

.theme_3-modal-design .form-row.input {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 30px;
    place-items: center;
    height: 60px;
    border: 1px solid #34384C;
    border-radius: 100px;
    padding: 0 21px;
    font-weight: 400;
    font-size: 16px;
}

.theme_3-modal-design .icon-box {
    position: relative;
    padding-left: 10px;
}

.theme_3-modal-design .icon-box::after{
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: var(--white);
}

.icon-box img {
    cursor: pointer;
}

.mobile .theme_3-modal-design .modal {
    padding: 20px;
}
</style>
