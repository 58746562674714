<template>
    <div class="chat"
         :class="{over: ddOver }"
         v-cloak
         @drop.prevent="e => addFile(e)"
         @dragover.prevent
         @dragenter="over(true)"
         @dragleave="over(false)"
         @dragover="over(true)"
         @keyup.esc="toggler = false"
    >
        <div class="messages-list" >
            <div class="scroll" id="support-messages">
                <h4 v-if="!Object.keys(supportMessages).length" class="title title__medium">{{tokens.newDesign.howCanIAssist}}</h4>
                <template v-if="!Object.keys(supportMessages).length">
                    <MessageChipsList
                        v-for="(chips, index) in messageChips"
                        :key="index"
                        :messages="chips.chips"
                        @send-message="send"
                    >
                        <template #title>
                            {{ chips.title }}
                        </template>
                    </MessageChipsList>
                </template>
                <div class="message-list">
                    <MessageBox
                        v-for="message in supportMessages"
                        :key="message.time_created + Math.random()"
                        :message="message"
                    >
                        <template #copy="{message}">
                            <BaseIconButton
                                v-if="message.text !== ''"
                                @click.native="copy(message.text)"
                                :icon="'content_copy'"
                            >
                                <template #icon>
                                    <i class="material-symbols-outlined copy">content_copy</i>
                                </template>
                            </BaseIconButton>
                        </template>
                    </MessageBox>
                </div>
            </div>
        </div>
        <ChatBar/>
    </div>
</template>

<script>
import MessageChipsList from "@/new-design/entities/LiveChat/ui/MessageChipsList.vue"
import ChatBar from "@/new-design/features/LiveChat/ChatBar/ui/ChatBar.vue"
import {mapGetters} from "vuex"
import MessageBox from "@/new-design/entities/LiveChat/ui/MessageBox.vue"
import {REST_ENDPOINT, STATIC_BASE, BRAND} from "@/common/config"
import {NOTIFICATION, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {ATTACHMENT_UPLOAD, SUPPORT_READ, SUPPORT_SEND} from "@/store/actions.type";
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue";

export default {
    name: "LiveChat",
    components: {BaseIconButton, MessageBox, ChatBar, MessageChipsList},
    mounted() {
        setInterval(() => {
            if (this.modalFullscreen.page === 'support') {
                this.$store.dispatch(SUPPORT_READ)
            }
        }, 3000)
        setTimeout(() => {
            let container = document.getElementById("support-messages")
            container.scrollTop = container.scrollHeight
        }, 300)
    },
    data() {
        return {
            ddOver: false,
            toggler: false,
            staticBase: STATIC_BASE,
            restEndpoint: REST_ENDPOINT,
            brand: BRAND
        }
    },
    methods: {
        over(val) {
            this.ddOver = val
        },
        addFile(e) {
            this.ddOver = false

            if (!e.dataTransfer?.files) {
                return
            }

            let data = new FormData()

            data.append('file', e.dataTransfer?.files[0])

            this.$store.dispatch(ATTACHMENT_UPLOAD, data)
        },
        send(message) {
            if (!message.trim()) {
                return
            }
            // send message
            if (this.user.enable_chat === 0) {
                this.$store.commit(NOTIFICATION_ERROR, this.tokens.errors.CHAT_DISABLED)
                message = ''
                this.$refs?.file && (this.$refs.file.value = '')
                this.preview = ''
                this.previewShow = false
                return
            }

            if (message) {
                this.$store.dispatch(SUPPORT_SEND, message)
            }

            // send attached file:
            if (this.$refs.file?.files[0]) {
                if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(this.$refs.file.files[0].type) > -1) {
                    let data = new FormData()
                    data.append('file', this.$refs.file.files[0])
                    this.$store.dispatch(ATTACHMENT_UPLOAD, data)
                } else {
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
                }
            }

            this.$refs?.file && (this.$refs.file.value = '')
            this.preview = ''
            this.previewShow = false
        },
        copy(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$store.commit(NOTIFICATION, this.tokens.newDesign.messageCopied)
                })
        },
    },
    computed: {
        messageChips() {
            let messageChips = [
              {
                  title: this.tokens.newDesign.accountBalance,
                  chips: [this.tokens.newDesign.accountBalanceText1, this.tokens.newDesign.accountsBalanceText2]
              },
              {
                  title: this.tokens.newDesign.tradingActions,
                  chips: [this.tokens.newDesign.tradingActionsText1, this.tokens.newDesign.tradingActionsText2, this.tokens.newDesign.tradingActionsText3]
              },
              {
                  title: this.tokens.newDesign.marketInfo,
                  chips: [this.tokens.newDesign.marketInfoText1, this.tokens.newDesign.marketInfoText2]
              },
              {
                  title: this.tokens.newDesign.securityAndSettings,
                  chips: [this.tokens.newDesign.securityAndSettingsText1, this.tokens.newDesign.securityAndSettingsText2, this.tokens.newDesign.securityAndSettingsText3]
              }
          ]

            if(['stage','rdpdeals','horizoncore','lumixadept','trilonex'].includes(this.brand)){
                messageChips[0].chips.push(this.tokens.newDesign.accountsBalanceText3)
            }

          return messageChips;
        },
        ...mapGetters(['supportMessages', 'user', 'modalFullscreen', 'tokens'])
    }
}
</script>

<style scoped>
.chat {
    display: grid;
    grid-template-rows: 1fr max-content;
    overflow: hidden;
    gap: 8px;
}

.messages-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;
    height: 100%;
    overflow-y: auto;
}

.title {
    color: var(--System-Secondary);
    padding: 20px 0;
}

.copy {
    font-size: 14px;
    color: var(--System-OnSurfaceVariant);
}

@media (max-width: 781px) {
    .messages-list {
        padding: 8px;
    }
}
</style>
