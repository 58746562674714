<template>
    <div class="verification-page">
        <div class="verification-page__inner">
            <div class="verification-page__header">
                <BasePageTitle :title="tokens.newDesign.verification" />
                <BaseButton
                    v-if="privacyPolicyEnv"
                    :button-style="'on-surface privacy-policy__button'"
                    :button-text="'Privacy Policy'"
                    @click.native="changePrivacyPolicyState" >
                    <template #icon>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="icon">
                            <path id="Vector" d="M12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 12.5167 19.7583 13.8792 19.275 15.1875C18.7917 16.4958 18.1 17.65 17.2 18.65L14 15.45C13.7 15.6333 13.3792 15.7708 13.0375 15.8625C12.6958 15.9542 12.35 16 12 16C10.9 16 9.95833 15.6083 9.175 14.825C8.39167 14.0417 8 13.1 8 12C8 10.9 8.39167 9.95833 9.175 9.175C9.95833 8.39167 10.9 8 12 8C13.1 8 14.0417 8.39167 14.825 9.175C15.6083 9.95833 16 10.9 16 12C16 12.3667 15.9542 12.7208 15.8625 13.0625C15.7708 13.4042 15.6333 13.7333 15.45 14.05L16.95 15.55C17.2833 14.8667 17.5417 14.15 17.725 13.4C17.9083 12.65 18 11.8833 18 11.1V6.375L12 4.125L6 6.375V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9C12.4333 19.7667 12.8458 19.5958 13.2375 19.3875C13.6292 19.1792 14.0167 18.9333 14.4 18.65L15.8 20.05C15.25 20.5 14.6542 20.8917 14.0125 21.225C13.3708 21.5583 12.7 21.8167 12 22ZM12 14C12.55 14 13.0208 13.8042 13.4125 13.4125C13.8042 13.0208 14 12.55 14 12C14 11.45 13.8042 10.9792 13.4125 10.5875C13.0208 10.1958 12.55 10 12 10C11.45 10 10.9792 10.1958 10.5875 10.5875C10.1958 10.9792 10 11.45 10 12C10 12.55 10.1958 13.0208 10.5875 13.4125C10.9792 13.8042 11.45 14 12 14Z" fill="var(--System-OnSurfaceVariant)"/>
                            </g>
                        </svg>
                    </template>
                </BaseButton>
            </div>
            <BasePanel v-show="!isActivePrivacyPolicy">
                <template #content>
                    <div v-if="categoriesItems.length">
                        <ProgressChipsList
                            :categoriesItems="categoriesItems"
                            :active="activeItem || categoriesItems[0].id"
                            @update="(e) => changeActiveitem(e)" />
            
                        <div class="inner"
                            v-for="tab in categoriesItems.filter((data) => data.id === activeItem)"
                            :key="tab.id">
                                <template v-if="tab.id === 'questionnaire'">
                                    <div class="questionnaire">
                                        <h5 class="title title__medium questionnaire__title">
                                            {{ tokens.newDesign.questionnaire }}
                                        </h5>
                                        <div class="questionnaire__subtitle">
                                            {{ tokens.newDesign.questionnaireText }}
                                        </div>
                                        <div class="questionnaire__inner">
                                            <div v-for="question in questions"
                                                :key="question.id"
                                                class="questionnaire__question"
                                            >
                                                <div class="questionnaire__question-label label__largeprominent">
                                                    {{ question.question }}
                                                </div>
                                                <BaseInput
                                                    v-model="question.answer"
                                                    type="text"
                                                    class="questionnaire__question-input"
                                                    placeholder="Enter your answer"
                                                />
                                            </div>
                                        </div>
                                        <BaseButton
                                            :buttonText="tokens.newDesign.sendAnswers"
                                            :buttonStyle="'filled-primary'"
                                            class="questionnaire__button"
                                            @click.native="sendAnswers"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="info" v-html="infoText[activeItem]"></div>
                                    <div class="update-document-list">
                                        <UpdateDocument
                                            v-for="(docData, i) in documentsData.filter(data => data.tabId === activeItem)"
                                            :key="'docData' + i"
                                            :document="docData"
                                            :idx="i" />
                                    </div>
                                </template>
                        </div>
                    </div>
                    <div v-else>
                        You currently have no available verification steps. Please contact your manager.
                    </div>
                    <div class="documents-table">
                        <div class="table-title label__largeprominent">{{ tokens.fullScreenVerification.documentsList }}</div>
                        <div class="table-desc body__extrasmall">{{tokens.newDesign.toCompleteVerification}}</div>
                        <UploadedDocumentList />
                    </div>
                </template>
            </BasePanel>
            <PrivacyPolicy 
                v-if="privacyPolicyEnv && isActivePrivacyPolicy"
                @close="changePrivacyPolicyState"
                />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { NOTIFICATION } from "@/store/mutations.type"
import RestService from "@/common/rest.service"
import {
    BRAND,
    KYC_DOCUMENTS,
    NEW_KYC_DOCUMENT_TEXT,
    DISABLE_QUESTIONS,
    PRIVACY_POLICY
} from "@/common/config"
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle.vue"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import ProgressChipsList from "@/new-design/entities/Verification/ui/ProgressChipsList"
import UpdateDocument from "@/new-design/features/Verification/ui/UpdateDocument"
import UploadedDocumentList from "@/new-design/entities/Verification/ui/UploadedDocumentList.vue";
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput"
import BaseButton from '@/new-design/shared/ui/buttons/BaseButton'
import PrivacyPolicy from '@/new-design/pages/verification/ui/PrivacyPolicy'

export default {
    name: "PersonalInformationPage",
    components: { BasePageTitle, BasePanel,ProgressChipsList,UpdateDocument, UploadedDocumentList, BaseInput, BaseButton, PrivacyPolicy },
    data() {
        return {
            kycDocuments: KYC_DOCUMENTS,
            newVerificationText: NEW_KYC_DOCUMENT_TEXT,
            disableQuestions: DISABLE_QUESTIONS,
            brand: BRAND,
            active: '',
            isActivePrivacyPolicy: false,
            privacyPolicyEnv: PRIVACY_POLICY
        }
    },
    computed: {
        ...mapGetters(['documents', 'tokens', 'questions','variables']),
        infoText() {
            return {
                document:
                    `${this.tokens.newDesign.governmentIssuedPhoto1}<br />
                    ${this.tokens.newDesign.governmentIssuedPhoto2}<br />
                    ${this.tokens.newDesign.governmentIssuedPhoto3}<br />
                    ${this.tokens.newDesign.governmentIssuedPhoto4}`,
                address:
                    `${this.tokens.newDesign.uploadRecent1}<br />
                    ${this.tokens.newDesign.uploadRecent2}`,
                card:
                    `${this.tokens.newDesign.uploadCopy1}<br />
                    ${this.tokens.newDesign.uploadCopy2}`,
                selfie:
                    `${this.tokens.newDesign.takeAndUpload1}<br />
                    ${this.tokens.newDesign.takeAndUpload2}`,
                other: this.brand === 'denalitrdgroupltd' ? `${this.tokens.newDesign.ssnDocuments}` : `${this.tokens.newDesign.otherDocuments}`,
            }
        },
        documentsData() {
            let resultingArray = [],
                docs = this.documents,
                over = false,
                status,
                type,
                tabId

            this.kycDocuments.forEach((value) => {
                // let typeToken = this.tokens.fullScreenVerification[this.newVerificationText ? 'types2' : 'types'][value]

                let docsOfType = docs.filter(doc => doc.type === value)
                docsOfType.sort((a, b) => a.id - b.id)
                let lastDocOfType = docsOfType[docsOfType.length - 1]

                status = lastDocOfType ? lastDocOfType.status : -1
                type = value

                switch (type) {
                    case 0:
                    case 4:
                        tabId = 'document'
                        break;
                    case 1:
                        tabId = 'address'
                        break;
                    case 2:
                    case 3:
                        tabId = 'card'
                        break;
                    case 5:
                        tabId = 'selfie'
                        break;
                    case 6:
                        tabId = 'other'
                        break;
                }

                let resultingDoc = { over, status, type, tabId, text: this.getCategoryAndText(type).text, category: this.getCategoryAndText(type).category }

                resultingArray.push(resultingDoc)
            })

            return resultingArray
        },
        categoriesItems() {
            let verifcationTablesVariable = [];
            let categoriesItems = [];

            try{
                verifcationTablesVariable = JSON.parse(this.variables.VERIFICATION_TABLES)
            }catch{
                verifcationTablesVariable = []
            }

            verifcationTablesVariable.forEach(variable => {
                switch (variable) {
                    case 'document':
                        categoriesItems.push({
                            id: 'document',
                            title: this.tokens.newDesign.document,
                            documentCount: 0,
                            uploadedCount: 0,
                        })
                        break;
                    case 'address':
                        categoriesItems.push({
                            id: 'address',
                            title: this.tokens.newDesign.address,
                            documentCount: 0,
                            uploadedCount: 0,
                        })
                        break;
                    case 'card':
                        categoriesItems.push({
                            id: 'card',
                            title: this.tokens.newDesign.creditCard,
                            documentCount: 0,
                            uploadedCount: 0,
                        });
                        break;
                    case 'selfie':
                        categoriesItems.push({
                            id: 'selfie',
                            title: this.tokens.newDesign.selfie,
                            documentCount: 0,
                            uploadedCount: 0,
                        });
                        break;
                    case 'other':
                        categoriesItems.push({
                            id: 'other',
                            title: this.brand === 'denalitrdgroupltd' ? this.tokens.newDesign.ssn : this.tokens.newDesign.other,
                            documentCount: 0,
                            uploadedCount: 0,
                        });
                        break;
                }
            });

            this.documentsData.forEach((i) => {
                if (i.tabId === categoriesItems[0]?.id) {
                    categoriesItems[0].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[0].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[1]?.id) {
                    categoriesItems[1].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[1].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[2]?.id) {
                    categoriesItems[2].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[2].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[3]?.id) {
                    categoriesItems[3].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[3].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[4]?.id) {
                    categoriesItems[4].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[4].uploadedCount++
                    }
                }
            })
            
            if (!this.disableQuestions && this.questions.length > 0) {
                categoriesItems.push({
                    id: 'questionnaire',
                    title: this.tokens.fullScreenVerification.questionnaire,
                    documentCount: this.questions.length,
                    uploadedCount: this.questions.filter((i) => i.answer).length,
                })
            }

            return categoriesItems.filter(i => i.documentCount > 0)
        },
        activeItem:{
            get(){
                return this.active || (this.categoriesItems[0] && this.categoriesItems[0].id) || ""
            },
            set(val){
                this.active = val
            }
        }
    },
    methods: {
        sendAnswers() {
            let answersArray = []
            for (let n in this.questions) {
                answersArray.push({ question_id: this.questions[n].id, answer: this.questions[n].answer })
            }

            RestService.post('/kyc/questions', { answers: answersArray })
                .then(() => {
                    this.$store.commit(NOTIFICATION, this.tokens.newDesign.answerSaved)
                })
        },
        changeActiveitem(id) {
            this.activeItem = id
        },
        getCategoryAndText(type) {
            let category, text;

            switch (type) {
                case 0:
                    category = this.tokens.newDesign.document
                    text = this.tokens.newDesign.frontSide
                    break;
                case 4:
                    category = this.tokens.newDesign.document
                    text = this.tokens.newDesign.backSide
                    break;
                case 1:
                    category = this.tokens.newDesign.address
                    text = this.tokens.newDesign.frontSideDocument
                    break;
                case 2:
                    category = this.tokens.newDesign.creditCard
                    text = this.tokens.newDesign.frontSide
                    break;
                case 3:
                    category = this.tokens.newDesign.creditCard
                    text = this.tokens.newDesign.backSide
                    break;
                case 5:
                    category = this.tokens.newDesign.selfie
                    text = this.tokens.newDesign.frontSide
                    break;
                case 6:
                    category = this.tokens.newDesign.other
                    text = this.brand === 'denalitrdgroupltd' ? this.tokens.newDesign.frontSideSsnDocument : this.tokens.newDesign.frontSideDocument
                    break;
            }

            return { category, text }
        },
        changePrivacyPolicyState() {
            if(this.brand === 'accglobalfinancial'){
                window.open(`https://p-cdn.in/${this.brand}/brands/${this.brand}/documents/TermsAndConditions.pdf`, '_blank');
            }else{
                this.isActivePrivacyPolicy = !this.isActivePrivacyPolicy
            }
        },
    }
}
</script>

<style scoped>
.verification-page__inner{
    position: relative;
}

.verification-page__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.panel {
    border: none !important;
    padding: 0;
}

.inner {
    padding: 16px 0;
}

.info {
    margin-bottom: 16px;
    line-height: 1.6;
}

.update-document-list {
    display: flex;
    gap: 16px;
}

.questionnaire {
    border: 1px solid var(--System-OutlineVariant);
    border-radius: var(--Corner-small);
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.questionnaire__title {
    color: var(--System-OnSurface);
}

.questionnaire__subtitle {
    margin-top: 5px;
}

.questionnaire__inner { 
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.questionnaire__question {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.questionnaire__question-label {
    color: var(--System-OnSurface);
    min-width: 40%;
    max-width: 40%;
}

.questionnaire__question-input { 
    width: 100%;
}

.questionnaire__button { 
    margin: 32px 0 0 auto;
}

@media (max-width: 1200px) {
    .questionnaire__inner { 
        gap: 24px;
    }

    .questionnaire__question {
        flex-direction: column;
        gap: 8px;
    }

    .questionnaire__question-label {
        max-width: 100%;
        min-width: 100%;
    }
}

@media (max-width: 768px) {
    .update-document-list {
        flex-direction: column;
    }
}

.documents-table {
    padding-top: 24px;
}

.table-title {
    margin-bottom: 5px;
    color: var(--System-OnSurface);
}

.table-desc {
    margin-bottom: 20px;
}
</style>
