<template>
    <div class="auto-close">
        <div class="value-container">
            <BaseNumberInput 
                :title="tokens.autoCloseModal.takeProfit" 
                type="number" 
                placeholder="0.0"
                v-model="values.tp"
                :validateFunction="updateInput" />
            <BaseButton 
                class="minus" 
                :buttonStyle="'outlined-primary'"
                @click.native="
                    values.tp = values.tp ? 
                        (Math.max(0, parseFloat(values.tp) - Math.pow(0.1, modalSymbolActive.precision))).toFixed(modalSymbolActive.precision) : 
                        modalSymbolActive.quote.bid.toFixed(modalSymbolActive.precision)">
                <template #icon>
                    <i class="material-symbols-outlined">remove</i>
                </template>
            </BaseButton>
            <BaseButton 
                class="plus" 
                :buttonStyle="'outlined-primary'"
                @click.native="
                    values.tp = values.tp ? 
                        (parseFloat(values.tp) + Math.pow(0.1, modalSymbolActive.precision)).toFixed(modalSymbolActive.precision) : 
                        modalSymbolActive.quote.bid.toFixed(modalSymbolActive.precision)">
                <template #icon>
                    <i class="material-symbols-outlined">add</i>
                </template>
            </BaseButton>
        </div>
    
        <div class="value-container">
            <BaseNumberInput 
                :title="tokens.autoCloseModal.stopLoss" 
                type="number" 
                placeholder="0.0"
                v-model="values.sl"
                :validateFunction="updateInput" />
            <BaseButton 
                class="minus" 
                :buttonStyle="'outlined-primary'"
                @click.native="
                    values.sl = (values.sl ?
                        (Math.max(0, parseFloat(values.sl) - Math.pow(0.1, modalSymbolActive.precision))).toFixed(modalSymbolActive.precision) : 
                        modalSymbolActive.quote.ask.toFixed(modalSymbolActive.precision))">
                <template #icon>
                    <i class="material-symbols-outlined">remove</i>
                </template>
            </BaseButton>
            <BaseButton 
                class="plus" 
                :buttonStyle="'outlined-primary'"
                @click.native="
                    values.sl = values.sl ? 
                        (parseFloat(values.sl) + Math.pow(0.1, modalSymbolActive.precision)).toFixed(modalSymbolActive.precision) : 
                        modalSymbolActive.quote.ask.toFixed(modalSymbolActive.precision)">
                <template #icon>
                    <i class="material-symbols-outlined">add</i>
                </template>
            </BaseButton>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_AUTOCLOSE} from '@/store/mutations.type'
import {currencySign, isMobile} from '@/common/helpers'
import BaseNumberInput from "@/new-design/shared/ui/Inputs/BaseNumberInput";
import BaseButton from '@/new-design/shared/ui/buttons/BaseButton.vue';

export default {
    name: 'AutoCloseBlock',
    components: {
        BaseNumberInput,
        BaseButton
    },
    props: {
        data: Object
    },
    data() {
        return {
            values: {
                symbolActive: this.data.symbolActive,
                tp: this.data.tp,
                sl: this.data.sl,
            },
        }
    },
    methods: {
        updateInput(e) {
            return e.replace(/,/g, "");
        },
        apply(){
            this.$store.commit(MODAL_AUTOCLOSE, {event: 1})
            this.$store.commit(MODAL, {
                autoClose: false
            })
        },
        currencySign,
        isMobile
    },
    computed: {
        ...mapGetters(['modals', 'modalAutoClose', 'tokens', 'activeAccount', 'symbols', 'orders']),
        modalSymbolActive() {
            if (this.values?.symbolActive === null) {
                const symbol = this.orders?.[this.values?.item]?.symbol
                if (symbol) {
                    this.$store.commit(MODAL_AUTOCLOSE, {symbolActive: this.symbols[symbol]})
                }
            }
            return this.modalAutoClose.symbolActive || {
                precision: 0,
                quote: {
                    bid: 0,
                    ask: 0
                }
            }
        }
    },
    watch: {
        modalAutoClose: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    if (this.values[n] !== undefined) {
                        this.values[n] = val[n]
                    }
                }
            }
        },
        values: {
            deep: true,
            handler(val) {
                this.$store.commit(MODAL_AUTOCLOSE, val);
                this.apply()
            }
        },
        data: {
            handler(newData) {
                this.values.symbolActive = newData.symbolActive;
                this.values.tp = newData.tp;
                this.values.sl = newData.sl;
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
.auto-close {
    padding: 8px 0 24px;
}

.title {
    padding: 20px;
    color: var(--System-OnSurface);
}

.value-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    padding: 5px 20px;
}

.plus i, .minus i {
    font-size: 18px;
    padding: 2.5px 0;
    margin: 0 -5px;
}

/* modifiers */

.stretch {
    position: relative;
    padding: 0 15px;
}
.buttons {
    border-top: 1px solid var(--System-OutlineVariant);
    padding: 25px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

</style>
