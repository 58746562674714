<template>
    <div class="order-menu" ref="order-menu">

        <Calculator :visible="calculatorIsVisible"
                    :input="calculatorInputValue"
                    @close="calculatorIsVisible = false"
                    @apply="applyCalcValue"
        />

        <div class="grid-item">
            <!-- div with input and buttons for lots -->
            <div class="volume-with-buttons value-container value-container--grid"
                 :class="{transparent: lotsTransparent}"
                 @click="focusLots()"
                 v-show="volumeType==='lots'"
            >
                <div class="subtitle-input">
                    <span>{{ tokens.orderMenu.volume }}</span>
                    <input type="text" class="value" inputmode="decimal"
                           :readonly="isMobile()"
                           v-model="volumeInLots"
                           @keypress="validateInput"
                           @blur="blurLots"
                           @keyup="updateLotsInput"
                    />
                </div>
                <div class="plus" @click.stop="increaseVolumeInLots"><i class="material-icons">add</i></div>
                <div class="minus" @click.stop="decreaseVolumeInLots"><i class="material-icons">remove</i></div>
            </div>

            <!-- div with input and buttons for units -->
            <div class="volume-with-buttons value-container value-container--grid"
                 :class="{transparent: unitsTransparent}"
                 @click="focusUnits()"
                 v-show="volumeType==='units'"
            >
                <div class="subtitle-input">
                    <span>{{ tokens.orderMenu.volume }}</span>
                    <input type="text" class="value" inputmode="decimal"
                           :readonly="isMobile()"
                           v-model="volumeInUnits"
                           @keypress="validateInput"
                           @blur="blurUnits"
                           @keyup="updateUnitsInput"
                    />
                </div>
                <div class="plus" @click.stop="increaseVolumeInUnits"><i class="material-icons">add</i></div>
                <div class="minus" @click.stop="decreaseVolumeInUnits"><i class="material-icons">remove</i></div>
            </div>

            <!-- div with input and buttons for currency -->
            <div class="volume-with-buttons value-container value-container--grid"
                 :class="{transparent: currencyTransparent}"
                 @click="focusCurrency()"
                 v-show="volumeType==='currency'"
            >
                <div class="subtitle-input">
                    <span>{{ tokens.orderMenu.volume }}</span>
                    <input type="text" class="value" inputmode="decimal"
                           :readonly="isMobile()"
                           v-model="volumeInCurrency"
                           @keypress="validateInput"
                           @blur="blurCurrency"
                           @keyup="updateCurrencyInput"
                    />
                </div>
                <div class="plus" @click.stop="increaseVolumeInCurrency"><i class="material-icons">add</i></div>
                <div class="minus" @click.stop="decreaseVolumeInCurrency"><i class="material-icons">remove</i></div>
            </div>

            <!-- lots / units / currency switch -->
            <div class="btns" @click.stop="">
                <div v-for="(volumesType, index) in volumesTypes" :key="index" class="btn" :class="{active: volumeType===volumesType}" @click="volumeType=volumesType">{{ tokens.orderMenu[volumesType] }}</div>
            </div>

        </div>

        <div class="grid-item order-vals">
            <div class="order-val"><span class="title">{{ tokens.orderMenu.contractSize }}:</span><span class="digit">{{ contractSize.toLocaleString() }}</span></div>
            <div class="order-val"><span class="title">{{ tokens.orderMenu.position}}:</span><span class="digit">{{ orderVals.position.toLocaleString() }}</span></div>
            <div class="order-val"><span class="title">{{ tokens.orderMenu.margin }}:</span><span class="digit" :class="{red: orderVals.margin > orderVals.freeMargin}">{{ currencySign(activeAccount.currency) }}{{ orderVals.margin.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}</span></div>
            <div class="order-val"><span class="title">{{ tokens.orderMenu.freeMargin.toLocaleString(undefined, {minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}) }}:</span><span class="digit" :class="{red: orderVals.freeMargin < 0}">{{ currencySign(activeAccount.currency) + orderVals.freeMargin.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}</span></div>
            <div class="order-val" :class="{hide: pendingPrice}"><span class="title">{{ tokens.orderMenu.spread }}:</span><span class="digit">{{ spread }}</span></div>
            <div class="order-val" v-show="showLeverage==1"><span class="title">{{ tokens.orderMenu.leverage }}:</span><span class="digit">1:{{ computedLeverage }}</span></div>
            <!--            <div class="order-val"><span class="title">Trend Strength:</span><span class="digit">{{ trend }}</span></div>-->
            <button class="profit-calculator" @click="showProfitCalculator"><i class="material-icons">legend_toggle</i>{{tokens.profitCalculatorModal.profitCalculator}}</button>
        </div>

        <!--        <div class="grid-item profit-calculator-wrapper">-->
        <!--            <button @click="showProfitCalculator"><i class="material-icons">legend_toggle</i>Profit Calculator</button>-->
        <!--        </div>-->

        <div class="grid-item tpsl grey-input" @click="showAutoCloseModal" ref="autoclose-holder">
            <span>{{ tokens.orderMenu.takeProfitStopLoss }}</span>
            <span class="expiration">
                <span :class="{hide: tp || tp != 0 || sl || sl != 0}">{{ tokens.orderMenu.notSet }}</span>
                <span :class="{hide: tp == 0}">{{ tpType === '$' ? currencySign(activeAccount.currency) : '' }}{{ tp }}{{ tpType === '%' ? '%' : '' }}</span>
                <span>{{ (tp > 0 && sl > 0) ? ' & ' : '' }}</span>
                <span :class="{hide: sl == 0}">{{ slType === '$' ? currencySign(activeAccount.currency) : '' }}{{ sl }}{{ slType === '%' ? '%' : '' }}</span>
            </span>
        </div>

        <div class="grid-item pending grey-input">
            <div class="time" @click="showPendingModal" ref="pending-holder">
                <div>{{tokens.orderMenu.pending}}</div>
                <div class="expiration">
                    <span>{{ pendingPrice ? tokens.orderMenu.at + ' ' + pendingPrice : tokens.orderMenu.market }}</span>
                </div>
            </div>
        </div>

        <div class="grid-item buy-sell">
            <div class="button up" @click="openOrder(0)" :class="{disabled: tradeDisabled, close: !sessionsState[currentSym]}">
                <span>{{ sessionsState[currentSym] ?  tokens.orderMenu.buy.toUpperCase() : 'Closed' }}</span>
                <span class="price">
                    <span>{{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote && symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym].quote && symbols[currentSym]?.quote.ask ? symbols[currentSym]?.quote.ask.toFixed(symbols[currentSym].precision).slice(0,-2) : 0 : ''}}</span>
                    <span>{{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote && symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote ? symbols[currentSym]?.quote.ask.toFixed(symbols[currentSym].precision).slice(-2) : 0 : '' }}</span>
                </span>
            </div>
            <div class="button down" @click="openOrder(1)" :class="{disabled: tradeDisabled, close: !sessionsState[currentSym]}">
                <span>{{ sessionsState[currentSym] ?  tokens.orderMenu.sell.toUpperCase() : 'Closed' }}</span>
                <span class="price">
                    <span>{{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote && symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote && symbols[currentSym]?.quote.ask ? symbols[currentSym]?.quote.bid.toFixed(symbols[currentSym].precision).slice(0,-2) : 0 : '' }}</span>
                    <span>{{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote && symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote ? symbols[currentSym]?.quote.bid.toFixed(symbols[currentSym].precision).slice(-2) : 0 : '' }}</span>
                </span>
            </div>
        </div>

        <div class="grid-item meter" v-if="!isMobile() && !ordersPane">
            <div class="background"><img :src="staticBase+`misc/meter-scale.png`"></div>
            <div class="sell">
                <div class="animal bear"><img :src="staticBase+`misc/bear.svg`"></div>
                <div class="word">SELL</div>
                <div class="percent">{{ (100-trend).toFixed(1)+'%' }}</div>
            </div>
            <div class="buy">
                <div class="animal bull"><img :src="staticBase+`misc/bull.svg`"></div>
                <div class="word">BUY</div>
                <div class="percent">{{ trend.toFixed(1)+'%' }}</div>
            </div>
            <img class="arrow" :style="`transform: rotate(${angle}deg)`" :src="staticBase+`misc/meter-arrow.png`">
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_AMOUNTS, MODAL_AUTOCLOSE, MODAL_PENDING, NOTIFICATION_ERROR} from '@/store/mutations.type'
import {currencySign, isMobile} from "@/common/helpers"
import {CUSTOM_TPSL, SHOW_LEVERAGE, STATIC_BASE, VOLUME_TYPES} from "@/common/config"
import {CFD_ORDER_OPEN_REQUEST} from '@/store/actions.type'
import Calculator from '@/components/Calculator'

export default {
    name: "OrderMenu",
    components: {Calculator},
    props: ['chart', 'gridId', 'visible'],
    data() {
        return {
            staticBase: STATIC_BASE,
            showLeverage: SHOW_LEVERAGE,
            time: Date.now(),
            calcCoef: 1,
            leverage: 100,
            tp: '',
            tpType: '=',
            sl: '',
            slType: '=',
            pendingPrice: '',
            volumesTypes: this.user?.volume_units.split(',') || VOLUME_TYPES,
            volumeType: this.user?.volume_units.split(',')[0] || 'lots',

            volumeInUnits: 1,
            volumeInLots: 0,
            volumeInCurrency: 0,
            calculatorIsVisible: false,
            calculatorInput: '',
            lotsTransparent: false,
            unitsTransparent: false,
            currencyTransparent: false,
            lotStepLength: 2,
            lotStepStr: '',
            lotStepChecks: [],
            customTpSl: CUSTOM_TPSL
        }
    },
    mounted() {
        this.$store.commit(MODAL_AMOUNTS, {
            contractSize: this.contractSize
        })
    },
    methods: {
        showProfitCalculator(){
            this.$store.commit(MODAL, {
                profitCalculator: true,
                account: false,
            })
        },
        decreaseVolumeInLots() {

            this.volumeInLots = Number(((this.volumeInLots - this.symbols[this.currentSym].lot_step) < 0 ? this.volumeInLots : parseFloat(this.volumeInLots) - this.symbols[this.currentSym].lot_step).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((this.volumeInLots * this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        increaseVolumeInLots() {
            this.volumeInLots = Number((parseFloat(this.volumeInLots) + this.symbols[this.currentSym].lot_step).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((this.volumeInLots * this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        decreaseVolumeInUnits() {
            this.volumeInUnits = this.volumeInUnits <= 1 ? this.volumeInUnits : this.volumeInUnits - 1
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        increaseVolumeInUnits() {
            this.volumeInUnits = parseFloat(this.volumeInUnits) + 1
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        decreaseVolumeInCurrency() {
            this.volumeInCurrency = this.volumeInCurrency <= 1 ? this.volumeInCurrency : this.volumeInCurrency - 1
            this.volumeInUnits = Number((this.volumeInCurrency * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
        },
        increaseVolumeInCurrency() {
            this.volumeInCurrency = parseFloat(this.volumeInCurrency) + 1
            this.volumeInUnits = Number((this.volumeInCurrency * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
        },
        focusLots() {
            this.lotsTransparent = true
            this.calculatorIsVisible = true
            this.calculatorInput = 'lots'
        },
        blurLots() {
            this.lotsTransparent = false
        },
        updateLotsInput(e) {
            let val = e.target.value
            if(val.length === 0 || !this.checkLotStepZeros){
                this.volumeInLots = ''
                return
            }

            if(val.slice(-1) === '.' || this.checkLotStepZeros.indexOf(val) !== -1) {
                return
            }
            if(val.slice(-1) === ',') {
                this.volumeInLots = val.split(',')[0]+'.'
                return
            }

            this.volumeInLots = Number(parseFloat(val).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((val * this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        focusUnits() {
            this.unitsTransparent = true
            this.calculatorIsVisible = true
            this.calculatorInput = 'units'
        },
        blurUnits() {
            this.unitsTransparent = false
        },
        updateUnitsInput(e) {
            let val = e.target.value

            if(val.length === 0 || !this.checkLotStepZeros){
                this.volumeInUnits = ''
                return
            }
            if(val.slice(-1) === '.' || this.checkLotStepZeros.indexOf(val) !== -1) {
                return
            }
            if(val.slice(-1) === ',') {
                this.volumeInUnits = val.split(',')[0]+'.'
                return
            }

            this.volumeInUnits = Number(parseFloat(val).toFixed(this.lotStepLength))
            this.volumeInLots = Number((val / this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((val * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        focusCurrency() {
            this.currencyTransparent = true
            this.calculatorIsVisible = true
            this.calculatorInput = 'currency'
        },
        blurCurrency() {
            this.currencyTransparent = false
        },
        updateCurrencyInput(e) {
            let val = e.target.value

            if(val.length === 0 || !this.checkLotStepZeros){
                this.volumeInCurrency = ''
                return
            }
            if(val.slice(-1) === '.' || this.checkLotStepZeros.indexOf(val) !== -1) {
                return
            }
            if(val.slice(-1) === ',') {
                this.volumeInCurrency = val.split(',')[0]+'.'
                return
            }

            this.volumeInCurrency = Number(parseFloat(val).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((val * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
        },
        applyCalcValue(val) {
            if (!this.lotStepLength){
                return
            }

            if (this.calculatorInput === 'lots') {
                this.volumeInLots = Number(parseFloat(val).toFixed(this.lotStepLength))
                this.volumeInUnits = Number((val * this.contractSize).toFixed(this.lotStepLength))
                this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
            } else if (this.calculatorInput === 'units') {
                this.volumeInUnits = Number(parseFloat(val).toFixed(this.lotStepLength))
                this.volumeInLots = Number((val / this.contractSize).toFixed(this.lotStepLength))
                this.volumeInCurrency = Number((val * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
            } else {
                this.volumeInCurrency = Number(parseFloat(val).toFixed(this.lotStepLength))
                this.volumeInUnits = Number((val * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
                this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
            }

        },
        showAutoCloseModal() {
            let rect = this.$refs['autoclose-holder'].getBoundingClientRect()
            let rectOrder = this.$refs['order-menu'].getBoundingClientRect()

            this.$store.commit(MODAL_AUTOCLOSE, {
                left: rect.left,
                top: rect.top,
                posY: rectOrder.y,
                posX: rectOrder.width / 2,
                symbolActive: this.symbols[this.currentSym],
                orderActive: null,
                tp: this.tp,
                tpType: this.tpType,
                sl: this.sl,
                slType: this.slType
            })

            this.$store.commit(MODAL, {
                autoClose: true
            })
        },
        showPendingModal() {
            let rect = this.$refs['pending-holder'].getBoundingClientRect()
            let rectOrder = this.$refs['order-menu'].getBoundingClientRect()

            this.$store.commit(MODAL_PENDING, {
                left: rect.left,
                top: rect.top,
                posY: rectOrder.y,
                posX: rectOrder.width / 2,
                price: this.pendingPrice
            })

            this.$store.commit(MODAL, {
                pending: true
            })
        },
        validateInput(e) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].indexOf(e.key) === -1) {
                e.preventDefault()
            }

            if ((e.key === '.' || e.key === ',') && (e.target.value.indexOf('.') !== -1 || e.target.value.indexOf(',') !== -1)) {
                e.preventDefault()
            }
        },
        currencySign,
        isMobile,
        openOrder(type) {
            if (!this.symbols[this.currentSym]) {
                return
            }

            if(!this.sessionsState[this.currentSym] || !this.symbols[this.currentSym].enable){
                this.$store.commit(NOTIFICATION_ERROR, 'MARKET_CLOSED')
                return
            }

            if (!this.activeAccount.enable) {
                this.$store.commit(NOTIFICATION_ERROR, 'TRADING_DISABLED')
                return
            }

            let price = this.pendingPrice || this.symbols[this.currentSym].quote[type ? 'bid' : 'ask'],
                sl = 0,
                tp = 0

            if (this.tp) {
                switch (this.tpType) {
                    case '%':
                        tp = Number(price) + (type ? -1 : 1) * price * this.tp / (100 * this.leverage)
                        break
                    case '$':
                        tp = Number(price) + (type ? -1 : 1) * price * this.tp / (this.leverage * this.volume)
                        break
                    case '=':
                        tp = this.tp
                        break
                }
            }

            if (this.sl) {
                switch (this.slType) {
                    case '%':
                        sl = Number(price) + (type ? 1 : -1) * price * this.sl / (100 * this.leverage)
                        break
                    case '$':
                        sl = Number(price) + (type ? 1 : -1) * price * this.sl / (this.leverage * this.volume)
                        break
                    case '=':
                        sl = this.sl
                        break
                }
            }

            if (this.pendingPrice) {
                let pendingType = 0

                if (type) {
                    if (this.pendingPrice < this.symbols[this.currentSym].quote.bid) {
                        pendingType = 5
                    } else {
                        pendingType = 3
                    }
                } else {
                    if (this.pendingPrice < this.symbols[this.currentSym].quote.ask) {
                        pendingType = 2
                    } else {
                        pendingType = 4
                    }
                }

                type = pendingType
            }

            if(this.customTpSl) {
                if (type == 0) {
                    if (sl && this.pendingPrice - sl < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid SL')
                        return
                    }
                    if (tp && tp - this.pendingPrice < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid TP')
                        return
                    }
                }

                if (type == 1) {
                    if (sl && sl - this.pendingPrice < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid SL')
                        return
                    }
                    if (tp && this.pendingPrice - tp < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid TP')
                        return
                    }
                }
            }

            let order = {
                type,
                symbol: this.currentSym,
                volume: this.orderVals.position,
                account_id: this.activeAccount.id,
                sl,
                tp,
                price_open: this.pendingPrice || undefined
            }
            // if (this.tp && this.tp >= this.symbols[this.currentSym].bid) {
            //     order.tp = this.calcPrice(this.currentSym,order,this.activeAccount, tp)
            // }
            //
            // if (this.sl && type != 1) {
            //     order.sl = this.calcPrice(this.currentSym,order,this.activeAccount, sl, 'sl')
            // }

            this.$store.dispatch(CFD_ORDER_OPEN_REQUEST, order).then(() => {
                this.tp = ''
                this.sl = ''
            })
        },
        calcPrice(symbolConf, newOrder, account, targetProfit, mode = 'tp'){
            let point = newOrder.volume

            let baseRate = 1, baseCur = symbolConf.base_currency,
                accRate = 1, accCur = account.currency

            if (this.symbols['USD'+baseCur] !== undefined) {
                baseRate = this.symbols['USD'+baseCur].quote.bid
            } else if (this.symbols[baseCur+'USD'] !== undefined) {
                baseRate = 1 / this.symbols[baseCur+'USD'].quote.bid
            }

            if (this.symbols['USD'+accCur] !== undefined) {
                accRate = this.symbols['USD'+accCur].quote.bid
            } else if (this.symbols[accCur+'USD'] !== undefined) {
                accRate = 1 / this.symbols[accCur+'USD'].quote.bid
            }

            point *= accRate / baseRate

            let dif = targetProfit / point,
                mult = mode === 'tp' ? 1 : -1

            switch (newOrder.type) {
                case 0:
                    return parseFloat(this.symbols[newOrder.symbol].quote.bid + mult * dif)
                case 1:
                    return parseFloat(this.symbols[newOrder.symbol].quote.ask + mult * dif)
                case 2:
                case 4:
                    return newOrder.price_open + mult * dif
                case 3:
                case 5:
                    return newOrder.price_open - mult * dif
                default:
                    throw new Error('Invalid order type')
            }
        }
    },
    computed: {
        ...mapGetters(['symbols', 'sessionsState','activeAccount', 'accounts', 'modals', 'tokens', 'modalAutoClose', 'modalPending', 'tradeDisabled', 'gridLayout', 'ordersPane', 'orders', 'user','autoCloseMode', 'conversions','notifications']),
        checkLotStepZeros(){
            if (this.lotStepStr == 0) {
                return
            }

            let stepArray = []
            let step = "0.0",
                curLot = this.lotStepStr.slice(0, -1)

            for (let i = 0; i < 10; i++) {
                stepArray.push(step)
                if(curLot !== '0' && step === curLot){
                    break
                }
                step += '0'
            }

            return stepArray
        },
        computedLeverage() {
            if(this.symbols[this.currentSym]) {
                return this.symbols[this.currentSym].leverage_default || this.activeAccount.leverage
            } else {
                return 1
            }
        },
        trend() {
            return (this.symbols && this.currentSym) ? this.symbols[this.currentSym].quote.trend : 50
        },
        angle() {
            return 90 / 50 * this.trend - 90
        },
        balances() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                equity: 0,
                margin: 0
            }

            if (!this.activeAccount.id) {
                return b
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            let marginBySymbol = {}

            for(let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                o.easy_amount = o.easy_amount || o.margin

                b.orders += o.easy_amount

                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }

                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision)) : 0
            }

            for(let n in marginBySymbol) {
                b.margin += Math.abs(marginBySymbol[n]) * (1 / this.conversions[this.activeAccount.currency])
            }

            b.equity = b.balance + b.credit + b.profit
            return b
        },
        curSymObj() {
            return this.symbols[this.currentSym] || {lot_step: 0}
        },
        orderVals() {
            let vals = {
                position: 0,
                margin: 0,
                freeMargin: 0,
                leverage: 1
            }

            if (!this.symbols[this.currentSym] || !this.activeAccount.id || !this.volumeInLots) {
                return vals
            }

            vals.position = parseFloat(this.volumeInLots) * this.contractSize
            vals.leverage = this.symbols[this.currentSym].leverage_default || this.activeAccount.leverage

            let swapAndCommission = 0
            let marginBySymbol = {}

            for(let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }
                marginBySymbol[o.symbol] += o.type == 0 ? Number(o.margin) : -Number(o.margin)

            }
            for(let n in marginBySymbol) {
                swapAndCommission += Math.abs(marginBySymbol[n])
            }

            let baseRate = 1, baseCur = this.symbols[this.currentSym].base_currency,
                accRate = 1, accCur = this.activeAccount.currency

            if (this.symbols["USD" + baseCur] !== undefined) {
                baseRate = this.symbols["USD" + baseCur].quote.bid
            } else if (this.symbols[baseCur + "USD"] !== undefined) {
                baseRate = 1 / this.symbols[baseCur + 'USD'].quote.bid
            }

            if (this.symbols["USD" + accCur] !== undefined) {
                accRate = this.symbols["USD" + accCur].quote.bid
            } else if (this.symbols[accCur + "USD"] !== undefined) {
                accRate = 1 / this.symbols[accCur + 'USD'].quote.bid
            }

            let marginRate = accRate / baseRate
            let margin = vals.position / vals.leverage

            vals.margin = parseFloat((margin * this.symbols[this.currentSym].quote.bid * marginRate).toFixed(this.activeAccount.precision))  || 0

            // pnl:
            let pnl = 0

            for(let i in this.orders) {
                let order = this.orders[i]
                if (order.type > 1 || !this.symbols[order.symbol]) {
                    continue
                }
                pnl += order.point * (order.type === 0 ? this.symbols[order.symbol].quote.bid - order.price_open : order.price_open - this.symbols[order.symbol].quote.ask) * Math.pow(10, this.symbols[order.symbol].precision) + order.swap + order.commission
            }

            // free margin:
            let equity = this.activeAccount.balance + this.activeAccount.credit + pnl
            vals.freeMargin = equity - swapAndCommission

            return vals
        },
        currentSym() {
            return this.chart.symbol || ''
        },
        contractSize() {
            return this.symbols[this.currentSym] !== undefined ? (this.symbols[this.currentSym].lot || 1) : 1
        },
        spread() {
            if (!this.currentSym || !this.symbols[this.currentSym]) {
                return 0
            }

            return Number(this.symbols[this.currentSym].quote.ask - this.symbols[this.currentSym].quote.bid).toFixed(this.symbols[this.currentSym].precision)
        },
        computedConversions() {
            let conv = {
                base: 1,
                acc: 1
            }

            if (this.symbols[this.currentSym] === undefined) {
                return conv
            }

            let accCur = this.activeAccount.currency

            // let baseCur = this.symbols[this.currentSym].base_currency,

            // if (baseCur !== 'USD') {
            //     if (this.symbols[baseCur+'USD'] !== undefined) {
            //         conv.base = this.symbols[baseCur + 'USD'].quote.bid
            //     } else if (this.symbols['USD'+baseCur] !== undefined) {
            //         conv.base = 1 / this.symbols['USD' + baseCur].quote.bid
            //     } else {
            //         conv.base = this.conversions[baseCur]
            //     }
            // }

            if (accCur !== 'USD') {
                if (this.symbols[accCur+'USD'] !== undefined) {
                    conv.acc = this.symbols[accCur + 'USD'].quote.bid
                } else if (this.symbols['USD'+accCur] !== undefined) {
                    conv.acc = 1 / this.symbols['USD'+accCur].quote.bid
                } else {
                    conv.acc = this.conversions[accCur]
                }
            }

            return conv
        },
        calculatorInputValue() {
            switch (this.calculatorInput) {
                case 'lots':
                    return this.volumeInLots
                case 'currency':
                    return this.volumeInCurrency
                case 'units':
                default:
                    return this.volumeInUnits
            }
        }
    },
    watch: {
        activeAccount(val) {
            if (!val) {
                return
            }
            this.leverage = val.leverage
        },
        sl(val) {
            this.sl = String(val).split(',').join('.')
        },
        tp(val) {
            this.tp = String(val).split(',').join('.')
        },
        modalAutoClose: {
            deep: true,
            handler(val) {
                if(!val.orderActive) {
                    if(val.event){
                      this.tp = val.tp
                      this.sl = val.sl
                      this.$store.commit(MODAL_AUTOCLOSE, {event: 0})
                    }
                    this.tpType = val.tpType
                    this.slType = val.slType
                }

                // if(!this.modals.autoClose) {
                //     if (this.tp && this.tp < val.symbolActive.quote.bid && !this.sl) {
                //         this.tp = (val.symbolActive.quote.bid * 1.01).toFixed(val.symbolActive.precision)
                //     } else if (this.sl && this.tp) {
                //         let tpWithIncrement = val.symbolActive.quote.bid * 1.01
                //         this.tp = tpWithIncrement - (tpWithIncrement * 0.05).toFixed(val.symbolActive.precision)
                //     }
                // }
            }
        },
        modalPending: {
            deep: true,
            handler(val) {
                if(val.event){
                    this.pendingPrice = val.price
                    this.$store.commit(MODAL_PENDING, {event: 0})
                }
            }
        },
        curSymObj: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal === oldVal) {
                    return
                }

                if(!newVal.lot_step){
                    return
                }

                this.$set(this, 'lotStepLength', newVal.lot_step.toString().length - 2 > 0 ? newVal.lot_step.toString().length - 2 : 2)
                this.$set(this, 'lotStepStr', newVal.lot_step.toString())
                this.$set(this, 'lotStepChecks', this.checkLotStepZeros)

                this.volumeInLots = newVal.lot_step || 0.01
                this.volumeInUnits = Number((this.volumeInLots * newVal.lot).toFixed(3))

                if (newVal.quote !== undefined) {
                    this.volumeInCurrency = Number((this.volumeInUnits * newVal.quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
                }
            }
        },
        user: {
            deep: true,
            handler(newVal) {
                if (newVal.volume_units === '') {
                    this.$set(this, 'volumesTypes', VOLUME_TYPES)
                } else {
                    this.$set(this, 'volumesTypes', this.user.volume_units.split(','))
                }
                    // this.$set(this, 'volumeType', this.volumesTypes[0])
                    this.$set(this, 'volumeType', 'lots')
            }
        }
    }
}
</script>

<style scoped>
.order-menu {
    display: grid;
    grid-template-columns: 200px;
    grid-template-rows: repeat(5, auto);
    align-content: start;
    gap: var(--gutter);
    padding: 5px 10px;
    overflow: hidden;
    --gutter: 7px;
}

.grey-input {
    height: 42px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: var(--background-light);
    border: 2px solid var(--background-light);
    border-radius: 2px;
    cursor: pointer;
    transition: background-color ease-in-out 0.3s;
}

.grey-input:hover {
    color: var(--white);
}

.light-theme .grey-input {
    background-color: var(--theme_3-background-light);
    border: 2px solid var(--border);
}
.light-theme .grey-input:hover {
    color: var(--white-to-black);
}

.btns {
    margin-top: 1px;
    display: flex;
    align-items: stretch;
    padding: 0 2px;
}

.btn {
    height: 15px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--background-light);
    border-radius: 2px;
    cursor: pointer;
    color: var(--font);
}

.button.close{
    opacity: .5;
    padding: 13px 0;
}

.btn.active {
    color: var(--white);
}

.light-theme .btn{
    background-color: var(--theme_3-background-light);
}
.light-theme .btn.active{
    color: var(--white-to-black);
    outline: 1px solid var(--border);
}

.btn:not(:last-child) {
    margin-right: 2px;
}

.order-vals {
    text-align: right; /* for desktop alignment */
}

button.profit-calculator {
    padding: 0 12px;
    height: 30px;
}

.light-theme button.profit-calculator{
    border: 1px solid var(--border);
    background: var(--theme_3-background-light);
    color: var(--white-to-black);
}

button.profit-calculator i {
    vertical-align: middle;
}

.order-val {
    margin-bottom: var(--gutter);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-val:last-child {
    margin: 0;
}

.order-val .title {
    color: var(--white-to-black);
}

.order-val .digit {
    color: var(--green);
    font-weight: 800;
}

.order-val .digit.red {
    color: var(--red);
}

.profit-calculator-wrapper {
    text-align: right;
}

.profit-calculator-wrapper button {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    color: var(--font);
}

.profit-calculator-wrapper button:hover {
    color: var(--white);
}

.profit-calculator-wrapper i {
    margin-right: 5px;
}

.expiration {
    color: var(--white-to-black);
    margin-left: 5px;
}

.pending {
    white-space: nowrap;
    font-size: 1em;
    color: var(--font);
}

.pending.active {
    color: var(--white);
    margin-top: 10px;
}

.buy-sell {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gutter);
}

.button {
    height: auto;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: var(--white);
    font-weight: 800;
    font-size: 1.25em;
    cursor: pointer;
}

.button.up {
    /*background-color: var(--green-button);*/
    /*background-color: #3bb78f;*/
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
    /*background-color: var(--green);*/
}

.button.down {
    /*background-color: var(--red);*/
    /*background-color: #eb4511;*/
    background-image: linear-gradient(315deg, #eb4511 0%, #b02e0c 74%);
    /*background-color: var(--red);*/
}

.button.up.disabled, .button.down.disabled {
    background: var(--secondary-button);
    cursor: initial;
}

.button .price {
    text-align: center;
    font-weight: 400;
}

.meter {
    margin-top: 10px;
    place-self: start center;
    width: 100px;
    height: 100px;
    position: relative;
}

.meter .background {
    position: absolute; z-index:1;
}

.meter .arrow {
    position: absolute; z-index: 3;
    transition: all 300ms ease-in-out;
}

.light-theme .meter .arrow {
    filter: contrast(0.5);
}

.meter .sell {
    position: absolute; left: -24px; top: 4px; z-index: 5;
    color: #aa2a2a;
}

.meter .buy {
    position: absolute; right:-37px; top: 4px; z-index: 5;
    color: #2aaa51;
}

.meter .animal img {
    height: 30px;
}

.meter .animal.bull img {
    transform: scaleX(-1);
}

.meter .word {
    font-size: 10px;
    font-weight: bold;
}

.meter .percent {
    font-size: 14px;
    font-weight: normal;
}

/* Phones */

/*@media (max-width: 500px) {*/
@media screen and (orientation: portrait) {
    .order-menu {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        gap: var(--gutter);
        --gutter: 5px;
    }

    .volume-with-buttons { grid-column: 1/2; grid-row: 1/2; }
    .order-vals          { grid-column: 2/3; grid-row: 1/3; }
    .tpsl                { grid-column: 1/2; grid-row: 2/3; }
    .pending             { grid-column: 1/2; grid-row: 3/4; }
    .buy-sell            { grid-column: 2/3; grid-row: 3/4; }

    .order-vals {
        margin: 0;
    }

    .order-val {
        margin-bottom: 1px;
        font-size: 11px;
    }

    .order-val:last-of-type {
        margin-bottom: 0;
    }

    button.profit-calculator {
        margin-top: var(--gutter);
        width: 100%;
        font-size: 11px;
    }

    .buy-sell {
        gap: var(--gutter);
    }

    .button {
        padding: 10px 0;
    }

    .button .price {
        display: none;
    }
}


/* copied from auto-close modal */

.value-container {
    height: 55px;
    background-color: var(--background-light);
    border: 2px solid var(--background-dark);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: background-color ease-in-out 0.3s;
    flex: 1 1 auto;
}

.light-theme .value-container {
    background-color:var(--theme_3-background-light);
}

.value-container--grid {
    display: grid;
    grid-template-columns: 1fr 26px;
    grid-template-rows: 1fr 1fr;
}

.subtitle-input {
    grid-column: 1/2;
    grid-row: 1/3;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

input.value {
    background-color: transparent;
    border: none;
    width: 85%;
    cursor: pointer;
    font-size: 1.5em;
    margin-top: 3px;
    margin-left: 5px;
}

.plus {
    grid-column: 2/3;
    grid-row: 1/2;
    border-left: 2px solid var(--background-dark);
    border-bottom: 2px solid var(--background-dark);
    display: grid;
}

.minus {
    grid-column: 2/3;
    grid-row: 2/3;
    border-left: 2px solid var(--background-dark);
    display: grid;
}

.light-theme .subtitle-input,
.light-theme .plus,
.light-theme .minus{
    border: 1px solid var(--border)
}

.plus i, .minus i {
    place-self: center center;
    font-size: 12px;
}

/* modifiers */

.value-container:hover span {
    color: var(--white-to-black);
}

.value-container.transparent {
    border: 2px solid var(--background-light);
    background-color: transparent;
}

.value-container.transparent .plus {
    border-left: 2px solid var(--background-light);
    border-bottom: 2px solid var(--background-light);
}

.value-container.transparent .minus {
    border-left: 2px solid var(--background-light);
}


/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (min-width: 1300px) and (min-height: 900px) {
    .value-container, .btn, .grey-input {
        height: auto;
    }
}
</style>
