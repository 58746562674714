import { render, staticRenderFns } from "./BaseChartFearGreedIndex.vue?vue&type=template&id=36254b50&scoped=true"
import script from "./BaseChartFearGreedIndex.vue?vue&type=script&lang=js"
export * from "./BaseChartFearGreedIndex.vue?vue&type=script&lang=js"
import style0 from "./BaseChartFearGreedIndex.vue?vue&type=style&index=0&id=36254b50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36254b50",
  null
  
)

export default component.exports