<template>
    <table>
        <thead>
        <tr>
            <th @click="sortBy('symbol')">
                {{tokens.ordersPane.symbol}}
            </th>
            <th class="no-mobile" @click="sortBy('id')">
                {{tokens.ordersPane.id}}
            </th>
            <th @click="sortBy('type')">
                {{tokens.ordersPane.type}}
            </th>
            <th @click="sortBy('volume')">
                {{tokens.ordersPane.volume}}
            </th>
            <th class="no-mobile" @click="sortBy('price_open')">
                {{tokens.ordersPane.openPrice}}
            </th>
            <th class="no-mobile" @click="sortBy('time_open')">
                {{tokens.ordersPane.openTime}}
            </th>
            <th @click="sortBy('price_close')">
                {{tokens.ordersPane.closePrice}}
            </th>
            <th class="no-mobile" @click="sortBy('time_close')">
                {{tokens.ordersPane.closeTime}}
            </th>
            <th class="no-mobile" @click="sortBy('commission')">
                {{tokens.ordersPane.commission}}
            </th>
            <th v-if="!hideSwapColumn" class="no-mobile" @click="sortBy('swap')">
                {{tokens.ordersPane.swap}}
            </th>
            <th>
                {{tokens.ordersPane.pnl}}
            </th>
        </tr>
        </thead>
        <tbody ref="tables-container">
        <tr v-for="(order, i) in history"
            :key="i"
        >
            <td>
                <!--                    <img :src="staticBase+order.symbol.toUpperCase()+'.png'" />-->
                <span class="symbol" :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : ''" />
                {{symbols[order.symbol] ? symbols[order.symbol].alias : ''}}
            </td>
            <td class="no-mobile">
                {{order.id}}
            </td>
            <td :class="order.type % 2 ? 'red' : 'green'">
                {{orderTypeString(order.type)}}
            </td>
            <td>
                {{order.volume.toLocaleString()}}
            </td>
            <td class="no-mobile" v-if="['stage','interlendholdings','mftreasuries'].includes(brand)">
                {{
                    symbols[order.symbol] ?
                        order.price_open.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 5
                        }) :
                        0
                }}
            </td>
            <td class="no-mobile" v-else>
                {{
                    symbols[order.symbol] ?
                        order.price_open.toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) :
                        0
                }}
            </td>
            <td class="no-mobile">
                {{new Date(order.time_open * 1000).toLocaleString()}}
            </td>
            <td v-if="['stage','interlendholdings','mftreasuries'].includes(brand)">
                {{symbols[order.symbol] && order.price_close ? order.price_close.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 5 }) : '0'}}
            </td>
            <td v-else>
                {{symbols[order.symbol] && order.price_close ? order.price_close.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) : '0'}}
            </td>
            <td class="no-mobile">
                {{new Date(order.time_close * 1000).toLocaleString()}}
            </td>
            <td class="no-mobile">
                {{ currencySign(activeAccount.currency) + order.commission.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
            </td>
            <td v-if="!hideSwapColumn" class="no-mobile">
                {{ currencySign(activeAccount.currency) + order.swap.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
            </td>
            <td :class="order.profit > 0 ? 'green' : (order.profit < 0 ? 'red' : '')">
                {{currencySign(activeAccount.currency) +  order.profit.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision})}}
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {mapGetters} from 'vuex'
import {orderTypeString, timeToString, currencySign} from "@/common/helpers"
import {DISABLE_ORDER_SORT, BRAND, HIDE_SWAP_COLUMN} from "@/common/config"
import {SORT_HISTORY_ORDERS} from "@/store/mutations.type"

export default {
    name: "HistoryTable",
    props: ['active'],
    mounted() {
        setInterval(() => {
            if (!this.active) {
                this.historyPage = 1
                return
            }

            const container = document.getElementById('tables-container')
            const isAtBottom = container.scrollTop + container.clientHeight === container.scrollHeight
            const canLoadMore = this.activeAccount.history.length > this.historyPage * 20

            if (isAtBottom && canLoadMore) {
                this.historyPage++
            }
        }, 1000)
    },
    data() {
        return {
            historyPage: 1,
            disableOrderSort: DISABLE_ORDER_SORT,
            brand: BRAND,
            hideSwapColumn: HIDE_SWAP_COLUMN
        }
    },
    methods: {
        orderTypeString,
        timeToString,
        currencySign,
        sortBy(sortBy) {
            if (this.disableOrderSort) {
                return
            }

            this.$store.commit(SORT_HISTORY_ORDERS, {
                sort: sortBy,
                account: this.activeAccount.id
            })
        },
    },
    computed: {
        ...mapGetters(['symbols', 'tokens', 'activeAccount','staticBase', 'conversions']),
        history() {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const history = []

            if (!this.activeAccount.history) {
                return history
            }

            const startIndex = 0
            const endIndex = this.historyPage * 20
            const slicedHistory = this.activeAccount.history.slice(startIndex, endIndex)

            slicedHistory.forEach((order) => {
                const d = new Date(order.time_close * 1000)

                order.timeStr = ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
                order.dayStr = monthNames[d.getMonth()] + ' ' + ('0' + d.getDate()).slice(-2)
                order.month = monthNames[d.getMonth()]
                order.day = ('0' + d.getDate()).slice(-2)

                order.amount = order.easy_amount || order.margin

                history.push(order)
            })

            return history
        },
    },
    watch: {
        activeAccount() {
            this.historyPage = 1
            document.getElementById('tables-container').scrollTo(0, 0)
        }
    }
}
</script>

<style scoped>
table {
    width: 100%;
    position: relative;
}

table th {
    cursor: pointer;
}

tr:nth-child(even) td {
    background-color: rgba(255,255,255,0.02);
}

td, th {
    white-space: nowrap;
    text-align: right;
}

th {
    padding: 8px 0;
    position: sticky;
    top: 0;
    background-color: var(--background-dark);
}

td {
    padding: 3px 0;
}

td:first-child, th:first-child {
    text-align: left;
    padding-left: 10px;
}

td:last-child, th:last-child {
    padding-right: 10px;
}

td:first-child img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
}

td .symbol {
    zoom: 0.21875;
    margin-right: 23px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px;
}

@-moz-document url-prefix() {
    td .symbol {
        -moz-transform: scale(0.21875);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: -16px;
        top: -22px;
    }

    td:first-child {
        position: relative;
        padding-left: 28px !important;
    }
}
</style>
