export const manualPspList = [{
    alias: "bitcoin_manual",
    symbol: "BTC",
    text: 'Bitcoin'
},{
    alias: "tron_manual",
    symbol: "TRX",
    text: 'Tron',
},{
    alias: "xrp_manual",
    symbol: "XRP",
    text: 'XRP',
},{
    alias: "ethereum_manual",
    symbol: "ETH",
    text: 'Ethereum',
},{
    alias: "usdt_sol_manual",
    symbol: "SOLUSDT",
    text: 'USDT (SOL)',
},{
    alias: "usdc_sol_manual",
    symbol: "SOLUSDC",
    text: 'USDC (SOL)',
},{
    alias: "usdt_erc20_manual",
    symbol: "USDTE",
    text: 'USDT (ERC20)',
},{
    alias: "usdt_trc20_manual",
    symbol: "USDTT",
    text: 'USDT (TRC20)',
},{
    alias: "usdc_erc20_manual",
    symbol: "USDCE",
    text: 'USDC (ERC20)',
},{
    alias: "usdc_trc20_manual",
    symbol: "USDCT",
    text: 'USDC (TRC20)',
},{
    alias: "matic_manual",
    symbol: "MATIC",
    text: 'Polygon',
},{
    alias: "ltc_manual",
    symbol: "LTC",
    text: 'Litecoin',
},{
    alias: "nct_manual",
    symbol: "NCT",
    text: 'PolySwarn',
},{
    alias: "doge_manual",
    symbol: "DOGE",
    text: 'Dogecoin',
},{
    alias: "bnb_manual",
    symbol: "BNB",
    text: 'Binance Coin',
}



]
