<template>
    <div>
        <table :class="{hide: !historyLength}">
            <thead>
            <tr class="label__small">
                <th @click="sortBy('symbol')">
                    <div class="symbol-container">
                        {{tokens.ordersPane.symbol}}
                        <div class="sort-button material-symbols-outlined"
                        :class="`${getSortButtonClass('symbol')}`"></div>
                    </div>
                </th>
                <th class="no-mobile " @click="sortBy('id')">
                    {{tokens.ordersPane.id}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('id')}`"></div>
                </th>
                <th @click="sortBy('type')">
                    {{tokens.ordersPane.type}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('type')}`"></div>
                </th>
                <th @click="sortBy('volume')">
                    {{tokens.ordersPane.volume}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('volume')}`"></div>
                </th>
                <th class="no-mobile " @click="sortBy('price_open')">
                    {{tokens.ordersPane.openPrice}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('price_open')}`"></div>
                </th>
                <th class="no-mobile " @click="sortBy('time_open')">
                    {{tokens.ordersPane.openTime}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('time_open')}`"></div>
                </th>
                <th @click="sortBy('price_close')">
                    {{tokens.ordersPane.closePrice}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('price_close')}`"></div>
                </th>
                <th class="no-mobile " @click="sortBy('time_close')">
                    {{tokens.ordersPane.closeTime}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('time_close')}`"></div>
                </th>
                <th class="no-mobile " @click="sortBy('commission')">
                    {{tokens.ordersPane.commission}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('commission')}`"></div>
                </th>
                <th v-if="!hideSwapColumn" class="no-mobile " @click="sortBy('swap')">
                    {{tokens.ordersPane.swap}}
                    <div class="sort-button material-symbols-outlined"
                         :class="`${getSortButtonClass('swap')}`"></div>
                </th>
                <th class="no-hover">
                    {{tokens.ordersPane.pnl}}
                </th>
            </tr>
            </thead>
            <tbody ref="tables-container">
            <tr v-for="(order, i) in history"
                :key="i"
                class="body__small"
            >
                <td>
                    <!--                    <img :src="staticBase+order.symbol.toUpperCase()+'.png'" />-->
                    <span class="symbol" :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : ''" />
                    {{symbols[order.symbol] ? symbols[order.symbol].alias : ''}}
                </td>
                <td class="no-mobile">
                    {{order.id}}
                </td>
                <td :class="order.type % 2 ? 'red' : 'green'">
                    {{orderTypeString(order.type)}}
                </td>
                <td>
                    {{order.volume.toLocaleString()}}
                </td>
                <td class="no-mobile" v-if="['stage','interlendholdings','mftreasuries'].includes(brand)">
                    {{
                        symbols[order.symbol] ?
                            order.price_open.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 5
                            }) :
                            0
                    }}
                </td>
                <td class="no-mobile" v-else>
                    {{
                        symbols[order.symbol] ?
                            order.price_open.toLocaleString(undefined, {
                                minimumFractionDigits: activeAccount.precision,
                                maximumFractionDigits: activeAccount.precision
                            }) :
                            0
                    }}
                </td>
                <td class="no-mobile">
                    {{new Date(order.time_open * 1000).toLocaleString()}}
                </td>
                <td v-if="['stage','interlendholdings','mftreasuries'].includes(brand)">
                    {{symbols[order.symbol] && order.price_close ? order.price_close.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 5 }) : '0'}}
                </td>
                <td v-else>
                    {{symbols[order.symbol] && order.price_close ? order.price_close.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) : '0'}}
                </td>
                <td class="no-mobile">
                    {{new Date(order.time_close * 1000).toLocaleString()}}
                </td>
                <td class="no-mobile">
                    {{ currencySign(activeAccount.currency) + order.commission.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
                </td>
                <td v-if="!hideSwapColumn" class="no-mobile">
                    {{ currencySign(activeAccount.currency) + order.swap.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
                </td>
                <td :class="order.profit > 0 ? 'green' : (order.profit < 0 ? 'red' : '')">
                    {{currencySign(activeAccount.currency)+(order.profit).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision})}}
                </td>
            </tr>
            </tbody>
        </table>
        <div class="info" :class="{hide: historyLength}">
            <h3 class="title__medium">No Order History</h3>
            <span class="body__small">It seems you don't have any past orders yet.</span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {orderTypeString, timeToString, currencySign} from "@/common/helpers"
import {DISABLE_ORDER_SORT, BRAND, HIDE_SWAP_COLUMN} from "@/common/config"
import {SORT_HISTORY_ORDERS} from "@/store/mutations.type"

export default {
    name: "HistoryTable",
    props: ['active'],
    mounted() {
        setInterval(() => {
            if (!this.active) {
                this.historyPage = 1
                return
            }

            const container = document.getElementById('tables-container')
            const isAtBottom = container.scrollTop + container.clientHeight === container.scrollHeight
            const canLoadMore = this.activeAccount.history.length > this.historyPage * 20

            if (isAtBottom && canLoadMore) {
                this.historyPage++
            }
        }, 1000)
    },
    data() {
        return {
            historyPage: 1,
            disableOrderSort: DISABLE_ORDER_SORT,
            brand: BRAND,
            sortButtonState: {},
            hideSwapColumn: HIDE_SWAP_COLUMN
        }
    },
    methods: {
        orderTypeString,
        timeToString,
        currencySign,
        setSortButtonState(colName){
            if(this.sortButtonState.colName !== colName) {
                this.sortButtonState.colName = colName;
                this.sortButtonState.directionUp = false
            }else{
                this.sortButtonState.directionUp = !this.sortButtonState.directionUp
            }
        },
        getSortButtonClass(colName) {
            if(this.sortButtonState.colName !== colName) return '';
            return this.sortButtonState.directionUp ? 'up' : 'down';
        },
        sortBy(sortBy) {
            if (this.disableOrderSort) {
                return
            }

            this.$store.commit(SORT_HISTORY_ORDERS, {
                sort: sortBy,
                account: this.activeAccount.id
            })

            this.setSortButtonState(sortBy)
        },
    },
    computed: {
        ...mapGetters(['symbols', 'tokens', 'activeAccount','staticBase']),
        history() {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const history = []

            if (!this.activeAccount.history) {
                return history
            }

            const startIndex = 0
            const endIndex = this.historyPage * 20
            const slicedHistory = this.activeAccount.history.slice(startIndex, endIndex)

            slicedHistory.forEach((order) => {
                const d = new Date(order.time_close * 1000)

                order.timeStr = ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
                order.dayStr = monthNames[d.getMonth()] + ' ' + ('0' + d.getDate()).slice(-2)
                order.month = monthNames[d.getMonth()]
                order.day = ('0' + d.getDate()).slice(-2)

                order.amount = order.easy_amount || order.margin

                history.push(order)
            })

            return history
        },
        historyLength() {
            return Object.keys(this.history).length
        }
    },
    watch: {
        activeAccount() {
            this.historyPage = 1
            document.getElementById('tables-container').scrollTo(0, 0)
        }
    }
}
</script>

<style scoped>
table {
    width: 100%;
    position: relative;
    background: var(--System-Surface, #161B24);
    border-collapse: collapse;
    border: 1px solid var(--System-Surface5);

}

thead tr th:hover {
    cursor: pointer;
    background: var(--System-Surface3, #252B36);
}

td, th {
    white-space: nowrap;
    text-align: left;
    padding: 7px 7px 6px;
    background: var(--System-Surface);
}

th {
    position: sticky;
    top: 0px;
    border: 1px solid var(--System-Surface5);
    font-weight: 500;
}

.symbol-container::after ,
th::after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

.symbol-container::before ,
th::before {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

td {
    color: var(--System-OnSurface);
    border-right: 1px solid var(--System-Surface5);
}

td:last-child {
    border: none;
}

td:last-child span {
    cursor: pointer;
}

td:last-child span {
    margin-right: 15px;
}

td:last-child span:last-child {
    margin-right: 0;
}

td:first-child img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
}

td .symbol {
    zoom: 0.21875;
    margin-right: 23px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px;
}

td span:has(i) {
    display: flex;
    align-items: center;
}

td:has(i){
    display: flex;
    align-items: center;
}

td i {
    font-size: 16px;
    color: var(--System-Primary);
    height: 100%;
}

.red {
    color: var(--Custom-RedFixed)
}

.green {
    color: var(--Custom-GreenFixed)
}

th.no-hover:hover {
    background: transparent;
    cursor: default;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-height: 150px;
    text-align: center;
    padding: 5px 10px;
}

thead tr th:first-child,
td:has(span.symbol){
    position: sticky;
    left: 0;
    z-index: 5;
}

thead tr th:first-child{
    top: 0;
    z-index: 6;
}

thead tr th:first-child::after,
td:has(span.symbol)::after{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    right: -1px;
    top: 0;
}

thead tr th:first-child::before,
td:has(span.symbol)::before{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    left: -1px;
    top: 0;
}

.sort-button{
    display: inline-block;
    position: relative;
    width: 10px;
    height: 20px;
    transform: translateY(2px);
    margin-top: -3px;
    margin-bottom: -4px;
}

.sort-button::after,
.sort-button::before{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: var(--System-Outline);
    transition: color .2s ease;
}

.sort-button::before{
    content: 'arrow_drop_up';
    top: 35%;
}

.sort-button::after{
    content: 'arrow_drop_down';
    top: 65%;
}

.sort-button.up::before,
.sort-button.down::after {
    color: var(--System-Primary);
}

.sort-button.down::before,
.sort-button.up::after {
    color: var(--System-OutlineVariant);
}

@media (max-height: 900px) {
    .info{
        min-height: 15vh;
    }
}

@media (max-width: 767px) {
    .symbol-container {
        width: 105px;
    }
}

@-moz-document url-prefix() {
    td .symbol {
        -moz-transform: scale(0.21875);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: -16px;
        top: -22px;
    }

    td:first-child {
        position: relative;
        padding-left: 28px !important;;
    }
}
</style>
