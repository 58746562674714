<template>
    <div class="referrals-page">
        <BasePanel class="panel">
            <template #content>
                <div class="referrals-head">
                    <div class="title title__medium">{{ tokens.fullScreen.referrals }}</div>
                    <div class="subtitle label__large">{{ tokens.fullScreenReferrals.referralDesc }}</div>
                </div>
                <div class="inner">
                    <span class="title__medium">{{ tokens.fullScreenReferrals.shareLink }}</span>
                    <div class="link">
                        <div class="link__icon">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="1">
                            <path id="Vector" d="M19.6878 25.7901C18.613 25.7901 17.6999 25.4156 16.9484 24.6665C16.1967 23.9176 15.8208 23.0082 15.8208 21.9381C15.8208 21.8363 15.8436 21.6215 15.8892 21.2938L8.52663 17.0151C8.19494 17.2857 7.8221 17.498 7.4081 17.6521C6.9941 17.8063 6.55047 17.8833 6.07722 17.8833C5.00285 17.8833 4.08963 17.5061 3.33757 16.7516C2.5855 15.9971 2.20947 15.081 2.20947 14.0032C2.20947 12.9255 2.5855 12.0081 3.33757 11.2512C4.08963 10.4942 5.00285 10.1158 6.07722 10.1158C6.54841 10.1158 6.99682 10.1955 7.42244 10.3548C7.84807 10.5142 8.23207 10.7345 8.57444 11.0158L15.8734 6.76889C15.8464 6.65883 15.831 6.54558 15.827 6.42914C15.8229 6.31252 15.8208 6.19505 15.8208 6.07673C15.8208 5.00236 16.197 4.08914 16.9492 3.33708C17.7015 2.58502 18.6149 2.20898 19.6894 2.20898C20.7642 2.20898 21.6774 2.58511 22.4291 3.33736C23.1806 4.08961 23.5563 5.00311 23.5563 6.07786C23.5563 7.15242 23.1803 8.06555 22.4283 8.81723C21.6762 9.56892 20.763 9.94477 19.6886 9.94477C19.1923 9.94477 18.7271 9.85973 18.293 9.68967C17.8588 9.5198 17.4705 9.28364 17.1281 8.9812L9.89266 13.1642C9.93016 13.3067 9.9536 13.4441 9.96297 13.5763C9.97235 13.7085 9.97704 13.8522 9.97704 14.0074C9.97704 14.1629 9.96963 14.3121 9.95482 14.4552C9.94019 14.5982 9.91413 14.7406 9.87663 14.8824L17.0963 19.0657C17.4387 18.7526 17.8304 18.5057 18.2714 18.3252C18.7122 18.1446 19.1846 18.0543 19.6886 18.0543C20.763 18.0543 21.6762 18.4316 22.4283 19.1861C23.1803 19.9406 23.5563 20.8567 23.5563 21.9345C23.5563 23.0122 23.1802 23.9242 22.428 24.6705C21.6757 25.4169 20.7623 25.7901 19.6878 25.7901Z" fill="url(#paint0_linear_3005_29162)"/>
                            </g>
                            <defs>
                            <linearGradient id="paint0_linear_3005_29162" x1="2.20947" y1="2.20898" x2="19.9277" y2="32.5" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#4A7EFF"/>
                            <stop offset="0.486792" stop-color="#61BEDA"/>
                            <stop offset="1" stop-color="#404C73"/>
                            </linearGradient>
                            </defs>
                            </svg>
                        </div>
                        <div class="link__input label__large">{{ refferalLink }}</div>
                        <BaseButtonSmall 
                            :button-text="tokens.newDesign.copy"
                            :icon="'content_copy'" 
                            :button-style="'outlined-primary'"
                            @click.native="copyLink()"
                        />
                    </div>
                    <div class="desc">
                        <h3 class="title__medium">{{ tokens.fullScreenReferrals.subtitle }}</h3>
                        <span class="body__medium">{{ tokens.fullScreenReferrals.descSuccesfull }}</span>
                        <span class="body__medium">{{ tokens.fullScreenReferrals.descTracked }}</span>
                    </div>
                </div>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {NOTIFICATION} from '@/store/mutations.type'
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import BaseButtonSmall from '@/new-design/shared/ui/buttons/BaseButtonSmall'

export default {
    name: "ReferralsPage",
    components: { BasePanel, BaseButtonSmall },
    computed: {
        ...mapGetters(['tokens','user']),
        refferalLink(){
            return `https://${window.location.hostname}/register?referred_by=${this.user.id}`
        }
    },
    methods: {
        copyLink(){
            navigator.clipboard.writeText(this.refferalLink)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Link copied to clipboard')
                })
                .catch(() => {
                })
        }
    }
}
</script>

<style scoped>
.panel {
    border: none !important;
    padding: 0;
}

.referrals-head{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(74, 126, 255, 0.2), rgba(97, 190, 218, 0.2)), #161B24;
}

.title{
    color: #E3E2E9;
}

.subtitle{
    color: #9A9DAB;
}

.inner {
    padding: 32px 0px;
    color: var(--System-OnSurfaceVariant, #9A9DAB);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.inner>span{
    color: var(--System-OnSurface, #E3E2E9);
}

.desc{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

.desc>h3{
    color: var(--System-OnSurface, #E3E2E9);
}

.desc>span{
    color: var(--System-OnSurfaceVariant, #9A9DAB);
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant, #3F454F);
    padding: 8px 12px;
    gap: 16px;
}

.link__icon { 
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4.5px;
    background: var(--State-layers-primary-opacity-08, rgba(175, 198, 255, 0.08));
}

.link__input { 
    margin-right: auto;
    text-decoration: underline;
    cursor: pointer;
    word-break: break-word;
}
</style>
