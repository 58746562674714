import {BRAND} from "@/common/config";

export const pspMethods = () => {
    return {
        bank: {
            name: "Bank Transfer",
            type: "bank",
            currencies: ["USD"],
            order: -1
        },
        alwayspay_master: {
            name: "Credit/Debit card (Alwayspay Master)",
            currencies: ["USD"],
            type: "card"
        },
        alwayspay_visa: {
            name: "Credit/Debit card (Alwayspay Visa)",
            currencies: ["USD"],
            type: "card"
        },
        chargemoney: {
            name: "Credit/Debit card (Charge Money)",
            currencies: ["USD"],
            type: 'card',
        },
        checkoutchamp: {
            name: "Credit/Debit card (Check Out Champ)",
            currencies: ["EUR"],
            type: 'card',
        },
        'cc-ext': {
            name: "Credit/Debit card (CC-EXT)",
            currencies: ["USD", "EUR", "KGS", "RUB"],
            type: 'card',
        },
        bmopay: {
            name: "Credit/Debit card (Bmopay)",
            currencies: ["USD", "EUR"],
            type: 'card',
        },
        payonera: {
            name: "Credit/Debit card (Payonera)",
            currencies: ["USD", "CAD"],
            type: 'card',
        },
        pinpay: {
            name: "Credit/Debit card (Pinpay)",
            currencies: ["USD", "EUR", "KGS", "RUB"],
            type: 'card',
        },
        realeasypay: {
            name: "Credit/Debit card (REP)",
            currencies: ["USD"],
        },
        cascading: {
            name: "Credit/Debit card (CASC)",
            currencies: ["USD", "RUB"],
        },
        finanic: {
            name: "Credit/Debit card (FIN)",
            currencies: ["USD", "EUR"],
        },
        betatransfer: {
            name: "Credit/Debit card (BT)",
            currencies: ["RUB", "USD"],
        },
        prmoney: {
            name: "Credit/Debit card (PRM)",
            currencies: ["USD"],
        },
        prmoney_eur: {
            name: "Credit/Debit card (PRM SEPA)",
            currencies: ["EUR"],
        },
        prmoney_ru: {
            name: "Credit/Debit card (PRM RU)",
            currencies: ["RUB"],
        },
        starbill_usd: {
            name: "Credit/Debit card (SBU)",
            currencies: ["USD"],
        },
        starbill_eur: {
            name: "Credit/Debit card (SBE)",
            currencies: ["EUR"],
        },
        paypound: {
            name: "Credit/Debit card (PP)",
            currencies: ["USD", "EUR", "GBP"],
        },
        kryptova: {
            name: "Credit/Debit card (KR)",
            currencies: ["USD", "EUR", "GBP"],
        },
        walletix: {
            name: "Credit/Debit card (WI)",
            currencies: ["USD", "EUR", "RUB", "PLN"],
        },
        walletcomru: {
            name: "Credit/Debit card (WCR)",
            currencies: ["USD"],
        },
        payzon: {
            name: "Credit/Debit card (PZ)",
            currencies: ["USD", "EUR"],
        },
        bitexbit: {
            name: "Credit/Debit card (BB)",
            currencies: ["UAH"],
        },
        paystudio: {
            name: "Credit/Debit card (PS)",
            currencies: ["USD", "GBP", "EUR", "AUD", "NZD"],
        },
        stripe: {
            name: "Credit/Debit card (St)",
            currencies: ["USD", "EUR"],
        },
        settlepay_usd: {
            name: "Credit/Debit card (SPU)",
            currencies: ["USD"],
        },
        settlepay_eur: {
            name: "Credit/Debit card (SPE)",
            currencies: ["EUR"],
        },
        settlepay_kzt: {
            name: "Credit/Debit card (SPK)",
            currencies: ["KZT"],
        },
        garrypay: {
            name: "Credit/Debit card (GP)",
            currencies: ["USD", "EUR"],
        },
        texcent: {
            name: "Credit/Debit card (TC)",
            currencies: ["USD"],
        },
        billing_ru: {
            name: "Billing-Ru",
            currencies: ["RUB", "USD", "EUR", "UAH"],
        },
        billing_world: {
            name: "Billing-World",
            currencies: ["RUB", "USD", "EUR", "UAH"],
        },
        honeypay: {
            name: "Credit/Debit card (HP)",
            currencies: ["USD"],
        },
        square: {
            name: "Credit/Debit card (SQ)",
            currencies: ["USD"],
        },
        wellex: {
            name: "Credit/Debit card (WX)",
            currencies: ["RUB", "AZN", "KGS", "UZS"],
        },
        villpay: {
            name: "Credit/Debit card (VP)",
            currencies: ["RUB"],
        },
        payhub: {
            name: "Credit/Debit card (PH)",
            currencies: ["RUB"],
        },
        ppay: {
            name: "Credit/Debit card (PA)",
            currencies: ["USD", "RUB"],
        },
        pinikle: {
            name: "Credit/Debit card (Pinikle)",
            currencies: ["USD"],
        },
        enot: {
            name: "Credit/Debit card (Enot)",
            currencies: ["RUB", "USD", "EUR"],
        },
        decard: {
            name: "Credit/Debit card (Decard)",
            currencies: ["USD", "CLP", "PEN", "BRL"],
        },
        p2p_bank: {
            name: "Credit/Debit card (P2P Bank)",
            currencies: ["RUB", "KZT", "GEL"],
        },
        milkypay: {
            name: "Credit/Debit card (Milkypay)",
            currencies: ["RUB"],
        },
        todapay: {
            name: "Credit/Debit card (Todapay)",
            currencies: ["INR"],
        },
        payretailers: {
            name: "Credit/Debit card (Payretailers)",
            currencies: [
                "ARS",
                "BRL",
                "CLP",
                "COP",
                "CRC",
                "GTQ",
                "MXN",
                "PEN",
                "USD",
                "PAB",
            ],
        },
        dikeshield: {
            name: ['limitrex','cbtrex','firewix','foxiamix','orbitolympus','visematrix'].includes(BRAND) ? "Credit/Debit Card" : "Credit/Debit Card (DikeShield)",
            currencies: ['visematrix','stage'].includes(BRAND) ? ["EUR", "USD"] : ["USD"],
        },
        dikeshield_smartpay: {
            name: "Credit/Debit card (DikeShield Smartpay)",
            currencies: ["USD"],
        },
        dikeshield_2: {
            name: "Credit/Debit card (DikeShield)",
            currencies: ["USD"],
        },
        alfakit: {
            name: "Credit/Debit card (Alfakit)",
            currencies: ["USD", "EUR"],
        },
        momentex: {
            name: "Credit/Debit card (Momentex)",
            currencies: ["INR"],
        },
        betspacemoney: {
            name: "Credit/Debit card (Betspacemoney)",
            currencies: ["EUR"],
        },
        betspacemoney_card: {
            name: "Credit/Debit card (Betspacemoney card)",
            currencies: ["EUR", "USD"],
        },
        paycos: {
            name: "Credit/Debit card (paycos)",
            currencies: ["CLP", "MXN", "VES"],
        },
        storsunrise: {
            name: "Credit/Debit card (storsunrise)",
            currencies: ["USD"],
        },
        payepo: {
            name: "Credit/Debit card (Payepo)",
            currencies: ["USD"],
        },
        payepo_world: {
            name: "Credit/Debit card (RemitEX)",
            currencies: ["USD", "EUR", "GBP", "CAD"],
        },
        solidpayment: {
            name: "Credit/Debit card (TopTechPayment)",
            currencies: ["USD", "EUR", "GBP"],
        },
        transfertum: {
            name: "Credit/Debit card (Transfertum)",
            currencies: ["USD", "EUR"],
        },
        paypecker: {
            name: "Paypecker",
            currencies: ["USD", "EUR", "RUB", "KZT", "KGS", "UZS"]
        },
        nqpays: {
            name: "NQ Pays",
            currencies: ["USD"],
        },
        "cc-ext2": {
            name: "Credit/Debit card (CEX24)",
            currencies: ["USD","EUR"],
            type: 'card'
        },
        "cc-ext3": {
            name: "Credit/Debit card (CEX24)",
            currencies: ["EUR"],
            onlyButtons: [
                {
                    value: 300,
                    link: "https://buy.stripe.com/28oaEVaOcgwla40eWK",
                },
                {
                    value: 249,
                    link: "https://buy.stripe.com/7sI9AR3lK5RHb84eWL",
                },
                {
                    value: 220,
                    link: "https://buy.stripe.com/aEU28pbSgfsh0tqdSI",
                },
                {
                    value: 200,
                    link: "https://buy.stripe.com/4gw5kB1dCeodcc82a1",
                },
            ],
        },
    }
}
