<template>
    <div @click="activeSelect = false">
        <div class="fullscreen-page-title">
            {{ tokens.fullScreen.savings.toUpperCase() }}
        </div>

        <div class="form-row fs">
            <div class="form-title">
                {{ tokens.fullScreenSavings.detailedInformation.toUpperCase() }}
            </div>
        </div>
        <div class="inner">
            <div class="savings fs">

                <div class="savings-available fs">
                    <div class="item"
                         v-for="staking in stakingCurrencies"
                         :key="staking.currency"
                    >
                        <header @click="currencyChosen = currencyChosen === staking.currency ? '' : staking.currency">
                            <div class="image"><img :src="staticBase+`currencies/${staking.currency}.png`"></div>
                            <div class="name">{{ staking.currency }}</div>
                            <div class="rates">{{ staking.pct_0 && staking.pct_365 ? `${staking.pct_0.toFixed(1)} - ${staking.pct_365.toFixed(1)} %` : '0 %' }}</div>
                            <i class="material-icons" :class="{ active: staking.currency === currencyChosen }">{{ currencyChosen === staking.currency ? 'expand_less' : 'expand_more' }}</i>
                        </header>
                        <div class="periods fs" v-show="staking.currency === currencyChosen">
                            <div class="period"
                                 v-for="period in periods"
                                 :key="period"
                                 @click="periodChosen = period"
                                 :class="{active: period === periodChosen}"
                            >
                                <div class="radio"></div>
                                <input type="radio" v-model="periodChosen" :value="period" hidden>
                                <span>{{ tokens.fullScreenSavings[period] }}</span>
                                <span class="rate">{{ staking[period].toFixed(1) }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calculations fs" v-if="!theme_3_Design">
                    <div class="fs-please" v-if="!currencyChosen || !periodChosen">
                        {{ tokens.fullScreenSavings.pleaseSelect }}
                    </div>
                    <div class="lines fs" v-if="currencyChosen && periodChosen">
                        <div class="lines-content">
                            <div><span>{{ tokens.fullScreenSavings.currentTime }}:</span><strong>{{  new Date(time).toLocaleString() }}</strong></div>
                            <div v-show="periodChosen !== 'pct_0'"><span>{{ tokens.fullScreenSavings.releaseTime }}:</span><strong>{{  new Date(time + periodChosen.split('_')[1] * 86400000).toLocaleString()}}</strong></div>
                            <div><span>{{ tokens.fullScreenSavings.currencyChosen }}:</span><strong>{{ currencyChosen }}</strong></div>
                            <div><span>{{ tokens.fullScreenSavings.periodChosen }}:</span><strong>{{ tokens.fullScreenSavings[periodChosen] }}</strong></div>
                            <div><span>{{ tokens.fullScreenSavings.yourRate }}:</span><strong class="rate">{{ stakingCurrencies[currencyChosen][periodChosen].toFixed(1) }}%</strong></div>
                            <div v-show="periodChosen !== 'pct_0'">
                                <span>{{ tokens.fullScreenSavings.yourEarnings }}:</span>
                                <strong>{{ currencySign(currencyChosen) }} {{ ((amountChosen*stakingCurrencies[currencyChosen][periodChosen] * periodChosen.split('_')[1])/(100 * 365)).toFixed(2) }}</strong>
                            </div>
                            <div>
                                <span>{{ tokens.fullScreenSavings.accountFrom }}:</span>
                                    <select class="form-control fs" v-model="accountChosenId" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                        <option v-for="account in accounts"
                                                :key="account.id"
                                                :value="account.id"
                                        >
                                            {{ account.product.toUpperCase()+' '+account.id+' ('+currencySign(account.currency)+account.balance ? account.balance.toFixed(account.precision) : ''+')' }}
                                        </option>
                                    </select>
                            </div>
                            <div><span>{{ tokens.fullScreenSavings.enterAmount }} ({{ tokens.fullScreenSavings.in }} {{ currencyChosen }}):</span><input type="text" class="form-control amount fs img" v-model="amountChosen"></div>
                        </div>
                        <div>
                            <span></span>
                            <button class="fs-normal" @click="openSavingsAccount">{{ tokens.fullScreenSavings.openAndInvest }} {{ this.brand === 'standexcapital' ? '' : currencySign(currencyChosen)+Number(amountChosen || 0).toFixed(2) }}</button>
                        </div>

                    </div>

                </div>
                <div class="calculations fs" v-else>
                    <div class="fs-please" v-if="!currencyChosen || !periodChosen">
                        {{ tokens.fullScreenSavings.pleaseSelect }}
                    </div>
                    <div class="lines fs" v-if="currencyChosen && periodChosen">
                        <div class="pad">
                            <div class="pad-item">
                                <h2>{{ tokens.fullScreenSavings.currencyChosen }}</h2>
                                <div class="img-content">
                                    <img :src="staticBase+`currencies/${currencyChosen}.png`">
                                    {{ currencyChosen }}
                                </div>
                            </div>
                            <div class="pad-item">
                                <h2>{{ tokens.fullScreenSavings.currentTime }}</h2>
                                {{  new Date(time).toLocaleString() }}
                            </div>
                            <div class="pad-item">
                                <h2>{{ tokens.fullScreenSavings.releaseTime }}</h2>
                                {{new Date(time + periodChosen.split('_')[1] * 86400000).toLocaleString()}}
                            </div>
                            <div class="pad-item">
                                <h2>{{ tokens.fullScreenSavings.periodChosen }}</h2>
                                {{tokens.fullScreenSavings[periodChosen] }}
                            </div>
                        </div>
                        <div class="bottom-pad">
                            <div class="pad-item">
                                <h2>{{tokens.fullScreenSavings.yourRate}}</h2>
                                <span class="theme_3-green">{{ stakingCurrencies[currencyChosen][periodChosen].toFixed(1) }}%</span>
                            </div>
                            <div class="pad-item" v-show="periodChosen !== 'pct_0'">
                                <h2>{{ tokens.fullScreenSavings.yourEarnings }}</h2>
                                <div class="img-content">
                                    <img :src="staticBase+`currencies/${currencyChosen}.png`">
                                    {{((amountChosen * stakingCurrencies[currencyChosen][periodChosen] * periodChosen.split('_')[1]) / (100 * 365)).toFixed(2) }}
                                </div>
                            </div>
                        </div>
                        <div class="send-info">
                            <div class="info-item">
                                <label>{{ tokens.fullScreenSavings.accountFrom }}</label>
                                <select class="form-control fs" v-model="accountChosenId" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                    <option v-for="account in accounts"
                                            :key="account.id"
                                            :value="account.id"
                                    >
                                        {{ account.product.toUpperCase()+' '+account.id+' ('+currencySign(account.currency)+account.balance ? account.balance.toFixed(account.precision) : ''+')' }}
                                    </option>
                                </select>
                            </div>
                            <div class="info-item">
                                <div>{{ tokens.fullScreenSavings.enterAmount }} ({{ tokens.fullScreenSavings.in }} {{ currencyChosen }})</div>
                                <input type="text" class="form-control amount fs img" v-model="amountChosen">
                            </div>
                        </div>
                        <button class="fs-normal" @click="openSavingsAccount">{{ tokens.fullScreenSavings.openAndInvest }} {{ this.brand === 'standexcapital' ? '' : currencySign(currencyChosen)+Number(amountChosen || 0).toFixed(2) }}</button>
                    </div>
                </div>
            </div>

        <div class="savings-open">
            <div class="savings-items fs">
                <div class="item" v-for="account in stakingAccounts"
                     :key="account.id"
                >
                    <div class="item-header">
                        <div class="item-left">
                            <div class="image"><img :src="staticBase+`currencies/${account.currency}.png`"></div>
                            <div class="name">{{ account.currency }}</div>
                        </div>
                        <div class="rate">{{ account.annual_pct.toFixed(1) }}%</div>
                    </div>
                    <div class="details fs">
                        <div v-if="account.alias"><span>{{ tokens.fullScreenSavings.name }}:</span><strong>{{ account.alias }}</strong></div>
                        <div><span>{{ tokens.fullScreenSavings.investment }}:</span><strong>{{ currencySign(account.currency) + account.balance ? account.balance.toFixed(2) : '' }}</strong></div>
                        <div><span>{{ tokens.fullScreenSavings.period }}:</span><strong>{{ tokens.fullScreenSavings['pct_'+account.period]}}</strong></div>
                        <template v-if="account.period != 0">
                            <div><span>{{ tokens.fullScreenSavings.daysTotal }}:</span><strong>{{ account.period }} {{tokens.fullScreenSavings.days}}</strong></div>
                            <div><span>{{ tokens.fullScreenSavings.finalEarnings }}:</span><strong>{{ currencySign(account.currency) + ((account.balance * account.annual_pct * account.period)/(100 * 365)).toFixed(2) }}</strong></div>
                        </template>
                        <template v-if="account.period >= 0">
                            <div><span>{{ tokens.fullScreenSavings.created }}:</span><strong>{{ timeToString(account.created*1000).split(' ')[0] }}</strong></div>
                            <div><span>{{tokens.fullScreenSavings.releaseTime}}:</span><strong>{{ account.period > 0 ? timeToString((account.created*1000) + (account.period * 24 * 3600 * 1000)).split(' ')[0] : '-'  }}</strong></div>
                            <div><span>{{ tokens.fullScreenSavings.daysElapsed }}:</span><strong>{{Math.floor((time/1000 - account.created)/86400) }} {{ tokens.fullScreenSavings.days }}</strong></div>
                            <div><span>{{ tokens.fullScreenSavings.earnings }}:</span><strong>{{ currencySign(account.currency) + ((account.balance * account.annual_pct * (time/1000 - account.created))/(100 * 365 * 86400)).toFixed(6) }}</strong></div>
                        </template>
                    <div v-if="account.period != 0"><span>{{ tokens.fullScreenSavings.fundsReleasedIn }}:</span><strong>{{account.period - Math.floor((time/1000 - account.created)/86400) }} {{ tokens.fullScreenSavings.days }}</strong></div>
                </div>
                <div>
                    <button class="fs-normal" v-show="account.period == 0" @click="claim(account.id)">{{ tokens.fullScreenSavings.claim }}</button>
                </div>
                <div class="progressBar" v-if="account.period != 0">
                    <div class="empty">
                        <div class="filled" :style="{width: calcFilledPercent(account)}"></div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign, timeToString} from "@/common/helpers"
import {THEME_3_DESIGN, BRAND} from "@/common/config"
import {STAKING_CLAIM, STAKING_MAKE} from '@/store/actions.type'
import {NOTIFICATION_ERROR} from "@/store/mutations.type"

export default {
    name: "Savings",
    data() {
        return {
            activeSelect:false,
            brand: BRAND,
            periods: ['pct_0', 'pct_30', 'pct_90', 'pct_180', 'pct_365'],
            periodChosen: 'pct_0',
            currencyChosen: '',
            accountChosenId: 0,
            amountChosen: '0',
            time: '',
            minDeposit: 0,
            theme_3_Design: THEME_3_DESIGN
        }
    },
    mounted() {
        setInterval(() => {
            this.time = Date.now()
        }, 1000)
    },
    methods: {
        currencySign,
        timeToString,
        openSavingsAccount() {
            // kill if amount is below minimum
            if(this.amountChosen < this.minDeposit) {
                this.$store.commit(NOTIFICATION_ERROR, this.tokens.errors.LOCAL_MIN_STAKING + this.minDeposit)
                return
            }

            this.$store.dispatch(STAKING_MAKE, {
                currency: this.currencyChosen,
                amount: this.amountChosen,
                period: this.periodChosen.split('_')[1],
                account_from: this.accountChosenId
            })
            this.currencyChosen = ''
            this.periodChosen = ''
        },
        claim(id) {
            this.$store.dispatch(STAKING_CLAIM, {
                account_id: id
            })
        },
        calcFilledPercent(account) {
            let zeroDatum = 5,
                result = zeroDatum
            if (account.period) {
                let timeElapsed = (this.time / 1000 - account.created) / 86400,
                    percentElapsed = 100 * Math.floor(timeElapsed / account.period)
                result = (100 - zeroDatum) / 100 * percentElapsed + zeroDatum
            }
            return result + '%'
        }
    },
    computed: {
        ...mapGetters(['tokens', 'symbols', 'accounts', 'stakingAccounts', 'stakingCurrencies','staticBase'])
    },
    watch: {
        currencyChosen(val) {
            // set minimum deposit as default when user picks currency:
            console.log('fired watcher currencyChosen with val=' + val)
            for (let n in this.stakingCurrencies) {
                if (n === val) {
                    this.minDeposit = this.stakingCurrencies[n].min_deposit // minDeposit is also used in checking when trying to open a saving
                    this.amountChosen = this.minDeposit
                }
            }

            if (val && this.periodChosen && !this.accountChosenId) {
                for (let n in this.accounts) {
                    let a = this.accounts[n]
                    if (a.product === 'cfd') {
                        this.accountChosenId = a.id
                        return
                    }
                }
            }
        },
        periodChosen(val) {
            if (val && this.currencyChosen && !this.accountChosenId) {
                for (let n in this.accounts) {
                    let a = this.accounts[n]
                    if (a.product === 'cfd') {
                        this.accountChosenId = a.id
                        return
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.savings {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
}

.savings-available header {
    min-width: 320px;
    padding: 8px 10px;
    border-bottom: 1px solid var(--fs-border);
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    place-items: center start;
    gap: 10px;
    background: var(--background-dark-mid);
    color: var(--white-to-black);
    cursor: pointer;
}

.inner {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(2, auto);
    gap: 2vh;
}

.savings-available img {
    height: 20px;
}

.periods.fs .radio{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid #2B834E;
    position: relative;
}

.savings-available .name {
    justify-self: start;
}

.savings-available .rates {
    justify-self: end;
}

.savings-available .periods{
    padding-left: 40px;
    border-left: 1px solid #91BDFF;
    border-bottom: 1px solid var(--fs-border);
}

.period .radio{
    position: relative;
}

.savings-available .period.active .radio:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: #2bab40;
    border-radius: 100%;
}

.savings-available .period {
    padding: 8px 13px 8px 0;
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto 1fr auto;
    gap: 6px;
    align-items: center;
    color: var(--white);
    cursor: pointer;
}

.theme_3-design .savings-available .period {
    color: var(--white-to-black);
}

.savings-available .period:hover,
.savings-available .period.active {
    background: rgba(255, 255, 255, 0.05);
}

.savings-available .period.active .radio {
    background: var(--background-footer);
}

.savings-available .rate {
    color: var(--green);
}


.calculations {
    width: 100%;
}

.lines{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.lines-content{
    background-color: var(--background-dark-mid);
}

.calculations .lines-content > div {
    margin: 0 0 1vw 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calculations .lines-content > div:last-child{
    margin: 0;
}

.calculations strong {
    color: var(--white);
}

.calculations strong.rate {
    color: var(--green);
}

.calculations input[type=text] {
    text-align: left;
}


.savings-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.savings-items .item {
    width: 100%;
    padding: 15px;
    border: 1px solid var(--fs-border);
    border-radius: 3px;
    background-color: var(--background-dark-mid);
}

.savings-items .item > .item-header{
    display: grid;
    grid-template-columns: repeat(2,auto);
    align-items: center;
    place-content: center space-between;
}

.item .item-left{
    display: grid;
    grid-template-columns: repeat(2,auto);
    align-items: center;
    gap: 1vw;
}

.savings-items .image img {
    height: 50px;
}

.savings-items .name {
    font-size: 30px;
    text-tranfsorm: uppercase;
}

.savings-items .rate {
    font-size: 50px;
    font-weight: bold;
    background: linear-gradient(180deg, #E3FF72 0%, #3FBC2A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.savings-items .details > div {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.savings-items .details strong {
    color: var(--white-to-black)
}

.savings-items button {
    width: 100%;
}

.savings-items .progressBar {
    height: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.savings-items .progressBar .empty {
    height: 12px;
    border: 1px solid var(--white);
    border-radius: 10px;
}

.savings-items .progressBar .filled {
    height: 100%;
    background: linear-gradient(95deg, #60BD35 25%, rgba(135, 194, 60, 0.35) 90%);
    border-radius: 1vw;
}

.fs-please {
    background: var(--background-dark-mid);
    text-align: center;
    font-size: 18px;
    color: var(--white-to-black);
    font-weight: 300;
    padding: 30px;
}

.savings-available i {
    color: var(--white-to-black);
}

.savings-available.fs i.active{
    background-color: #91BDFF;
    border-radius: 100%;
    color:black;
}

.theme_3-design .savings-available.fs .item {
    /* background: rgba(47, 50, 65, 0.5); */
    border: 1px solid #34384C;
    border-radius: 32px;
    margin-bottom: 12px;
}

.theme_3-design .savings-available.fs .item:last-child {
    margin-bottom: 0;
}

.theme_3-design .savings-available.fs header {
    background-color: transparent;
    border: none;
    padding: 15px 25px;
}

.theme_3-design .savings-available.fs .periods {
    border: none;
    padding: 15px 25px;
}

.theme_3-design .savings-available .period.active {
    background-color: transparent;
}

.theme_3-design .periods.fs .radio {
    border: 2px solid  #11CABE;
    width: 21px;
    height: 21px;
}

.theme_3-design .savings-available .period.active .radio::after {
    background-color: #11CABE;
    width: 7px;
    height: 7px;
}

.theme_3-design .savings-available.fs .period {
    gap: 16px;
}

.theme_3-design .rate {
    color: var(--white-to-black);
}

.theme_3-design .savings-available.fs i.active {
    background-color: transparent;
    color: var(--white-to-black);
}

.pad {
    background: var(--theme_3-background-third);
    border-radius: 12px;
    color: var(--whitesmoke-to-lightgrey);
    padding: 35px 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 30px 0;
}

.pad-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    min-height: 70px;
    background: var(--theme_3-background-third);
    border-radius: 12px;
}

.pad-item h2 {
    font-size: 12px;
}

.pad .pad-item {
    border-right: 1px solid #141518;
    padding-right: 40px;
    margin-right: 40px;
}

.pad .pad-item:last-child {
    padding: 0;
    margin: 0;
    border: none;
}

.pad-item .img-content {
    display: grid;
    grid-template-columns: repeat(2,max-content);
    place-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: bold;
    color: var(--white);
}

.theme_3-design .pad-item .img-content {
    color: var(--white-to-black);
}

.pad-item .img-content img {
    width: 34px;
    height: 34px;
}
.bottom-pad {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    margin-top: 20px;
    gap: 20px;
}

.bottom-pad .pad-item {
    padding: 35px 24px;
}

.theme_3-design .savings-items.fs .item {
    background: var(--theme_3-background-third);
    border-radius: 12px;
    border: none;
}

.send-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.info-item {
    display: grid;
    grid-template-columns: 1fr;
}

.info-item:first-child {
    margin-right: 20px;
}

.theme_3-design .form-control.fs {
    width: 100% !important;
    background: var(--theme_3-background-input);
    border-radius: 100px;
}

.theme_3-design .lines button.fs-normal {
    width: 50%;
    margin: 30px 0 0 auto;
}

@media (min-width: 1921px) {
    .savings-available .period.active .radio:after{
        width: .4vw;
        height: .4vw;
    }

    .periods.fs .radio {
        width: 1vw;
        height: 1vw;
        border: .2vw solid #2B834E;
    }
}

@media (min-width: 1000px) {
    .theme_3-design .savings-available.fs header {
        border: none;
        padding: 1vw 0.8vw;
    }

    .theme_3-design .savings-available.fs .periods {
        border: none;
        padding: 1vw 0.8vw;
    }

    .theme_3-design .periods.fs .radio {
        border: .1vw solid  #11CABE;
        width: 1vw;
        height: 1vw;
    }

    .theme_3-design .savings-available.fs .period {
        gap: 1vw;
    }

    .theme_3-design .savings-items .item {
        border: none;
    }

    .theme_3-design .form-control.fs {
        border-radius: 10vw;
    }

    .theme_3-design .savings-items.fs .item {
        border: none;
    }

    .theme_3-design .lines button.fs-normal {
        margin: .8vw 0 0 auto;
        font-size: 1vw;
    }

    .lines-content{
        padding: 1.5vw 2.5vw;
        margin-bottom: 1.5vw;
    }

    .calculations.fs select,
    .calculations.fs input[type=text] {
        width: 10vw;
        position: relative;
    }

    .calculations.fs select::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: red;
    }

    .calculations.fs input[type=text] {
        text-align: left;
    }

    .calculations.fs select{
        width: 18vw;
        border-radius: .5vw;
    }

    .calculations .lines.fs > div:last-child{
        justify-content: flex-start;
        height: auto;
        margin: 0;
    }

    .calculations .lines.fs > div span,
    .calculations .lines.fs > div strong,
    .details.fs > div > span,
    .details.fs > div > strong
    {
        font-size: .8vw;
    }

    .savings.fs{
        gap: 1.5vw;
    }
    .savings-available.fs header {
        padding: .5vw .6vw;
        border-bottom: .1vw solid var(--background-dark);
        gap: .5vw;
        min-width: 16vw;
        width: 100%;
    }

    .savings-items.fs .item-header{
        margin-bottom: 1vw;
    }

    .savings-available.fs header img {
        height: 1vw;
    }

    .savings-available.fs .name,
    .savings-available.fs .rate,
    .savings-available.fs span,
    .savings-available.fs .rates{
        font-size: 1vw;
    }

    .savings-available.fs input{
        width: 1vw;
        height: 1vw;
    }

    .savings-available.fs i{
        font-size: 1.25vw;
        position: relative;
    }

    .savings-available.fs i::after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 1.5vw;
        height: 1.5vw;
        border: .1vw solid var(--font);
        border-radius: 50%;
    }

    .theme_3-design .savings-available.fs i::after {
        border: none;
    }

    .savings-available.fs .periods{
        padding-left: 2vw;
    }

    .savings-available.fs .period {
        padding: .5vw .7vw .5vw 0;
        gap: 3vw;
    }

    .savings-items.fs {
        gap: 1.5vw;
    }

    .savings-items.fs .item {
        width: 100%;
        padding: .7vw;
        border: .1vw solid var(--fs-border);
        border-radius: .5vw;
    }

    .savings-items.fs .image img {
        height: 2vw;
    }

    .savings-items.fs .name {
        font-size: 1.5vw;
    }

    .savings-items.fs .rate {
        font-size: 2.6vw;
    }

    .savings-items.fs .details > div {
        margin: .5vw 0;
    }

    .savings-items.fs .progressBar {
        height: 2.8vw;
    }

    .savings-items .progressBar .empty {
        height: .8vw;
        border: .1vw solid var(--background-footer);
        border-radius: 1vw;
    }

    .fs-please {
        padding: 7vw 12vw;
        border-radius: .5vw;
        font-size: 1vw;
    }
}

@media (max-width: 1000px) {
    .inner{
        gap: 40px;
    }

    .lines-content{
        padding: 25px 20px;
        margin-bottom: 20px;
    }

    .calculations .lines-content > div{
        margin-bottom: 10px;
    }

    .calculations select,
    .calculations input[type=text] {
        font-size: 10px;
    }


    .savings.fs{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .savings-available .periods{
        padding-left: 20px;
    }

    .savings-items{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .savings-items .name{
        font-size: 24px;
    }

    .savings-items .rate{
        font-size: 40px;
    }
}

@media (max-width: 767px) {
    .savings {
        flex-direction: column;
    }

    .theme_3-design .lines button.fs-normal {
        width: 100%;
    }
}


.theme_3-design .lines {
    display: grid;
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: 1fr;
    gap: 40px;
}
</style>
