<template>
    <div class="chips-list">
        <ProgressChipsListItem 
            v-for="item in categoriesItems" 
            :key="item.id" 
            :item="item"
            :active="item.id === active"
            @update="(e) => $emit('update',e)"
        />
    </div>
</template>

<script>
import ProgressChipsListItem from "@/new-design/entities/Verification/ui/ProgressChipsListItem"

export default {
    name: "ProgressChipsList",
    components: {ProgressChipsListItem},
    props: {
        categoriesItems: {
            type: Array,
            default: () => [],
            required: true,
        },
        active: {
            type: String,
        }
    }
}
</script>

<style scoped>
.chips-list {
    display: flex;
    align-items: center;
    gap: 12px;
    overflow-x: auto;
    padding-bottom: 5px;
}

@media(max-width: 768px) {
    .chips-list {
        gap: 8px;
    }
}
</style>
