<template>
    <div class="side-menu" :class="{hide: isMobile}">
        <div class="menu">
            <div class="item" :class="{active: modalMenu.market, isMobile: isMobile}" @click="showMenuModal('market')">
                <i class="material-icons">view_list</i>
                <span>{{ tokens.sideMenu.marketWatch.toUpperCase() }}</span>
                <i class="material-icons toggle-btn" :class="{hide: modalMenuSplit > 1 && !modalMenu.market, minus: modalMenu.market && modalMenuSplit > 0}" @click.stop="toggleWidget('market')">{{ modalMenu.market ? 'remove_circle' : 'add_circle' }}</i>
            </div>
            <div class="item" :class="{active: modalMenu.orders, isMobile: isMobile}" @click="showMenuModal('orders')">
                <i class="material-icons">local_mall</i>
                <span>{{ tokens.sideMenu.activeOrders.toUpperCase() }}</span>
                <i class="material-icons toggle-btn" :class="{hide: modalMenuSplit > 1 && !modalMenu.orders, minus: modalMenu.orders && modalMenuSplit > 0 }" @click.stop="toggleWidget('orders')">{{ modalMenu.orders ? 'remove_circle' : 'add_circle' }}</i>
            </div>
            <div class="item" :class="{active: modalMenu.history, isMobile: isMobile}" @click="showMenuModal('history')">
                <i class="material-icons">history</i>
                <span>{{ tokens.sideMenu.tradingHistory.toUpperCase() }}</span>
                <i class="material-icons toggle-btn" :class="{hide: modalMenuSplit > 1 && !modalMenu.history, minus: modalMenu.history && modalMenuSplit > 0}" @click.stop="toggleWidget('history')">{{ modalMenu.history ? 'remove_circle' : 'add_circle' }}</i>
            </div>
            <div class="item" v-if="!removeEconomicCalendar" :class="{active: modalMenu.calendar, isMobile: isMobile}" @click="showMenuModal('calendar')">
                <i class="material-icons">today</i>
                <span>{{ tokens.sideMenu.economicCalendar.toUpperCase() }}</span>
                <i class="material-icons toggle-btn" :class="{hide: modalMenuSplit > 1 && !modalMenu.calendar, minus: modalMenu.calendar && modalMenuSplit > 0}" @click.stop="toggleWidget('calendar')">{{ modalMenu.calendar ? 'remove_circle' : 'add_circle' }}</i>
            </div>
            <div class="item" :class="{active: modalMenu.news, isMobile: isMobile}" @click="showMenuModal('news')">
                <i class="material-icons">campaign</i>
                <span>{{ tokens.sideMenu.marketNews.toUpperCase() }}</span>
                <i class="material-icons toggle-btn" :class="{hide: modalMenuSplit > 1 && !modalMenu.news, minus: modalMenu.news && modalMenuSplit > 0}" @click.stop="toggleWidget('news')">{{ modalMenu.news ? 'remove_circle' : 'add_circle' }}</i>
            </div>

            <div v-if="addMetatrader4Logo" class="item metatrader-logo" :class="{active: modalMenu.news, isMobile: isMobile}">
                <img :src="staticBase + '/misc/MT4.png'" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_MENU} from "@/store/mutations.type";
import {isMobile} from '@/common/helpers'
import {REMOVE_ECONOMIC_CALENDAR,STATIC_BASE,ADD_METATRADER4_LOGO} from "@/common/config";

export default {
    name: "SideMenu",
    data() {
        return {
            isMobile: isMobile(),
            removeEconomicCalendar: REMOVE_ECONOMIC_CALENDAR,
            staticBase: STATIC_BASE,
            addMetatrader4Logo: ADD_METATRADER4_LOGO
        }
    },
    methods: {
        showMenuModal(item) {
            this.$store.commit(MODAL, {
                menu: true
            })

            let m = {
                orders: false,
                history: false,
                market: false,
                calendar: false,
                news: false
            }

            m[item] = true

            this.$store.commit(MODAL_MENU, m)
        },
        toggleWidget(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for(let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }

            if (n[widget] && !this.modals.menu) {
                this.$store.commit(MODAL, {
                    menu: true
                })
            }
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals', 'modalMenu']),
        modalMenuSplit() {
            let i = 0
            for(let n in this.modalMenu) {
                if (this.modalMenu[n]) {
                    i++
                }
            }

            return i
        }
    }
}
</script>

<style scoped>
.side-menu {
    flex: 0 0 100px;
    display: flex;
    align-items: stretch;
    background: var(--background-dark);
}

.menu {
    display: flex;
    flex-direction: column;
}

.menu .item {
    height: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    font-weight: 400;
    font-size: 0.9em;
    position: relative;
    padding: 0 10px;
    border-bottom: 1px solid var(--background-dark);
    transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
}

.menu .item i {
    margin-bottom: 5px;
}

.menu .item span {
    text-align: center;
    /*word-break: break-all;*/
}

.menu .item:hover, .menu .item.active {
    color: var(--white);
    background-color: var(--background-light);
}
.light-theme .menu .item:hover, 
.light-theme .menu .item.active {
    color: var(--white-to-black);
    background-color: var(--theme_3-color-hover);
}

.menu .item:hover .toggle-btn, .toggle-btn.minus {
    opacity: 1;
}

.menu .item.metatrader-logo{
    margin: auto 0 25px;
    &:hover{
        background: transparent;
        cursor: default;
    }
    img{
        width: 90%;
        height: 90%;
        object-fit: contain;
        object-position: center;
    }
}

.toggle-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1.6em;
    color: var(--font);
    cursor: pointer;
    transition: color ease-in-out 0.3s, opacity ease-in-out 0.3s;
    opacity: 0;
}

.isMobile .toggle-btn{
    opacity: 1;
    top: 1px;
    right: 3px;
}

.isMobile .menu .item{
    align-items: flex-end;
}

.isMobile .material-icons:last-child{
    font-size: 12px
}

.toggle-btn:hover {
    color: var(--white);
}
.light-theme .toggle-btn:hover {
    color: var(--white-to-black);
}

@media (max-width: 767px) {
    .side-menu {
        flex: 0 0 40px;
    }

    .menu .item {
        width: 40px;
        height: 40px;
        font-size: 0.5em;
    }

    .menu .item i {
        font-size: 1.7em;
    }
}
</style>
