<template>
    <div class="inner">
        <div class="hold">
            <div class="left">
            </div>
            <div class="grid-item meter">
                <div class="sell">
                    <div class="word">{{ tokens.assetInfoModal.sell }}</div>
                    <div class="percent">{{ (100 - trend).toFixed(1) + '%' }}</div>
                </div>

                <div class="line">
                    <div :style="`width: ${trend}%`" class="green"></div>
                    <div class="red"></div>
                </div>
                <div class="buy">
                    <div class="word">{{ tokens.assetInfoModal.buy }}</div>
                    <div class="percent">{{ trend.toFixed(1) + '%' }}</div>
                </div>
            </div>
        </div>

        <div class="stat">
            <div class="left">
                <div :class="{active: candle === '60 min'}" class="box" @click="candleChange('60 min', 0)">
                    <div class="change">
                        <div>{{ tokens.assetInfoModal.oneHourChange }}</div>
                        <div :class="changePrice[0]  < 0 ? 'red' : (changePrice[0]   > 0 ? 'green' : '')"
                             class="candle">
                            {{ changePrice[0] + '%' }}
                        </div>
                    </div>
                    <div class="info">
                        <div class="title">
                            <div>{{ tokens.assetInfoModal.low }}</div>
                            <div>{{ tokens.assetInfoModal.high }}</div>
                        </div>
                        <div class="line">
                            <div :style="`left: ${change[0]}%`"></div>
                        </div>
                        <div class="info-price">
                            <div class="price">{{ newCandleData[0].low }}</div>
                            <div class="price">{{ newCandleData[0].high }}</div>
                        </div>
                    </div>
                </div>
                <div :class="{active: candle === '1 day'}" class="box" @click="candleChange('1 day',1)">
                    <div class="change">
                        <div>{{ tokens.assetInfoModal.oneDayChange }}</div>
                        <div :class="changePrice[1]  < 0 ? 'red' : (changePrice[1] > 0 ? 'green' : '')"
                             class="candle">
                            {{ isFinite(changePrice[1]) ? changePrice[1] + '%' : tokens.assetInfoModal.loading }}
                        </div>
                    </div>
                    <div class="info">
                        <div class="title">
                            <div>{{ tokens.assetInfoModal.low }}</div>
                            <div>{{ tokens.assetInfoModal.high }}</div>
                        </div>
                        <div class="line">
                            <div :style="`left: ${change[1]}%`"></div>
                        </div>
                        <div class="info-price">
                            <div class="price">{{ newCandleData[1].low }}</div>
                            <div class="price">{{ newCandleData[1].high }}</div>
                        </div>
                    </div>
                </div>
                <div :class="{active: candle === '1 week'}" class="box" @click="candleChange('1 week',2)">
                    <div class="change">
                        <div>{{ tokens.assetInfoModal.oneWeekChange }}</div>
                        <div :class="changePrice[2]  < 0 ? 'red' : (changePrice[2] > 0 ? 'green' : '')"
                             class="candle">
                            {{ isFinite(changePrice[2]) ? changePrice[2] + '%' : tokens.assetInfoModal.loading }}
                        </div>
                    </div>
                    <div class="info">
                        <div class="title">
                            <div>{{ tokens.assetInfoModal.low }}</div>
                            <div>{{ tokens.assetInfoModal.high }}</div>
                        </div>
                        <div class="line">
                            <div :style="`left: ${change[2]}%`"></div>
                        </div>
                        <div class="info-price">
                            <div class="price">{{ newCandleData[2].low }}</div>
                            <div class="price">{{ newCandleData[2].high }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="graphics">
                    <highcharts :options="chartOptions"/>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div :class="{active: candle === '1 month'}" class="more" @click="candleChange('1 month',3 )">
                <div>{{ tokens.assetInfoModal.oneMonthChange }}</div>
                <div class="candle">{{ isFinite(changePrice[3]) ? changePrice[3] + '%' : tokens.assetInfoModal.loading }}
                </div>
            </div>
            <div :class="{active: candle === '3 months'}" class="more" @click="candleChange('3 months',4 )">
                <div>{{ tokens.assetInfoModal.threeMonthsChange }}</div>
                <div class="candle">{{ isFinite(changePrice[4]) ? changePrice[4] + '%' : tokens.assetInfoModal.loading }}
                </div>
            </div>
            <div :class="{active: candle === '1 year'}" class="more" @click="candleChange('1 year',5)">
                <div>{{ tokens.assetInfoModal.oneYearChange }}</div>
                <div class="candle">{{ isFinite(changePrice[5]) ? changePrice[5] + '%' : tokens.assetInfoModal.loading }}
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import RestService from "@/common/rest.service";

export default {
    name: "Information",
    props: ['symbolActive'],
    mounted() {
        for (let i = 0; i < this.candleDataTime.length; i++) {
            (i => {
                RestService.get('/quotes/' + this.symbolActive + '/history', {
                    frame: this.candleDataTime[i] * 60,
                    count: this.candleDataCount[i],
                    offset: 0,
                    group: this.symbols[this.symbolActive].group
                })
                    .then(data => {
                        this.data = []

                        for (let j = 0; j < this.candleDataCount[i]; j++) {
                            if (data[j] === undefined) {
                                break
                            }

                            this.data[j] = data[j][3]
                        }
                        let min = Math.min.apply(null, this.data)
                        let max = Math.max.apply(null, this.data)
                        this.newCandleData[i].newData = this.data
                        this.newCandleData[i].low = min
                        this.newCandleData[i].high = max

                        this.chartOptions.series[0].data = this.newCandleData[0].newData
                        this.chartOptions.yAxis.min = this.newCandleData[0].low
                        this.chartOptions.yAxis.max = this.newCandleData[0].high
                    })
            })(i);
        }
    },
    data() {
        return {
            load: false,
            candle: '60 min',
            candleTime: 0,
            data: [],
            candleDataTime: [1, 30, 240, 1440, 60, 1440],
            candleDataCount: [59, 47, 41, 29, 89, 51],
            newCandleData: [{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            }],
            chartOptions: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    height: 244.5,
                    maxWidth: 500,
                    type: 'areaspline',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        fillColor: {
                            linearGradient: [0, 0, 50, 300],
                            stops: [
                                [0, '#1d98f9'],
                                [1, 'rgba(24,31,45,0.5)']
                            ]
                        },

                    }
                },
                legend: false,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                innerSize: '60%',
                enableMouseTracking: true,
                tooltip: {
                  enabled: false
                },
                series: [{
                    name: '60 min',
                    data: [],
                    marker:{
                        enable: false,
                        fillColor: 'transparent',
                        states: {
                          hover: {
                            enabled: false
                          }
                        }
                    },
                }]
            },
        }
    },
    methods: {
        candleChange(candle, time) {
            this.candle = candle
            this.candleTime = time
        },

    },
    computed: {
        trend() {
            return (this.symbolActive) ? this.symbols[this.symbolActive].quote.trend : 50
        },
        angle() {
            return 90 / 50 * this.trend - 90
        },
        changePrice() {
            let changePrice = []
            for(let time = 0; time<6; time++) {
                let result = ((this.symbols[this.symbolActive].quote.bid - this.newCandleData[time].newData[1]) / this.newCandleData[time].newData[1]) * 100
                changePrice.push(result > 0 ? '+' + result.toFixed(2) : result.toFixed(2))
            }

            return changePrice
        },
        change() {
            let changeArr = []
            for(let time = 0; time<6; time++) {
                let change = ((this.symbols[this.symbolActive].quote.bid - this.newCandleData[time].low) / (this.newCandleData[time].high - this.newCandleData[time].low)) * 100
                if (change >= 100) {
                    changeArr.push(98)
                } else if (change <= 0) {
                    changeArr.push(2)
                } else {
                    changeArr.push(change || 2)
                }
            }
            return changeArr
        },
        ...mapGetters(['symbols', 'themeColors', 'modalAssetsInfo', 'tokens'])
    },
    watch: {
        themeColors(val) {
            this.chartOptions.series[0].color = val['brand']
        },
        candleTime(el) {
            this.chartOptions.series[0].name = this.candle
            this.chartOptions.series[0].data = this.newCandleData[el].newData
            this.chartOptions.yAxis.min = this.newCandleData[el].low
            this.chartOptions.yAxis.max = this.newCandleData[el].high
        },
    }
}
</script>

<style scoped>

.hold {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    background-color: var(--background-dark);
    padding: 15px;
    border-radius: 10px;
}

.inner-stat{
    background-color: var(--red);
    transition: background-color .3s ease-in-out;
}

.inner-stat.loaded{
    background-color: transparent;
    width: 100%;
    height: 700px;
    position: absolute;
    z-index: 10;
    top: 220px;
    left: 0;
}

.grid-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
}

.line {
    margin: 0px 10px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    overflow: hidden;
    position: relative;
    height: 10px;
}

.line .red,
.line .green {
    position: absolute;
    top: 0;
    height: 100%;
}

.word,
.percent {
    font-weight: 400;
    color: var(--white-to-black);
}

.line .red {
    background-color: var(--red);
    width: 100%;
    left: 0;
}

.line .green {
    background-color: var(--green);
    z-index: 4;
    right: 0;
}

.hold-title {
    color: var(--white);
}

.stat {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
}

.stat .box {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 15px;
    transition: background-color .3s ease-in-out;
    border-radius: 5px;
}

.stat .change div {
    font-size: 1.18em;
}

.stat .change .candle {
    font-size: 2em;
    font-weight: 400;
    margin-top: 5px;
}

.stat .line {
    margin: 5px 0px;
    width: 100%;
    height: 8px;
    background-color: var(--border);
    border-radius: 2px;
    position: relative;
}

.stat .line div {
    position: absolute;
    top: 50%;
    background-color: var(--font);
    width: 7px;
    height: 7px;
    border-radius: 6px;
    transform: translate(-50%, -50%);
}

.stat .title, .info-price {
    display: flex;
    justify-content: space-between;
}

.stat .graphics {
    width: 100%;
    height: 100%;
    background-color: var(--background-dark);
}

.bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid #7a7b80;
    margin-top: 30px;
}

.bottom .more {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-items: center center;
    border-right: 2px solid #7a7b80;
    padding: 15px;
}

.bottom .more.active {
    background-color: var(--background-dark-mid);
}
.light-theme .more.active {
    background-color: var(--theme_3-background-active);
}

.bottom .more:last-child {
    border-right: none;
}

.bottom .more:first-child {
    padding: 0px 10px 0px 0px;
}

.bottom .more .candle {
    font-size: 1.5em;
    font-weight: 400;
    margin-top: 5px;
    color: var(--white-to-black);
}

.box.active {
    border-radius: 0px;
    background-color: var(--background-dark-mid);
}
.light-theme .box.active {
    border-radius: 0px;
    background-color: var(--theme_3-background-active);
}

.box.active .change:first-child, .box.active .title, .box.active .price {
    color: var(--white-to-black);
}

.box.active .line {
    background-color: var(--background-dark);
}
.light-theme .box.active .line {
    background-color: #cdcccc;
}

@media (max-width: 768px) {
    .stat {
        grid-template-columns: none;
        grid-template-rows: repeat(2, 1fr);
        margin-bottom: 20px;
    }
}

@media (max-width: 478px) {
    .bottom {
        grid-template-columns: none;
        grid-template-rows: repeat(3, 1fr);
    }
}

</style>