<template>
    <div class="modal-wrapper" :class="{hide: !modals.autoClose}" @mousedown.stop="hideModal">
        <div class="modal ElevationDark__3" @click.stop @mousedown.stop :style="{
                top: position.top+'px',
                left: position.left+'px'
            }">
            <div class="title title__large">{{ tokens.autoCloseModal.takeProfitStopLoss }}</div>
            <!-- <div class="btns" @click.stop="" :class="{hide: values.orderActive}">
                <div class="btn"
                     :class="{active: mode === 'default'}"
                     @click="setMode('default')">
                    {{tokens.orderMenu.default}}
                </div>
            </div> -->
            <div class="row stretch">
                <div class="value-container">
                    <!--<input type="text" class="value" v-model.number="values.tp" inputmode="decimal"
                            @keypress="validateInput"
                            @keyup="updateTpInput"
                            @blur="blurTP"
                            ref="tp"/>-->
                    <BaseNumberInput
                        :title="tokens.autoCloseModal.takeProfit"
                        type="number"
                        placeholder="0.0"
                        v-model="values.tp"
                        :validateFunction="updateInput" />
                    <BaseButton class="minus" :buttonStyle="'outlined-primary'"
                        @click.native="values.tp = values.tp ? (Math.max(0, parseFloat(values.tp) - Math.pow(0.1, modalSymbolActive.precision))).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.bid.toFixed(modalSymbolActive.precision)">
                        <template #icon>
                            <i class="material-symbols-outlined">remove</i>
                        </template>
                    </BaseButton>
                    <BaseButton class="plus" :buttonStyle="'outlined-primary'"
                        @click.native="values.tp = values.tp ? (parseFloat(values.tp) + Math.pow(0.1, modalSymbolActive.precision)).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.bid.toFixed(modalSymbolActive.precision)">
                        <template #icon>
                            <i class="material-symbols-outlined">add</i>
                        </template>
                    </BaseButton>
                </div>
            </div>

            <div class="row stretch">
                <div class="value-container">
                    <!-- <input type="text" class="value" v-model.number="values.sl" inputmode="decimal"
                    @keypress="validateInput" @keyup="updateSlInput" @blur="blurSL" ref="sl" /> -->
                    <BaseNumberInput
                        :title="tokens.autoCloseModal.stopLoss"
                        type="number"
                        placeholder="0.0"
                        v-model="values.sl"
                        :validateFunction="updateInput" />
                    <BaseButton class="minus" :buttonStyle="'outlined-primary'"
                        @click.native="values.sl = values.sl ? (parseFloat(values.sl) - Math.pow(0.1, modalSymbolActive.precision)).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.ask.toFixed(modalSymbolActive.precision)">
                        <template #icon>
                            <i class="material-symbols-outlined">remove</i>
                        </template>
                    </BaseButton>
                    <BaseButton class="plus" :buttonStyle="'outlined-primary'"
                        @click.native="values.sl = (values.sl ? (Math.max(0, parseFloat(values.sl) + Math.pow(0.1, modalSymbolActive.precision))).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.ask.toFixed(modalSymbolActive.precision))">
                        <template #icon>
                            <i class="material-symbols-outlined">add</i>
                        </template>
                    </BaseButton>
                </div>
            </div>
            <div class="buttons">
                <BaseButton :buttonText="'Cancel'" :buttonStyle="'text-primary'" @click.native="hideModal" />
                <BaseButton
                    v-if="!values.orderActive"
                    :buttonText="'Apply'"
                    :buttonStyle="'filled-primary'"
                    @click.native="apply" />
                <BaseButton
                    v-else
                    :buttonText="tokens.autoCloseModal.updateOrder"
                    :buttonStyle="'filled-primary'"
                    @click.native="updateOrder" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_AUTOCLOSE} from '@/store/mutations.type'
import {currencySign, isMobile} from '@/common/helpers'
import {CFD_ORDER_MODIFY_REQUEST} from "@/store/actions.type"
import BaseNumberInput from "@/new-design/shared/ui/Inputs/BaseNumberInput";
import BaseButton from '@/new-design/shared/ui/buttons/BaseButton.vue';

export default {
    name: 'AutoCloseModal',
    components: {BaseNumberInput,BaseButton},
    data() {
        return {
            values: {
                left: 0,
                top: 0,
                symbolActive: null,
                orderActive: null,
                tp: 0,
                tpType: '=',
                sl: 0,
                slType: '=',
                item: 0
            },
            // mode: 'default',
        }
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                autoClose: false
            })
        },
        updateInput(e) {
            return e.replace(/,/g, "");
        },
        updateOrder() {
            const orderKeys = Object.keys(this.activeAccount.orders)
            const orderIndex = orderKeys.find(orderKey => this.activeAccount.orders[orderKey].id === this.values.orderActive)

            if (this.activeAccount.orders[orderIndex] === undefined) {
                this.$store.commit(MODAL, {
                    autoClose: false
                })

                return
            }

            let price = this.activeAccount.orders[orderIndex].price_open,
                type = this.activeAccount.orders[orderIndex].type,
                leverage = this.activeAccount.orders[orderIndex].easy_leverage || this.activeAccount.leverage,
                amount = this.activeAccount.orders[orderIndex].easy_amount,
                sl = 0,
                tp = 0

            switch (this.values.tpType) {
                case '%':
                    tp = Number(price) + (type % 2 ? -1 : 1) * price * this.values.tp / (100 * leverage)
                    break
                case '$':
                    tp = Number(price) + (type % 2 ? -1 : 1) * price * this.values.tp / (100 * leverage * amount)
                    break
                case '=':
                    tp = String(this.values.tp)
                    tp = tp.includes(',') ? tp.replace(/,/, '.') : tp
                    tp = parseFloat(tp)
                    break
            }

            switch (this.values.slType) {
                case '%':
                    sl = Number(price) + (type ? 1 : -1) * price * this.values.sl / (100 * this.leverage)
                    break
                case '$':
                    sl = Number(price) + (type ? 1 : -1) * price * this.values.sl / (this.leverage * this.amount)
                    break
                case '=':
                    sl = this.values.sl
                    break
            }

            if (this.values.tp == 0 || this.values.tp === '') {
                tp = 0
            }

            if (this.values.sl == 0 || this.values.sl === '') {
                sl = 0
            }

            this.$store.dispatch(CFD_ORDER_MODIFY_REQUEST, {
                id: this.values.orderActive,
                account_id: this.activeAccount.id,
                sl,
                tp
            })

            this.$store.commit(MODAL, {
                autoClose: false
            })
        },
        setMode() {
            // need to be refactored
            /* this.mode = mode
            this.$store.commit(SET_AUTOCLOSE_MODE, mode) */
        },
        apply(){
            this.$store.commit(MODAL_AUTOCLOSE, {event: 1})
            this.$store.commit(MODAL, {
                autoClose: false
            })
        },
        currencySign,
        isMobile
    },
    computed: {
        ...mapGetters(['modals', 'modalAutoClose', 'tokens', 'activeAccount', 'symbols', 'orders']),
        position() {
            let screen = {
                width: document.body.offsetWidth,
                height: document.body.offsetHeight,
            };
            let modal = {
                width: 360,
                height: 345
            };

            return {
                left: (screen.width - modal.width) / 2,
                top: ((screen.height - modal.height) / 2) - 44,
            };
        },
        modalSymbolActive() {
            if (this.values?.symbolActive === null) {
                let symbol = false;
                for (let order in this.orders) {
                    if(this.values?.orderActive == this.orders[order]?.id){
                        symbol = this.orders[order]?.symbol;
                        break;
                    }
                }

                if (symbol) {
                    this.$store.commit(MODAL_AUTOCLOSE, {symbolActive: this.symbols[symbol]})
                }
            }
            return this.modalAutoClose.symbolActive || {
                precision: 0,
                quote: {
                    bid: 0,
                    ask: 0
                }
            }
        }
    },
    watch: {
        modalAutoClose: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    if (this.values[n] !== undefined) {
                        this.values[n] = val[n]
                    }
                }
            }
        },
        values: {
            deep: true,
            handler(val) {
                this.$store.commit(MODAL_AUTOCLOSE, val)
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    position: absolute;
    z-index: 200;
}

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 200;
    top: 357px;
    right: 110px;
    width: 360px;
    min-width: 280px;
    border-radius:  8px;
    background: var(--System-Surface3);
}

.title {
    padding: 20px;
    color: var(--System-OnSurface);
}

.value-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    padding: 5px;
}

.plus i, .minus i {
    font-size: 18px;
    padding: 2.5px 0;
    margin: 0 -5px;
}

/* modifiers */

.stretch {
    position: relative;
    padding: 5px 15px;
}
.buttons {
    border-top: 1px solid var(--System-OutlineVariant);
    padding: 25px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

</style>
