<template>
    <aside v-show="(!isMobile && modals.fullscreen) || (isMobile && modals.hamburgerMenu)"
           :class="{mobile: isMobile}"
           @click="activeSelect = false">
        <ul>
            <div class="top">
                <div class="head" :class="{mobile: isMobile}">
                    <div
                        class="profile-settings-avatar"
                    >
                        <BaseMonogram v-tooltip="'Your profile and settings'" />
                    </div>
                    <div class="user-info label__medium">
                        <span class="name">{{ user.fullname }}</span>
                        <span class="email" v-if="!hideEmail">{{ user.email }}</span>
                    </div>
                </div>
                <div class="account-info">
                    <div>
                        <div class="account-balance label__large">
                            {{ currencySign(activeAccount.currency) }}{{ activeAccount.balance.toLocaleString(undefined, {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 2
                        }) }}
                        </div>
                        <div class="user-id body__extrasmall">#{{ user.id }}</div>
                    </div>
                    <BaseButton :buttonText="tokens.fullScreen.deposit" :buttonStyle="'text-tertiary'"
                                @click.native="changePage('deposit')"/>
                </div>
            </div>
            <div class="scroll-menu">
                <AsideMenuItem
                    v-for="menuItem in asideMenuItems"
                    :key="menuItem.name"
                    :text="tokens.fullScreen[menuItem.token]"
                    :materialIcon="menuItem.icon || ''"
                    :active="modalFullscreen.page===menuItem.name"
                    @click.native="changePage(menuItem.name)">
                        <template v-if="menuItem.name === 'withdrawal'" #icon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 18 17" fill="none"
                                class="svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 17V15H0L5.96046e-08 17H18Z"
                                    fill="#9A9DAB"/>
                                <path
                                    d="M7.67157 0.5L7.72461 2.49758H11.3309L4.5 9.74268L6 11.2427L12.7451 3.91179L12.7451 7.51803L14.7426 7.57107V0.5H7.67157Z"
                                    fill="#9A9DAB"/>
                            </svg>
                        </template>
                        <template v-else-if="menuItem.name === 'verification'" #icon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M13 15.45V16H5V15.45C5 14.7 5.36667 14.1042 6.1 13.6625C6.83333 13.2208 7.8 13 9 13C10.2 13 11.1667 13.2208 11.9 13.6625C12.6333 14.1042 13 14.7 13 15.45ZM10.4125 11.4125C10.0208 11.8042 9.55 12 9 12C8.45 12 7.97917 11.8042 7.5875 11.4125C7.19583 11.0208 7 10.55 7 10C7 9.45 7.19583 8.97917 7.5875 8.5875C7.97917 8.19583 8.45 8 9 8C9.55 8 10.0208 8.19583 10.4125 8.5875C10.8042 8.97917 11 9.45 11 10C11 10.55 10.8042 11.0208 10.4125 11.4125ZM2.5875 19.4125C2.97917 19.8042 3.45 20 4 20H20C20.55 20 21.0208 19.8042 21.4125 19.4125C21.8042 19.0208 22 18.55 22 18V9L20 11V18H4V6H11L13 4H4C3.45 4 2.97917 4.19583 2.5875 4.5875C2.19583 4.97917 2 5.45 2 6V18C2 18.55 2.19583 19.0208 2.5875 19.4125Z"
                                    fill="#9A9DAB"/>
                                <path d="M13 7.55L16.55 11.1L22.2 5.45L20.75 4L16.55 8.2L14.45 6.1L13 7.55Z"
                                    fill="#9A9DAB"/>
                            </svg>
                        </template>
                        <template v-else-if="menuItem.name === 'accounts'" #icon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M3.175 18.825C3.95833 19.6083 4.9 20 6 20H11.5C10.5 17.5 10.5 15 12 13.1552L4.15 11.25C4.3 10.8667 4.5375 10.5625 4.8625 10.3375C5.1875 10.1125 5.56667 10 6 10H18C18.5258 10 19.0516 10.0614 19.5559 10.1843C20.4902 10.412 21.3505 10.8505 22 11.5V8C22 6.9 21.6083 5.95833 20.825 5.175C20.0417 4.39167 19.1 4 18 4H6C4.9 4 3.95833 4.39167 3.175 5.175C2.39167 5.95833 2 6.9 2 8V16C2 17.1 2.39167 18.0417 3.175 18.825ZM18 8H6C5.63333 8 5.28333 8.04167 4.95 8.125C4.61667 8.20833 4.3 8.34167 4 8.525V8C4 7.45 4.19583 6.97917 4.5875 6.5875C4.97917 6.19583 5.45 6 6 6H18C18.55 6 19.0208 6.19583 19.4125 6.5875C19.8042 6.97917 20 7.45 20 8V8.525C19.7 8.34167 19.3833 8.20833 19.05 8.125C18.7167 8.04167 18.3667 8 18 8Z"
                                    fill="#9A9DAB"/>
                                <path
                                    d="M15.0179 19.32L13 17.04L13.75 16.2L14.4286 16.96C14.4405 16.4533 14.5327 15.96 14.7054 15.48C14.878 15 15.1369 14.5667 15.4821 14.18C15.8274 13.7933 16.2202 13.5 16.6607 13.3C17.1012 13.1 17.5476 13 18 13C18.3452 13 18.6845 13.0533 19.0179 13.16C19.3512 13.2667 19.6667 13.4333 19.9643 13.66L19.1786 14.54C18.9881 14.4333 18.7917 14.35 18.5893 14.29C18.3869 14.23 18.1845 14.2 17.9821 14.2C17.6726 14.2 17.3631 14.27 17.0536 14.41C16.744 14.55 16.4702 14.7533 16.2321 15.02C15.9821 15.3 15.7976 15.6167 15.6786 15.97C15.5595 16.3233 15.506 16.6867 15.5179 17.06L16.2857 16.2L17.0357 17.04L15.0179 19.32Z"
                                    fill="#9A9DAB"/>
                                <path
                                    d="M16.9821 20.84C16.6488 20.7333 16.3333 20.5667 16.0357 20.34L16.8214 19.46C17.0119 19.5667 17.2083 19.65 17.4107 19.71C17.6131 19.77 17.8155 19.8 18.0179 19.8C18.3274 19.8 18.6369 19.73 18.9464 19.59C19.256 19.45 19.5298 19.2467 19.7679 18.98C20.006 18.7133 20.1875 18.3933 20.3125 18.02C20.4375 17.6467 20.5 17.28 20.5 16.92L19.7143 17.8L18.9643 16.96L20.9821 14.68L23 16.96L22.25 17.8L21.5536 17.04C21.5536 17.5467 21.4702 18.0433 21.3036 18.53C21.1369 19.0167 20.881 19.4533 20.5357 19.84C20.1905 20.2267 19.7946 20.5167 19.3482 20.71C18.9018 20.9033 18.4524 21 18 21C17.6548 21 17.3155 20.9467 16.9821 20.84Z"
                                    fill="#9A9DAB"/>
                            </svg>
                        </template>
                        <template v-else-if="menuItem.name === 'referrals'" #icon>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Icon">
                                    <g id="Vector">
                                        <path d="M10 12C8.9 12 7.95833 11.6083 7.175 10.825C6.39167 10.0417 6 9.1 6 8C6 6.9 6.39167 5.95833 7.175 5.175C7.95833 4.39167 8.9 4 10 4C11.1 4 12.0417 4.39167 12.825 5.175C13.6083 5.95833 14 6.9 14 8C14 9.1 13.6083 10.0417 12.825 10.825C12.0417 11.6083 11.1 12 10 12ZM2 20V17.2C2 16.65 2.14167 16.1333 2.425 15.65C2.70833 15.1667 3.1 14.8 3.6 14.55C4.45 14.1167 5.40833 13.75 6.475 13.45C7.54167 13.15 8.71667 13 10 13H10.35C10.45 13 10.55 13.0167 10.65 13.05C10.5167 13.35 10.4042 13.6625 10.3125 13.9875C10.2208 14.3125 10.15 14.65 10.1 15H10C8.81667 15 7.75417 15.15 6.8125 15.45C5.87083 15.75 5.1 16.05 4.5 16.35C4.35 16.4333 4.22917 16.55 4.1375 16.7C4.04583 16.85 4 17.0167 4 17.2V18H10.3C10.4 18.35 10.5333 18.6958 10.7 19.0375C10.8667 19.3792 11.05 19.7 11.25 20H2ZM10 10C10.55 10 11.0208 9.80417 11.4125 9.4125C11.8042 9.02083 12 8.55 12 8C12 7.45 11.8042 6.97917 11.4125 6.5875C11.0208 6.19583 10.55 6 10 6C9.45 6 8.97917 6.19583 8.5875 6.5875C8.19583 6.97917 8 7.45 8 8C8 8.55 8.19583 9.02083 8.5875 9.4125C8.97917 9.80417 9.45 10 10 10Z" fill="#9A9DAB"/>
                                        <path d="M17.7511 20.4478C18.1198 20.8159 18.5673 21 19.0936 21C19.6201 21 20.0677 20.8159 20.4364 20.4478C20.8051 20.0794 20.9894 19.6325 20.9894 19.1069C20.9894 18.5814 20.8051 18.1344 20.4364 17.7661C20.0677 17.3979 19.6201 17.2139 19.0936 17.2139C18.895 17.2139 18.7117 17.2416 18.5438 17.2972C18.3757 17.3525 18.2139 17.4322 18.0582 17.5362L15.7708 16.1759C15.7847 16.1092 15.7916 16.0505 15.7916 15.9999C15.7916 15.9494 15.7847 15.8908 15.7708 15.8241L18.0582 14.4638C18.2139 14.5678 18.3774 14.6475 18.549 14.7028C18.7205 14.7584 18.9056 14.7861 19.1042 14.7861C19.6305 14.7861 20.0781 14.6021 20.447 14.2339C20.8157 13.8656 21 13.4186 21 12.8931C21 12.3675 20.8157 11.9206 20.447 11.5522C20.0781 11.1841 19.6305 11 19.1042 11C18.5501 11 18.0851 11.2049 17.7094 11.6147C17.3337 12.0245 17.1736 12.5082 17.2292 13.0659L14.9312 14.4262C14.7758 14.3222 14.614 14.2408 14.4459 14.182C14.2778 14.123 14.0944 14.0935 13.8958 14.0935C13.3695 14.0935 12.922 14.2776 12.5533 14.6458C12.1844 15.0139 12 15.4608 12 15.9863C12 16.5121 12.1844 16.959 12.5533 17.3272C12.922 17.6953 13.3695 17.8794 13.8958 17.8794C14.0944 17.8794 14.2778 17.8517 14.4459 17.7964C14.614 17.7409 14.7758 17.6611 14.9312 17.557L17.2187 18.9341C17.2118 18.9619 17.2066 18.9879 17.2032 19.0123C17.1998 19.0366 17.1981 19.0626 17.1981 19.0902C17.1981 19.6269 17.3824 20.0794 17.7511 20.4478Z" fill="#9A9DAB"/>
                                    </g>
                                </g>
                            </svg>
                        </template>
                </AsideMenuItem>
            </div>
        </ul>
        <AsideMenuItem
            :materialIcon="'logout'"
            :text="tokens.fullScreen.logOut"
            @click.native="logout"/>
    </aside>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type"
import {isMobile} from '@/common/helpers'
import {LOGOUT} from "@/store/actions.type"
import {
    PERSONAL_BTNS,
    REST_ENDPOINT,
    BRAND,
    THEME_3_DESIGN,
    REMOVE_ECONOMIC_CALENDAR,
    HIDE_TRADING_BOT,
    HIDE_EMAIL,
    WHITE_THEME_3_THEME,
    CUSTOM_ASIDE_MENU_ITEMS_ORDER,
    REFERRALS
} from "@/common/config"
import BaseButton from '@/new-design/shared/ui/buttons/BaseButton.vue'
import AsideMenuItem from '@/new-design/widgets/Hamburger/ui/AsideMenuItem.vue'
import BaseMonogram from '@/new-design/shared/ui/Icons/BaseMonogram.vue'
import {currencySign} from "@/common/helpers"

export default {
    name: "SideMenu",
    components: {BaseButton, AsideMenuItem, BaseMonogram},
    data() {
        return {
            isMobile: isMobile(),
            apiBase: REST_ENDPOINT,
            personalBtns: PERSONAL_BTNS,
            activeSelect: false,
            brand: BRAND,
            theme_3_Design: THEME_3_DESIGN,
            removeEconomicCalendar: REMOVE_ECONOMIC_CALENDAR,
            hideTradingBot: HIDE_TRADING_BOT,
            hideEmail: HIDE_EMAIL,
            whiteTheme_3Theme: WHITE_THEME_3_THEME,
            customAsideMenuItemsOrder: CUSTOM_ASIDE_MENU_ITEMS_ORDER,
            referrals: REFERRALS
        }
    },
    methods: {
        ...mapMutations(["setLightTheme"]),
        currencySign,
        closeHamburgerMenu() {
            setTimeout(() => {
                this.$store.commit(MODAL, {
                    hamburgerMenu: false
                })
            }, 0);
        },
        changePage(page) {
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.closeHamburgerMenu()

            this.$store.commit(MODAL_FULLSCREEN, {
                page,
                fromPage: ''
            })
        },
        logout() {
            this.closeHamburgerMenu()
            this.$store.dispatch(LOGOUT)
        }
    },
    computed: {
        ...mapGetters(['tokens', 'user', 'autoTraderConfig', 'modals', 'modalMenu', 'modalFullscreen', 'user', 'accounts', 'activeAccount', 'savingExist', 'staticBase']),
        asideMenuItems() {
            let items = [
                {
                    name: 'dashboard',
                    token: 'dashboard',
                    icon: 'dashboard',
                },
                {
                    name: 'personal',
                    token: 'personalInfo',
                    icon: 'person',
                },
                {
                    name: 'withdrawal',
                    token: 'withdrawal',
                },
                {
                    name: 'verification',
                    token: 'verification',
                },
                {
                    name: 'accounts',
                    token: 'accounts',
                },
                {
                    name: 'support',
                    token: 'liveChat',
                    icon: 'chat',
                },
                {
                    name: 'savings',
                    token: 'savings',
                    icon: 'savings',
                },
                {
                    name: 'referrals',
                    token: 'referrals',
                },
                {
                    name: 'settings',
                    token: 'settings',
                    icon: 'settings',
                },
            ];

            if(this.customAsideMenuItemsOrder){
                let withdrawalIndex = items.findIndex((i) => i.name === 'withdrawal');
                if(withdrawalIndex > -1){
                    let item = items.splice(withdrawalIndex, 1);
                    items.unshift(item[0])
                }
            }

            if(!this.referrals){
                let referralIndex = items.findIndex((i) => i.name === 'referrals');
                if(referralIndex > -1){
                    items.splice(referralIndex, 1);
                }
            }

            return items;
        }
    }
}
</script>

<style scoped>

aside {
    width: 320px;
    max-width: 320px;
    background: var(--System-Surface);
    border-right: 1px solid var(--System-OutlineVariant);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3), 0px 4px 4px rgba(0, 0, 0, 0.15);
    /* padding: 0 15px; */
}

@media (max-width: 1023px) {
    aside {
        width: 280px;
        max-width: 280px;
    }

    aside.mobile {
        height: auto;
        max-height: calc(100% - 65px);
        top: 55px;
        left: 8px;
        border-radius: 8px;
        padding: 0;
        border: none;
        background: var(--System-Surface2);
    }

    aside.mobile .scroll-menu {
        height: auto;
    }
}

.top {
    padding: 24px 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3px;
}

.name {
    color: var(--System-OnSurface);
}

.email {
    font-weight: 400;
}

.account-info {
    border-radius: 4px;
    border: 1px solid var(--System-Surface5);
    background: var(--Statelayers-secondarycontainer-opacity16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
}

.account-balance {
    color: var(--System-OnSurface);
    margin-bottom: 4px;
}

.scroll-menu {
    height: 500px;
    overflow: auto;
}

@media (max-height: 777px) {
    .scroll-menu {
        height: calc(100dvh - 290px);
        overflow: auto;
    }
}

.personal-btns.fool {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, max-content);
    width: 100%;
    margin-top: 15px;
}

.personal-btns.fool a {
    width: 100%;
}

.personal-btns {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-items: center;
    gap: 10px;
}

.personal-btns img {
    height: 25px;
}

.personal-btns a {
    color: var(--white);
    text-decoration: none;
    display: inline-grid;
    place-items: center;
    grid-template-columns: repeat(2, max-content);
    gap: 5px;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px;
    transition: transform .3s ease-in-out;
}

.personal-btns a:hover {
    transform: scale(1.05);
}

.personal-btns a:active {
    transform: scale(.95);
}

.personal-btns a:nth-child(1) {
    border: 1px solid #ef443b;
}

.personal-btns a:nth-child(2) {
    border: 1px solid #178de5;
}

.personal-btns a:nth-child(3) {
    border: 1px solid #eb521a;
}

@media (min-width: 1000px) {
    .personal-btns {
        gap: .5vw;
    }

    .personal-btns img {
        height: 1.2vw;
    }

    .personal-btns a:nth-child(1) {
        border: .1vw solid #ef443b;
    }

    .personal-btns a:nth-child(2) {
        border: .1vw solid #178de5;
    }

    .personal-btns a:nth-child(3) {
        border: .1vw solid #eb521a;
    }

    .personal-btns a {
        gap: .2vw;
        font-size: .7vw;
        padding: .3vw;
        border-radius: .2vw;
    }
}
</style>
