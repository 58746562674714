<template>
    <div>
        <AccountListItem 
            v-for="account in accounts" 
            :key="account.id" 
            :account="account"
            @click.native="changeAccount(account.id)" />
    </div>
</template>

<script>
import AccountListItem from "@/new-design/entities/BalanceModal/ui/AccountListItem.vue";
import { ACCOUNT_ACTIVE } from "@/store/mutations.type";
import { mapGetters } from "vuex";

export default {
    components: { AccountListItem },
    methods: {
        changeAccount(id) {
            this.$store.commit(ACCOUNT_ACTIVE, id)
        },
    },
    computed: {
        ...mapGetters([ 'activeAccount','accounts']),
    }
}
</script>