<template>
    <div class="modal-wrapper" :class="{hide: !modals.forgotPassword, newDesign: newDesign, 'theme_3-modal-design': theme_3_Design}">
        <div class="modal">
            <div class="modal-box">
                <div class="title" >
                    <span>{{ tokens.forgotPasswordModal.forgotPassword.toUpperCase() }}</span>
                    <i class="material-icons close pointer" v-on:click="hideModal">close</i>
                </div>
                <div class="row">
                    <div class="col">
                        <label>{{ tokens.forgotPasswordModal.submitEmail }}</label>
                        <div class="form-row input">
                            <input class="form-control" v-model="email" :placeholder="tokens.loginModal.enterEmail"/>
                        </div>
                        <div class="button green-button" @click="restorePassword">
                            {{ tokens.forgotPasswordModal.submit.toUpperCase() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {THEME_3_DESIGN, NEW_DESIGN} from "@/common/config";

import {mapGetters} from "vuex"
import {MODAL} from "@/store/mutations.type"
import {PASSWORD_RESTORE} from "@/store/actions.type"

export default {
    name: "ForgotPasswordModal",
    data() {
        return {
            email: '',
            newDesign: NEW_DESIGN,
            theme_3_Design: THEME_3_DESIGN
        }
    },
    methods: {
        hideModal() {
            this.email = ''
            this.$store.commit(MODAL, {
                forgotPassword: false
            })
        },
        restorePassword() {
            this.$store.dispatch(PASSWORD_RESTORE, {
                email: this.email.trim()
            })
            this.$store.commit(MODAL, {
                forgotPassword: false
            })
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals'])
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newDesign .modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: linear-gradient(149.09deg, rgba(155, 162, 187, 0.8) 2.06%, rgba(57, 57, 57, 0.8) 77.03%);
    border: 1px solid #CECECE;
    border-radius: 10px;
    padding: 30px 40px;
    z-index: 1100;
}

.newDesign .title {
    color: var(--white);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newDesign .col {
    margin-right: 0 !important;
}

.newDesign .title i {
    font-size: 19px;
}

.newDesign .col {
    flex: 1 1 50%;
    justify-content: flex-start;
}


.newDesign label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.newDesign input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.newDesign .form-row:last-child {
    margin-bottom: 0;
}

.newDesign .row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.newDesign ::placeholder {
    color: transparent;
}

.newDesign .button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    cursor: pointer;
    background: #247243;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}

/*Forgot SharkSoft*/

.modal {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.theme_3-modal-design.modal-wrapper {
    justify-content: flex-end;
}

.theme_3-modal-design .modal {
    width: 660px;
    height: 100%;
    justify-content: center;
    padding: 20px 140px;
    position: relative;
    background: transparent;
    border: none;
}

.theme_3-modal-design .search-country,
.theme_3-modal-design .form-control {
    padding: 0 10px;
}

.theme_3-modal-design .modal::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1E1F25;
    opacity: 0.75;
    backdrop-filter: blur(32px);
    z-index: -1;
}

.theme_3-logo {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0 0 35px;
}

.theme_3-modal-design .show-hide-password {
    background: transparent;
}

.theme_3-modal-design .search-country,
.theme_3-modal-design .form-control{
    background-color: transparent;
}

.theme_3-modal-design .col {
    margin: 0;
}

.theme_3-modal-design .form-icon.form-row,
.theme_3-modal-design .form-row {
    position: relative;
    margin-bottom: 20px;
}

.theme_3-modal-design .form-icon .select {
    font-size: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 2;
}

.theme_3-modal-design .show-hide-password i {
    right: 20px;
}

.theme_3-modal-design input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
}

.theme_3-modal-design .button {
    margin: 55px 0 5px 0;
    padding: 16px 0;
    background: linear-gradient(265.56deg, #30E0A1 -0.27%, #1A82FF -0.26%, #1E02C7 98.59%);
    border-radius: 100px;
}

.theme_3-modal-design .currency {
    margin-left: 35px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
}

.theme_3-modal-design .form-icon img {
    width: 32px;
    height: 25px;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    z-index: 2;
}

.theme_3-modal-design .icon-box img {
    width: auto;
    height: auto;
    position: relative;
    transform: none;
    left: 0;
    top: 0;
}

.theme_3-modal-design .form-image {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 0 48px;
    position: relative;
}

.theme_3-modal-design option {
    color: #fff;
}

.tabs {
    margin-bottom: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.tabs a {
    font-weight: 600;
    font-size: 24px;
    color: var(--white);
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.tabs a.is-active {
    color: #1A82FF;
    font-weight: 600;
}

.tabs a:first-child{
    padding-right: 25px;
}

.tabs a:last-child {
    padding-left: 25px;
}

.tabs a:first-child::after {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: var(--white);
}

.theme_3-modal-design .country-list {
    top: 50px;
}

.theme_3-modal-design .country-list,
.theme_3-modal-design .form-row.input,
.theme_3-modal-design .form-image {
    background: #5D6588;
}

.theme_3-modal-design .form-row.input {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 30px;
    place-items: center;
    height: 60px;
    border: 1px solid #34384C;
    border-radius: 100px;
    padding: 0 21px;
    font-weight: 400;
    font-size: 16px;
}

.theme_3-modal-design .icon-box {
    position: relative;
    padding-left: 10px;
}

.theme_3-modal-design .icon-box::after{
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: var(--white);
}

.icon-box img {
    cursor: pointer;
}

.mobile .theme_3-modal-design .modal {
    padding: 20px;
}
</style>
