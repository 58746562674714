<template>
    <div class="widget">
        <BaseWidgetTitle :title="tokens.widgetMarketWatch.marketWatch" @close="hideModal('market')"/>
        <SymbolsGroup/>
        <AssetsInfoModal @close="hideModal('market')"/>
    </div>
</template>

<script>
import BaseWidgetTitle from "@/new-design/shared/ui/titles/BaseWidgetTitle.vue"
import SymbolsGroup from "@/new-design/widgets/SymbolsGroup.vue"
import {mapGetters} from "vuex"
import {MODAL, MODAL_MENU} from "@/store/mutations.type"
import {isMobile} from '@/common/helpers'
import AssetsInfoModal from "@/new-design/features/Modals/AssetsInfo/ui/AssetsInfoModal.vue"

export default {
    name: 'MarketWatch',
    components: {SymbolsGroup, BaseWidgetTitle, AssetsInfoModal},
    computed: {
        ...mapGetters(['tokens', 'modalMenu'])
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
    }
}
</script>

<style scoped>
.widget {
    position: relative;
}
</style>
