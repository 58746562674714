<template>
    <div class="crypto-tab">
        <BasePanel>
            <template #content>
                <template v-if="!successful">
                    <h5 class="title title__medium">{{ 'Choose a Crypto' }}</h5>
                    <div class="cryptocurrencies">
                        <BaseSmallChip
                            v-for="crypto in cryptoWithdrawalMethods"
                            :key="crypto.name"
                            :text="crypto.name"
                            :class="{active: activeTab.name === crypto.name}"
                            @click.native="changeTab(crypto)"
                        >
                            <template #icon>
                                <img width="18" height="18" :src="staticBase+`currencies/${crypto.symbol}.png`" alt="">
                            </template>
                        </BaseSmallChip>
                    </div>
                    <div class="amount-to">
                        <h3 class="title title__medium">{{tokens.newDesign.amountToWithdrawal}}</h3>
                        <div class="inputs-list">
                            <BaseInput v-model="amount" :type="'number'" :title="tokens.newDesign.amount" :placeholder="'0.00'">
                                <template #icon>
                                    <p class="body__large">$</p>
                                </template>
                                <template #info>
                                    <p class="body__large">USD</p>
                                </template>
                            </BaseInput>
                        </div>
                    </div>
                    <div class="card-details">
                        <h3 class="title title__medium">{{tokens.newDesign.cryptoWalletDetails}}</h3>
                        <div class="inputs-list">
                            <BaseInput 
                                v-model="wallet" 
                                :title="activeTab.symbol + ' wallet address'"
                            />
                        </div>
                    </div>
                    <div class="confirm">
                        <SendWithdrawal
                            :disabled="!amount || !wallet"
                            :info="getInfo"
                            @send="showSuccessful()"
                        />
                    </div>
                </template>
                <template v-else>
                    <SuccessfulTab
                        :details="getDetails"
                        @closeSuccessfullTab="closeSuccessfullTab"
                        @showAccounts="showAccounts"
                    />
                </template>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue"
import SuccessfulTab from "@/new-design/pages/withdrawal/tabs/SuccessfulTab.vue"
import SendWithdrawal from "@/new-design/features/Withdrawal/ui/SendWithdrawal.vue"
import BaseSmallChip from "@/new-design/shared/ui/chips/BaseSmallChip.vue"
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type"
import {currencySign} from "@/common/helpers"
import {mapGetters} from "vuex"
import {STATIC_BASE} from "@/common/config";
import {withdrawalMethods} from "@/common/helpers"

export default {
    name: "CryptoTab",
    components: {SendWithdrawal, SuccessfulTab, BaseInput, BasePanel, BaseSmallChip},
    props: {
        data: {
            type: String,
            default: ""
        },
    },
    mounted() {
        this.init()
    },
    data() {
        return {
            amount: null,
            wallet: "",
            successful: false,
            selectedActiveTab: '',
            staticBase: STATIC_BASE,
        }
    },
    methods: {
        init() {
            this.amount = null
            this.wallet = ''
            this.successful = false
        },
        showSuccessful() {
            this.successful = true
        },
        showAccounts() {
            this.init()

            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL, {
                hamburgerMenu: false
            })

            this.$store.commit(MODAL_FULLSCREEN, {
                page: 'accounts'
            })
        },
        closeSuccessfullTab() {
            this.init()
            this.successful = false
        },
        changeTab(tab) {
            this.activeTab = tab
        },
        currencySign
    },
    computed: {
        ...mapGetters(['activeAccount','tokens','withdrawalList']),
        activeTab: {
            get() {
                return this.selectedActiveTab || (this.cryptoWithdrawalMethods.length ? this.cryptoWithdrawalMethods[0] : {});
            },
            set(value) {
                this.selectedActiveTab = value;
            }
        },
        cryptoWithdrawalMethods(){
            let {cryptoMethods} = withdrawalMethods();
            return cryptoMethods.filter(i => this.withdrawalList.find(e => e.name === i.name.toLowerCase()))
        },
        getInfo() {
            return {
                method: this.activeTab.symbol,
                amount: this.amount,
                wallet: this.wallet,
                activeAccount: this.activeAccount
            }
        },
        getDetails() {
            return [
                {
                    title: this.tokens.newDesign.amountWithdrawal,
                    value: currencySign(this.activeAccount.currency) + ' ' + this.amount,
                },
                {
                    title: this.tokens.newDesign.withdrawalTo,
                    value: this.wallet
                },
                {
                    title: this.tokens.registerModal.currency,
                    value: this.activeAccount.currency
                },
                {
                    title: this.tokens.newDesign.time,
                    value: this.formattedWithdrawalTime
                },
                {
                    title: this.tokens.newDesign.transactionStatus,
                    value: 'Processing'
                },
            ]
        },
        formattedWithdrawalTime() {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }
            return new Date().toLocaleString('en-GB', options).replace(',', '')
        }
    }
}
</script>


<style scoped>
.title {
    color: var(--System-OnSurface);
    margin-bottom: 16px;
}

.cryptocurrencies {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 0 16px;
    white-space: nowrap;
    flex-wrap: wrap;
}

.amount-to,
.card-details {
    margin-bottom: 16px;
}

.cards-list {
    display: flex;
    align-items: center;
    gap: 4px;
}

.inputs-list {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.confirm {
    margin-top: 24px;
    width: 100%;
    text-align: right;
}
</style>
