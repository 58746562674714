import { render, staticRenderFns } from "./TwoFactorAuthenticationModal.vue?vue&type=template&id=4c2ed5bc&scoped=true"
import script from "./TwoFactorAuthenticationModal.vue?vue&type=script&lang=js"
export * from "./TwoFactorAuthenticationModal.vue?vue&type=script&lang=js"
import style0 from "./TwoFactorAuthenticationModal.vue?vue&type=style&index=0&id=4c2ed5bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c2ed5bc",
  null
  
)

export default component.exports