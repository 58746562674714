<template>
    <table class="orders-table">
        <thead>
        <tr>
            <th>
                {{tokens.ordersPane.symbol}}
            </th>
            <th class="no-mobile" @click="sortBy('id')">
                {{tokens.ordersPane.id}}
            </th>
            <th @click="sortBy('type')">
                {{tokens.ordersPane.type}}
            </th>
            <th @click="sortBy('volume')">
                {{tokens.ordersPane.volume}}
            </th>
            <th class="no-mobile" @click="sortBy('price_open')">
                {{tokens.ordersPane.openPrice}}
            </th>
            <th class="no-mobile" @click="sortBy('expiration')">
                {{tokens.ordersPane.timedOrder}}
            </th>
            <th class="no-mobile" @click="sortBy('time_open')">
                {{tokens.ordersPane.openTime}}
            </th>
            <th class="no-mobile" @click="sortBy('sl')">
                {{tokens.ordersPane.sl}}
            </th>
            <th class="no-mobile" @click="sortBy('tp')">
                {{tokens.ordersPane.tp}}
            </th>
            <th @click="sortBy('price_open')">
                {{tokens.ordersPane.price}}
            </th>
            <th class="no-mobile" @click="sortBy('commission')">
                {{tokens.ordersPane.commission}}
            </th>
            <th v-if="!hideSwapColumn" class="no-mobile" @click="sortBy('swap')">
                {{tokens.ordersPane.swap}}
            </th>
            <th>
                {{tokens.ordersPane.pnl}}
            </th>
            <th>
                {{tokens.ordersPane.actions}}
            </th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(order, i) in orders"
            :key="i"
            v-on:click="setActiveSymbol(order.symbol)"
        >
            <td>
                <span class="symbol" :class="'symbol-'+order.symbol?.toUpperCase()" />
                {{symbols[order.symbol] ? symbols[order.symbol].alias : ''}}
            </td>
            <td class="no-mobile">
                {{order.id}}
            </td>
            <td :class="order.type % 2 ? 'red' : 'green'">
                {{orderTypeString(order.type)}}
            </td>
            <td>
                {{order.volume.toLocaleString()}}
            </td>
            <td  class="no-mobile">
                {{symbols[order.symbol] ? order.price_open.toLocaleString(undefined, { minimumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3, maximumFractionDigits:  symbols[order.symbol] ? symbols[order.symbol].precision : 3 }) : 0}}
            </td>
            <td class="no-mobile">
                {{order.expiration ? new Date(order.expiration * 1000).toLocaleString() : '-'}}
            </td>
            <td class="no-mobile">
                {{new Date(order.time_open * 1000).toLocaleString()}}
            </td>
            <td class="no-mobile">
                {{order.sl && symbols[order.symbol] ? order.sl.toLocaleString(undefined, { minimumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3, maximumFractionDigits:  symbols[order.symbol] ? symbols[order.symbol].precision : 3 }) : '-'}}
            </td>
            <td class="no-mobile">
                {{order.tp && symbols[order.symbol] ? order.tp.toLocaleString(undefined, { minimumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3, maximumFractionDigits:  symbols[order.symbol] ? symbols[order.symbol].precision : 3 }) : '-'}}
            </td>
            <td :class="!symbols[order.symbol] ? '' : ([0, 3, 5].indexOf(order.type) === -1 ? symbols[order.symbol].quote.dirAsk : symbols[order.symbol].quote.dirBid)">
                {{symbols[order.symbol] ? (symbols[order.symbol].quote[[0, 3, 5].indexOf(order.type) === -1 ? 'ask' : 'bid'] || 0).toLocaleString(undefined, { minimumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3, maximumFractionDigits:  symbols[order.symbol] ? symbols[order.symbol].precision : 3 }) : ''}}
            </td>
            <td class="no-mobile">
                {{ currencySign(activeAccount.currency) + order.commission.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
            </td>
            <td v-if="!hideSwapColumn" class="no-mobile">
                {{ currencySign(activeAccount.currency) + order.swap.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
            </td>
            <td :class="getOrderStyle(order)" >
                {{getOrderPnl(order)}}
            </td>
            <td>
                <span @click.stop="showTimePickerModal($event, order.id, i, order)">{{ tokens.ordersPane.closeInTime }}</span>
                <span @click.stop="showAutoCloseModal($event, order.id, i)">{{ tokens.ordersPane.edit}}</span>
                <span @click.stop="showCloseOrderConfirmation(order.id, i)">{{ tokens.ordersPane.close}}</span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {mapGetters} from 'vuex'
import {orderTypeString, timeToString, currencySign} from "@/common/helpers";
import {
    MODAL,
    MODAL_CLOSE_POSITION,
    MODAL_AUTOCLOSE,
    SYMBOL_ACTIVE_UPDATE,
    NOTIFICATION_ERROR, SORT_ORDERS, MODAL_TIME_PICKER
} from '@/store/mutations.type'
import {DISABLE_ORDER_SORT,HIDE_SWAP_COLUMN} from "@/common/config"

export default {
    name: "OrdersTable",
    data() {
        return {
            disableOrderSort: DISABLE_ORDER_SORT,
            hideSwapColumn: HIDE_SWAP_COLUMN
        }
    },
    computed: {
        ...mapGetters(['tokens', 'orders', 'symbols', 'activeAccount', 'user', 'chartActive','staticBase'])
    },
    methods: {
        orderTypeString,
        timeToString,
        currencySign,
        sortBy(sortBy) {
            if (this.disableOrderSort) {
                return
            }

            this.$store.commit(SORT_ORDERS, {
                sort: sortBy,
                account: this.activeAccount.id
            })
        },
        getOrderStyle(order) {
            const symbol = this.symbols[order.symbol]
            if (!symbol) {
                return ''
            }
            const diff = order.type ? order.price_open - symbol.quote.ask : symbol.quote.bid - order.price_open
            const value = order.point * diff * Math.pow(10, symbol.precision) + order.swap + order.commission
            return value >= 0 ? 'green' : 'red'
        },
        getOrderPnl(order) {
            const symbol = this.symbols[order.symbol]
            if (order.type >= 2) {
                return '0'
            }
            if (!symbol) {
                return '-'
            }
            const diff = order.type ? order.price_open - symbol.quote.ask : symbol.quote.bid - order.price_open
            const value = (order.point * diff * Math.pow(10, symbol.precision) + order.swap + order.commission)
            const formattedValue = value.toLocaleString(undefined, {
                minimumFractionDigits: this.activeAccount.precision,
                maximumFractionDigits: this.activeAccount.precision,
            })
            return `${currencySign(this.activeAccount.currency)}${formattedValue}`
        },
        setActiveSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        showCloseOrderConfirmation(id, index) {
            if (!this.activeAccount.enable) {
                this.$store.commit(NOTIFICATION_ERROR, 'TRADING_DISABLED')
                return
            }

            this.$store.commit(MODAL_CLOSE_POSITION, {
                activeOrderId: id,
                index: index
            })
            this.$store.commit(MODAL, {
                closePosition: true
            })
        },
        showTimePickerModal(e, id, item, order) {
            let rect = e.target.getBoundingClientRect()

            this.$store.commit(MODAL_TIME_PICKER, {
                left: rect.left + 100,
                top: rect.top - 50,
                symbolActive: null,
                orderActive: id,
                item: parseInt(item),
                expiration: order.expiration
            })

            this.$store.commit(MODAL, {
                timePicker: true
            })
        },
        showAutoCloseModal(e, id,item) {
            let rect = e.target.getBoundingClientRect()

            this.$store.commit(MODAL_AUTOCLOSE, {
                left: rect.left,
                top: rect.top - 150,
                symbolActive: null,
                orderActive: id,
                tp: this.activeAccount.orders[item].tp || '',
                tpType: '=',
                sl: this.activeAccount.orders[item].sl || '',
                slType: '=',
                item: parseInt(item)
            })

            this.$store.commit(MODAL, {
                autoClose: true
            })
        },
    }
}
</script>

<style scoped>
table {
    width: 100%;
    position: relative;
}

tr:hover {
    cursor: pointer;
}

tr:nth-child(even) td {
    background-color: rgba(255,255,255,0.02);
    /*border-top: 1px solid var(--border-table);*/
    /*border-bottom: 1px solid var(--border-table);*/
}

td, th {
    white-space: nowrap;
    text-align: right;
}

th {
    padding: 8px 0;
    position: sticky;
    top: 0;
    background-color: var(--background-dark);
}

td {
    text-align: right;
    color: var(--white-to-black);
    padding: 3px 0;
}

td:first-child, th:first-child {
    text-align: left;
    padding-left: 10px;
}

td:last-child, th:last-child {
    padding-right: 10px;
}

td:last-child span {
    cursor: pointer;
}

td:last-child span {
    margin-right: 15px;
}

td:last-child span:last-child {
    margin-right: 0;
}

td:first-child img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
}

td .symbol {
    zoom: 0.21875;
    margin-right: 23px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px;
}

@-moz-document url-prefix() {
    td .symbol {
        -moz-transform: scale(0.21875);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: -16px;
        top: -22px;
    }

    td:first-child {
        position: relative;
        padding-left: 28px !important;;
    }
}
</style>
