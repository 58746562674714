<template>
    <div class="tab">
        <component :is="component" :data="data"/>
    </div>
</template>

<script>
export default {
    name: "BaseTabViewerItem",
    props: {
        component: {
            type: Object,
            default: () => {}
        },
        data: {
            type: String,
            default: ""
        },
    }
}
</script>