export default {
    data() {
        return {
            state: false
        }
    },
    methods: {
        toggler() {
            document.addEventListener('click', this.handleClickOutside)
            this.state = !this.state
            this.$emit('changeState', this.state)

            if (!this.state) {
                document.removeEventListener('click', this.handleClickOutside)
            }
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.state = false
                this.$emit('changeState', this.state)
                document.removeEventListener('click', this.handleClickOutside)
            }
        }
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }
}
