<template>
    <div class="modal-wrapper"
        :class="{ hide: loggedIn || modals.register || modals.newPassword || modals.forgotPassword }">
        <BaseLogo class="theme_3-logo" :src="logoComputed" :height="logo.height">
            <template v-if="!logoComputed" #logo-placeholder>
                <span class="brand">{{ tokens.header.cfd }}</span>
                {{ tokens.header.platform }}
            </template>
        </BaseLogo>
        <div class="modal" :class="{ hide: modals.otp }">
            <div class="title-wrapper">
                <BaseModalTitle>
                    <template #left>
                        <div class="modal-title-left">
                            <i class="material-icons">logout</i>
                            <span class="title__medium">{{ tokens.loginModal.login }}</span>
                        </div>
                    </template>
                    <template #right>
                        <div class="modal-title-right">
                            <span class="label__medium">{{ tokens.loginModal.dontHaveAccount }}</span>
                            <BaseButtonSmall
                                :buttonText="tokens.newDesign.registration"
                                :buttonStyle="'tonal-secondary'"
                                @click.native="goToRegisterPage" />
                        </div>
                    </template>
                </BaseModalTitle>
            </div>
            <div class="modal-box">
                <BaseInput
                    v-model="email"
                    :title="'Email'"
                    name="email"
                    autocomplete="off"
                    :placeholder="tokens.loginModal.enterEmail"
                    @keyup.native.enter="login" />

                <BaseInput
                    v-model="password"
                    :title="tokens.loginModal.password"
                    name="password"
                    :type="'password'"
                    :placeholder="tokens.loginModal.yourPassword"
                    @keyup.native.enter="login" />

            </div>
            <div class="modal-footer">
                <div v-if="!hideForgotPassword" class="modal-forgot">
                    <span class="label__medium">
                        {{ tokens.forgotPasswordModal.forgotPassword }}
                    </span>
                    <BaseButtonSmall
                        :buttonText="tokens.newDesign.recovery"
                        :buttonStyle="'tonal-secondary'"
                        @click.native="showForgot" />
                </div>
                <BaseButton
                    :buttonStyle="'filled-primary'"
                    :buttonText="tokens.loginModal.signIn"
                    @click.native="login"
                    @keydown.native.enter="login" />
            </div>
        </div>
        <TwoFactorAuthenticationModal
            v-if="newDesign"
            :class="{ hide: !modals.otp }"
            :email="this.email"
            :password="this.password" />
    </div>
</template>

<script>
import {
    APPS,
    BRAND,
    HIDE_FORGOT_PASSWORD,
    NEW_DESIGN,
    WEBSITE,
    TRADINGVIEW_THEME,
    LOGO,
    HIDE_LOGO,
    STATIC_BASE,
    LOGO_REDIRECT_OFF
} from "@/common/config";
import { mapGetters } from 'vuex'
import { LOGIN, PRE_LOGIN } from "@/store/actions.type";
import { MODAL } from "@/store/mutations.type";
import TwoFactorAuthenticationModal from "@/new-design/widgets/Auth/ui/TwoFactorAuthenticationModal";
import { hidePass, showPass } from "@/common/helpers";

import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton";
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall";
import BaseModalTitle from "@/new-design/shared/ui/titles/BaseModalTitle";
import BaseLogo from "@/new-design/shared/ui/Icons/BaseLogo";

export default {
    name: "LoginModal",
    components: {
        TwoFactorAuthenticationModal,
        BaseInput,
        BaseButton,
        BaseButtonSmall,
        BaseModalTitle,
        BaseLogo
    },
    data() {
        return {
            newDesign: NEW_DESIGN,
            hideForgotPassword: HIDE_FORGOT_PASSWORD,
            email: '',
            password: '',
            otp: '',
            apps: APPS,
            brand: BRAND,
            website: WEBSITE,
            tradingViewTheme: TRADINGVIEW_THEME,
            logo: LOGO,
            hideLogo: HIDE_LOGO,
            staticBase: STATIC_BASE,
            logoRedirectOff: LOGO_REDIRECT_OFF
        }
    },
    methods: {
        showPass,
        hidePass,
        login() {
            this.newDesign ?
                this.$store.dispatch(PRE_LOGIN, {
                    email: this.email.trim(),
                    password: this.password.trim(),
                    otp: this.otp
                }) : this.$store.dispatch(LOGIN, {
                    email: this.email.trim(),
                    password: this.password.trim(),
                    otp: this.otp
                })
        },
        showForgot() {
            this.$store.commit(MODAL, {
                forgotPassword: true
            })
        },
        goToRegisterPage() {
            window.location.href = '/register';
        },
    },
    computed: {
        ...mapGetters(['loggedIn', 'modals', 'tokens']),
        appsData() {
            let resultingArray = [],
                icons = ['AnyDesk-logo', 'TeamViewer-logo', 'aeroadmin'],
                paths = ['https://anydesk.com/download', 'https://teamviewer.com/download', 'https://www.aeroadmin.com'],
                path = '',
                icon = ''

            for (const item in this.apps) {
                icon = `${icons[item]}.png`
                path = `${paths[item]}`

                resultingArray.push({ icon, path })
            }

            return resultingArray
        },
        logoComputed() {
            return this.tradingViewTheme === 'Light' ? this.logo.url.replace(/white/, 'black') : this.logo.url
        }
    },
    watch: {
        loggedIn(val) {
            if (val) {
                this.email = ''
                this.password = ''
                this.otp = ''
            }
        }
    }
}
</script>

<style scoped>
.new-design.mobile.banner-is-active .modal-wrapper,
.new-design.mobile.banner-is-active .modal-otp {
    height: calc(100% - 44px);
}

.modal-wrapper,
.modal-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    top: auto;
}

.theme_3-logo {
    display: inline-block;
    width: max-content;
    position: absolute;
    top: 5px;
    left: 10px;
}

.modal {
    width: 620px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 25px 28px;
    z-index: 1100;
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
    background: var(--System-Surface1);
    position: relative;
}

.title-wrapper {
    margin-bottom: 34px;
}

.modal-title-left {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-title-left i {
    font-size: 18px;
}

.modal-title-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.modal-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-forgot{
    display: flex;
    align-items: center;
    gap: 10px;
}

@media(max-width: 768px){
    .modal{
        margin: 15px;
    }
}
</style>
