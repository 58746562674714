export const cryptoPspList = [{
    alias: "nax",
    text: 'Crypto',
    symbol: 'BTC',
    tabs: [
        {
            name: 'BTC',
            text: 'Bitcoin'
        }
    ]
    
},{
    alias: "cryptocurrency",
    text: 'Cryptocurrency',
    symbol: 'BTC',
    tabs: [
        {
            name: 'BTC',
            text: 'Bitcoin'
        },
        {
            name: 'LTC',
            text: 'Litecoin'
        },
        {
            name: 'DOGE',
            text: 'Dogecoin'
        },
        {
            name: 'BCH',
            text: 'Bitcoin Cash'
        },
        {
            name: 'ETH',
            text: 'Ethereum'
        },
        {
            name: 'MATIC',
            text: 'Polygon'
        },
        {
            name: 'BNB',
            text: 'BNB'
        },
    ]
},{
    alias: "bithide",
    text: 'BitHide',
    symbol: 'BTC',
    tabs: [
        {
            name: 'BTC',
            text: 'Bitcoin'
        },
        {
            name: 'USDT',
            text: 'USDT TRC-20'
        },
    ]
},
]
