<template>
    <div class="notifications-list">
        <NotificationsListItem
            v-for="notification in displayNotifications"
            :key="notification.id"
            :notification="notification"
        />
    </div>
</template>

<script>
import NotificationsListItem
    from "@/new-design/features/Notifications/NotificationsListItem/ui/NotificationsListItem.vue"
import {mapGetters} from "vuex"
import {currencySign} from "@/common/helpers";

export default {
    name: "NotificationsList",
    components: {NotificationsListItem},
    computed: {
        ...mapGetters(['notifications', 'tokens','symbols','activeAccount']),
        displayNotifications() {
            let nots = JSON.parse(JSON.stringify(this.notifications))

            for (let n in nots) {
                switch (nots[n].type) {
                    case 'order':
                        nots[n].title = `${this.tokens.newDesign.order} ${nots[n].data.id} ${this.tokens.newDesign.closedSuccessfully}`
                        nots[n].textClass = nots[n].data.profit > 0 ? 'green' : (nots[n].data.profit < 0 ? 'red' : '')
                        nots[n].profitText = (nots[n].data.profit > 0 ? '+' : (0 > nots[n].data.profit ? '-' : '')) + currencySign(this.activeAccount.currency) + Math.abs(nots[n].data.profit).toFixed(this.symbols[nots[n].data.symbol] ? this.symbols[nots[n].data.symbol].precision : 2)
                        nots[n].text = (nots[n].data.profit > 0 ? '+' : (0 > nots[n].data.profit ? '-' : '')) + (100 * Math.abs(nots[n].data.price_close - nots[n].data.price_open) / nots[n].data.price_open).toFixed(2) + '%'
                        nots[n].classes = {hide: true}
                        break
                    case 'order_open':
                        nots[n].title = `${this.tokens.newDesign.order} ${nots[n].data.id} ${this.tokens.newDesign.openSuccessfully}`
                        nots[n].textClass = nots[n].data.type % 2 ? 'red' : 'green'
                        nots[n].profitText = ''
                        nots[n].text = Number(nots[n].data.volume.toFixed(4)) + ' ' + nots[n].data.symbol + ' @ ' + nots[n].data.price_open.toFixed(this.symbols[nots[n].data.symbol] ? this.symbols[nots[n].data.symbol].precision : 2)
                        nots[n].classes = {hide: true}
                        break
                    case 'success':
                        nots[n].title = this.tokens.notifications.success
                        nots[n].textClass = ''
                        nots[n].profitText = ''
                        nots[n].text = this.tokens.errors[nots[n].text] || nots[n].text
                        nots[n].icon = `
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="#2A6A64"/>
                            </svg>
                        `
                        nots[n].classes = {green: true}
                        break
                    case 'error':
                        nots[n].title = this.tokens.notifications.error
                        nots[n].textClass = ''
                        nots[n].profitText = ''
                        nots[n].text = this.tokens.errors[nots[n].text] || nots[n].text
                        nots[n].icon = `
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="#BA1A1A"/>
                            </svg>
                        `
                        nots[n].classes = {brand: true}
                        break
                    case 'support':
                        nots[n].title = ''
                        nots[n].textClass = ''
                        nots[n].profitText = ''
                        nots[n].icon = `
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M17 17C17.4167 17 17.7708 16.8542 18.0625 16.5625C18.3542 16.2708 18.5 15.9167 18.5 15.5C18.5 15.0833 18.3542 14.7292 18.0625 14.4375C17.7708 14.1458 17.4167 14 17 14C16.5833 14 16.2292 14.1458 15.9375 14.4375C15.6458 14.7292 15.5 15.0833 15.5 15.5C15.5 15.9167 15.6458 16.2708 15.9375 16.5625C16.2292 16.8542 16.5833 17 17 17ZM17 20C17.5167 20 17.9917 19.8792 18.425 19.6375C18.8583 19.3958 19.2083 19.075 19.475 18.675C19.1083 18.4583 18.7167 18.2917 18.3 18.175C17.8833 18.0583 17.45 18 17 18C16.55 18 16.1167 18.0583 15.7 18.175C15.2833 18.2917 14.8917 18.4583 14.525 18.675C14.7917 19.075 15.1417 19.3958 15.575 19.6375C16.0083 19.8792 16.4833 20 17 20ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V10.675C19.6833 10.5417 19.3583 10.4208 19.025 10.3125C18.6917 10.2042 18.35 10.125 18 10.075V6.4L12 4.15L6 6.4V11.1C6 11.8833 6.10417 12.6667 6.3125 13.45C6.52083 14.2333 6.8125 14.9792 7.1875 15.6875C7.5625 16.3958 8.01667 17.05 8.55 17.65C9.08333 18.25 9.675 18.75 10.325 19.15C10.5083 19.6833 10.75 20.1917 11.05 20.675C11.35 21.1583 11.6917 21.5917 12.075 21.975C12.0583 21.975 12.0458 21.9792 12.0375 21.9875C12.0292 21.9958 12.0167 22 12 22ZM17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3833 12 17C12 15.6167 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 12 17 12C18.3833 12 19.5625 12.4875 20.5375 13.4625C21.5125 14.4375 22 15.6167 22 17C22 18.3833 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22Z" fill="#5D626D"/>
                        </svg>
                        `
                        nots[n].classes = {green: true}
                        break
                    case 'transaction':
                        nots[n].profitText = nots[n].data.status === 1 ? (nots[n].data.amount > 0 ? '+' : '-') + currencySign(nots[n].data.currency) + Math.abs(nots[n].data.amount).toLocaleString(undefined, {
                                minimumFractionDigits: this.activeAccount.precision,
                                maximumFractionDigits: this.activeAccount.precision
                            }) : ''

                        if(nots[n].data.type === 1){
                            // Desposit Transaction
                            if(nots[n].data.status === 1){
                                // Deposit Approved
                                nots[n].title = nots[n].profitText
                                nots[n].text = `${this.tokens.notifications.deposit} #${nots[n].data.id} ${(this.tokens.notifications.depositApproved)}`
                            }else{
                                // Deposit Declined
                                nots[n].title = `${this.tokens.notifications.deposit} #${nots[n].data.id}`
                                nots[n].text = this.tokens.notifications.depositDeclined
                            }
                        }else{
                            // Withdrawal Transaction
                            nots[n].title = `${this.tokens.notifications.withdrawal} #${nots[n].data.id}`
                            nots[n].text = `${(nots[n].data.status === 1 ? this.tokens.notifications.withdrawalApproved : this.tokens.notifications.withdrawalDeclined)}`
                        }
                        
                        nots[n].textClass = ''
                        nots[n].icon = nots[n].data.status === 1 ? `
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="#93d5c6"/>
                            </svg>
                        ` : `
                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z" fill="#BA1A1A"/>
                            </svg>
                        `
                        nots[n].classes = {green: nots[n].data.status === 1, red: nots[n].data.status === 2}
                        break
                    case 'verification':
                        nots[n].title = this.tokens.newDesign.completeVerification
                        nots[n].textClass = 'verification'
                        nots[n].icon = `
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 17C17.4167 17 17.7708 16.8542 18.0625 16.5625C18.3542 16.2708 18.5 15.9167 18.5 15.5C18.5 15.0833 18.3542 14.7292 18.0625 14.4375C17.7708 14.1458 17.4167 14 17 14C16.5833 14 16.2292 14.1458 15.9375 14.4375C15.6458 14.7292 15.5 15.0833 15.5 15.5C15.5 15.9167 15.6458 16.2708 15.9375 16.5625C16.2292 16.8542 16.5833 17 17 17ZM17 20C17.5167 20 17.9917 19.8792 18.425 19.6375C18.8583 19.3958 19.2083 19.075 19.475 18.675C19.1083 18.4583 18.7167 18.2917 18.3 18.175C17.8833 18.0583 17.45 18 17 18C16.55 18 16.1167 18.0583 15.7 18.175C15.2833 18.2917 14.8917 18.4583 14.525 18.675C14.7917 19.075 15.1417 19.3958 15.575 19.6375C16.0083 19.8792 16.4833 20 17 20ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V10.675C19.6833 10.5417 19.3583 10.4208 19.025 10.3125C18.6917 10.2042 18.35 10.125 18 10.075V6.4L12 4.15L6 6.4V11.1C6 11.8833 6.10417 12.6667 6.3125 13.45C6.52083 14.2333 6.8125 14.9792 7.1875 15.6875C7.5625 16.3958 8.01667 17.05 8.55 17.65C9.08333 18.25 9.675 18.75 10.325 19.15C10.5083 19.6833 10.75 20.1917 11.05 20.675C11.35 21.1583 11.6917 21.5917 12.075 21.975C12.0583 21.975 12.0458 21.9792 12.0375 21.9875C12.0292 21.9958 12.0167 22 12 22ZM17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3833 12 17C12 15.6167 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 12 17 12C18.3833 12 19.5625 12.4875 20.5375 13.4625C21.5125 14.4375 22 15.6167 22 17C22 18.3833 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22Z" fill="#9A9DAB"/>
                            </svg>
                        `
                        nots[n].chips = [{
                            text: this.tokens.newDesign.verification,
                            link: 'verification',
                            icon: 'open_in_new'
                        }]
                        break
                }
            }

            return nots
        }
    }
}
</script>

<style scoped>
.notifications-list {
    position: fixed;
    bottom: 40px;
    left: 120px;
    z-index: 10000
}

@media (max-width: 767px) {
    .notifications-list {
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
