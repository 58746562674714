<template>
    <div class="account-info-list">
        <AccountInfoListItem
            :title="tokens.fullScreenDashboard.totalBalance"
            :data="totalBalance ? totalBalance.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) : 0">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V7.5H19V5H5V19H19V16.5H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM13 17C12.45 17 11.9792 16.8042 11.5875 16.4125C11.1958 16.0208 11 15.55 11 15V9C11 8.45 11.1958 7.97917 11.5875 7.5875C11.9792 7.19583 12.45 7 13 7H20C20.55 7 21.0208 7.19583 21.4125 7.5875C21.8042 7.97917 22 8.45 22 9V15C22 15.55 21.8042 16.0208 21.4125 16.4125C21.0208 16.8042 20.55 17 20 17H13ZM20 15V9H13V15H20ZM16 13.5C16.4167 13.5 16.7708 13.3542 17.0625 13.0625C17.3542 12.7708 17.5 12.4167 17.5 12C17.5 11.5833 17.3542 11.2292 17.0625 10.9375C16.7708 10.6458 16.4167 10.5 16 10.5C15.5833 10.5 15.2292 10.6458 14.9375 10.9375C14.6458 11.2292 14.5 11.5833 14.5 12C14.5 12.4167 14.6458 12.7708 14.9375 13.0625C15.2292 13.3542 15.5833 13.5 16 13.5Z"
                            fill="url(#paint0_linear_1313_1202)" />
                        <defs>
                            <linearGradient id="paint0_linear_1313_1202" x1="3" y1="3" x2="15.4376" y2="27.7936"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4A7EFF" />
                                <stop offset="0.486792" stop-color="#61BEDA" />
                                <stop offset="1" stop-color="#404C73" />
                            </linearGradient>
                        </defs>
                    </svg>
                </template>
        </AccountInfoListItem>
        <AccountInfoListItem
            :title="tokens.fullScreenDashboard.totalPnl"
            :data="pnl.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })"
            :color="pnl > 0 ? 'green' : pnl < 0 ? 'red' : ''">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <path
                            d="M5 11.6197V3.98975H8V11.6197L6.5 10.2233L5 11.6197ZM10 13.1157V0.000244141H13V10.1236L10 13.1157ZM0 16.5567V7.97925H3V13.5645L0 16.5567ZM0 19.0002L6.45 12.5672L10 15.6092L15.6 10.0239H14V8.02912H19V13.016H17V11.4202L10.1 18.3021L6.55 15.2601L2.8 19.0002H0Z"
                            fill="url(#paint0_linear_1313_1211)" />
                        <defs>
                            <linearGradient id="paint0_linear_1313_1211" x1="0" y1="0.000244141" x2="13.5469" y2="25.5839"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4A7EFF" />
                                <stop offset="0.486792" stop-color="#61BEDA" />
                                <stop offset="1" stop-color="#404C73" />
                            </linearGradient>
                        </defs>
                    </svg>
                </template>
        </AccountInfoListItem>
        <AccountInfoListItem
            :title="tokens.fullScreenDashboard.profitableOrders"
            :data="cntTotalData">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                        <path
                            d="M0 17V15L2 13V17H0ZM4 17V11L6 9V17H4ZM8 17V9L10 11.025V17H8ZM12 17V11.025L14 9.025V17H12ZM16 17V7L18 5V17H16Z"
                            fill="url(#paint0_linear_1313_1220)" />
                        <path d="M6.70001 4.2L10.95 8.45L18 1.4L16.6 0L10.95 5.65L8.10001 2.8L6.70001 4.2Z"
                            fill="url(#paint1_linear_1313_1220)" />
                        <defs>
                            <linearGradient id="paint0_linear_1313_1220" x1="0" y1="0" x2="11.7249" y2="23.4453"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4A7EFF" />
                                <stop offset="0.486792" stop-color="#61BEDA" />
                                <stop offset="1" stop-color="#404C73" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_1313_1220" x1="0" y1="0" x2="11.7249" y2="23.4453"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4A7EFF" />
                                <stop offset="0.486792" stop-color="#61BEDA" />
                                <stop offset="1" stop-color="#404C73" />
                            </linearGradient>
                        </defs>
                    </svg>
                </template>
        </AccountInfoListItem>
        <AccountInfoListItem
            :title="tokens.fullScreenDashboard.totalDeposits"
            :data="totalDeposits.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                        <path
                            d="M11.5711 11.2427L11.518 9.2451H7.91179L14.7426 2L13.2426 0.5L6.49758 7.83089L6.49758 4.22465L4.5 4.17161V11.2427H11.5711Z"
                            fill="url(#paint0_linear_1313_1228)" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 17V15H0L5.96046e-08 17H18Z"
                            fill="url(#paint1_linear_1313_1228)" />
                        <defs>
                            <linearGradient id="paint0_linear_1313_1228" x1="0" y1="0.5" x2="11.1749" y2="23.5227"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4A7EFF" />
                                <stop offset="0.486792" stop-color="#61BEDA" />
                                <stop offset="1" stop-color="#404C73" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_1313_1228" x1="0" y1="0.5" x2="11.1749" y2="23.5227"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4A7EFF" />
                                <stop offset="0.486792" stop-color="#61BEDA" />
                                <stop offset="1" stop-color="#404C73" />
                            </linearGradient>
                        </defs>
                    </svg>
                </template>
        </AccountInfoListItem>
        <AccountInfoListItem
            v-if="roiOnDashboard"
            :title="tokens.fullScreenDashboard.returnOnInvestment"
            :data="`ROI (${pnl.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })} / ${totalDeposits.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}) × 100`">
                    <template #icon>
                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 16V14H3.25L2.85 13.65C1.98333 12.8833 1.375 12.0083 1.025 11.025C0.675 10.0417 0.5 9.05 0.5 8.05C0.5 6.2 1.05417 4.55417 2.1625 3.1125C3.27083 1.67083 4.71667 0.716667 6.5 0.25V2.35C5.3 2.78333 4.33333 3.52083 3.6 4.5625C2.86667 5.60417 2.5 6.76667 2.5 8.05C2.5 8.8 2.64167 9.52917 2.925 10.2375C3.20833 10.9458 3.65 11.6 4.25 12.2L4.5 12.45V10H6.5V16H0.5ZM12.5 15.75V13.65C13.7 13.2167 14.6667 12.4792 15.4 11.4375C16.1333 10.3958 16.5 9.23333 16.5 7.95C16.5 7.2 16.3583 6.47083 16.075 5.7625C15.7917 5.05417 15.35 4.4 14.75 3.8L14.5 3.55V6H12.5V0H18.5V2H15.75L16.15 2.35C16.9667 3.16667 17.5625 4.05417 17.9375 5.0125C18.3125 5.97083 18.5 6.95 18.5 7.95C18.5 9.8 17.9458 11.4458 16.8375 12.8875C15.7292 14.3292 14.2833 15.2833 12.5 15.75Z" fill="url(#paint0_linear_2726_27598)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0636 13H9.22543V12.0312C8.66667 11.9479 8.19461 11.7188 7.80925 11.3438C7.42389 10.9688 7.15414 10.4896 7 9.90625L8.01156 9.4375C8.08863 9.77083 8.23314 10.0625 8.44509 10.3125C8.65703 10.5625 8.91715 10.7396 9.22543 10.8438V8.375C8.72447 8.14583 8.27649 7.84375 7.8815 7.46875C7.48651 7.09375 7.28902 6.625 7.28902 6.0625C7.28902 5.45833 7.47688 4.97396 7.8526 4.60938C8.22832 4.24479 8.68593 4.03125 9.22543 3.96875V3H10.0925V4C10.4971 4.08333 10.8632 4.25521 11.1908 4.51562C11.5183 4.77604 11.7495 5.125 11.8844 5.5625L10.8439 6.03125C10.7861 5.82292 10.6946 5.64583 10.5694 5.5C10.4441 5.35417 10.2852 5.25 10.0925 5.1875V7.5C10.5934 7.72917 11.0366 8.02604 11.422 8.39062C11.8073 8.75521 12 9.20833 12 9.75C12 10.375 11.8121 10.8854 11.4364 11.2812C11.0607 11.6771 10.6031 11.9167 10.0636 12V13ZM10.0925 10.8125V8.75C10.3044 8.85417 10.4923 8.97917 10.6561 9.125C10.8198 9.27083 10.9017 9.48958 10.9017 9.78125C10.9017 10.1146 10.8247 10.349 10.6705 10.4844C10.5164 10.6198 10.3237 10.7292 10.0925 10.8125ZM8.6474 6.71875C8.82081 6.86458 9.01349 6.98958 9.22543 7.09375V5.15625C9.01349 5.19792 8.82081 5.28646 8.6474 5.42188C8.47399 5.55729 8.38728 5.77083 8.38728 6.0625C8.38728 6.35417 8.47399 6.57292 8.6474 6.71875Z" fill="url(#paint1_linear_2726_27598)"/>
                            <defs>
                                <linearGradient id="paint0_linear_2726_27598" x1="0.5" y1="0" x2="11.1289" y2="22.582" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#4A7EFF"/>
                                    <stop offset="0.486792" stop-color="#61BEDA"/>
                                    <stop offset="1" stop-color="#404C73"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_2726_27598" x1="0.5" y1="0" x2="11.1289" y2="22.582" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#4A7EFF"/>
                                    <stop offset="0.486792" stop-color="#61BEDA"/>
                                    <stop offset="1" stop-color="#404C73"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </template>
                    <template #sub-title>
                        <div class="sub-title">
                            {{ (((Number.isFinite(pnl / totalDeposits) ? (pnl / totalDeposits) : 0) * 100).toFixed(3)) + '%' }}
                        </div>
                    </template>
        </AccountInfoListItem>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { currencySign } from '@/common/helpers'
import AccountInfoListItem from './AccountInfoListItem.vue'
import {ROI_ON_DASHBOARD} from '@/common/config'

export default {
    name: "AccountInfoList",
    components: { AccountInfoListItem },
    data(){
        return {
            roiOnDashboard: ROI_ON_DASHBOARD,
        }
    },
    computed: {
        ...mapGetters(['tokens', 'dashboardData','accounts','stakingAccounts','conversions','activeCurrency','user']),
        plate() {
            let plate = []
            for (const account in this.accounts) {
                let metal = ['beginner', 'student', 'basic', 'bronze', 'bronze_plus', 'silver', 'silver_plus', 'gold', 'platinum', 'platinum_plus', 'vip', 'top', 'diamond','islamic','business','enterprise','regular','arbitrage','basicml','shift','custom','professional','brilliant','diamonds','individual','longterm','royalclub','cx','cx_2','cx_3'].includes(this.activeAccount.group) ? this.activeAccount.group : 'main'
                plate[this.accounts[account].id] = this.staticBase + this.platesFolder + '/' + metal + '_plate.png'
            }
            return plate
        },
        totalBalance() {
            if (!Object.keys(this.dashboardData.stats).length && !Object.keys(this.dashboardData.stats.accounts).length) {
                return 0
            }

            if (this.activeCurrency === 'USD') return this.user.balance_total;

            let accBalance = [].concat(
                ...Object.values(this.accounts).map(acc => acc.balance),
                ...Object.values(this.stakingAccounts).map(acc => acc.balance)
            )
            let accountsCur = [].concat(
                ...Object.values(this.accounts).map(acc => acc.currency),
                ...Object.values(this.stakingAccounts).map(acc => acc.currency)
            )
            let balanceTotal = accBalance.reduce((total, balance) => total + balance, 0)

            if (this.isOneAccountCurrency(accountsCur, accBalance) && this.activeCurrency === accountsCur[0]) {
                return balanceTotal
            } else {
                balanceTotal = this.convertToUSD(balanceTotal, accountsCur, accBalance)
                return this.convToActiveDashCurrency(balanceTotal)
            }
        },
        totalDeposits() {
            if (this.activeCurrency !== 'USD') {
                return this.convToActiveDashCurrency(this.dashboardData.stats.deposits)
            }
            return this.dashboardData.stats.deposits
        },
        pnl() {
            let pnl = []
            let accountsCur = []
            let accountId = Object.values(this.accounts).map(acc => acc.id)
            let dashAccId = []
            let pnlTotal = 0

            if (this.dashboardData.stats && this.dashboardData.stats.accounts) {
                dashAccId = Object.values(this.dashboardData.stats.accounts).map(acc => acc.account_id)
            }

            for (let i = 0; i < accountId.length; i++) {
                const index = dashAccId.indexOf(accountId[i])
                if (index !== -1) {
                    pnl.push(this.dashboardData.stats.accounts[index].profit)
                    accountsCur.push(this.accounts[accountId[i]].currency)
                }
            }

            if (this.isOneAccountCurrency(accountsCur, pnl)) {
                return this.dashboardData.stats.profit
            } else {
                pnlTotal = this.convertToUSD(pnlTotal, accountsCur, pnl)
                return this.convToActiveDashCurrency(pnlTotal)
            }
        },
        cntTotal() {
            let cntTotal = 0

            for (const cntStat in this.dashboardData.stats.accounts) {
                cntTotal += this.dashboardData.stats.accounts[cntStat].cnt_total
            }

            return cntTotal
        },
        cntTotalData() {
            return `${this.dashboardData?.stats?.cnt_profit || "0"} | ${this.cntTotal}`
        },
    },
    methods: {
        isOneAccountCurrency(accounts, accountsValue) {
            let isOneAccountCurrency = true

            for (let i = 0; i < accounts.length; i++) {
                if (!(accounts[i] === this.activeCurrency && accounts.length === accountsValue.length)) {
                    return false
                }
            }

            return isOneAccountCurrency
        },
        convToActiveDashCurrency(value) {
            if (this.activeCurrency !== 'USD') {
                return value / this.conversions[this.activeCurrency]
            }
            return value
        },
        convertToUSD(value, accountsCurrency, accountValues) {
            value = 0
            let acc

            for (let i = 0; i < accountValues.length; i++) {
                acc = 1

                if (accountsCurrency[i] !== 'USD') {
                    acc = this.conversions[accountsCurrency[i]]
                    value += acc * accountValues[i]
                } else {
                    acc = this.conversions[accountsCurrency[i]]
                    value += accountValues[i] / acc
                }
            }
            return value
        },
        currencySign
    }
}
</script>

<style scoped>
.account-info-list {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 6px));
    grid-gap: 12px;
    width: 100%;
}

.account-info-list > *:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
}

.sub-title {
    color: var(--System-OnSurface);
}
</style>
