<template>
    <div :class="{hideTotalDeposits: hideTotalDeposits && !theme_3_Design}">
        <template v-if="!theme_3_Design" class="dashboard" :class="{mobile: isMobile}">
            <div class="info__items-container">
                <div class="info__item total-balance">
                    <div class="left">
                        <img :src="staticBase+`misc/goblet.png`">
                    </div>
                    <div class="right">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalBalance }}</h4>
                        <p class="value"
                           :class="totalBalance < 0 ? 'red-gradient' : (totalBalance >= 0 ? 'green-gradient' : '')">
                            {{
                                activeCurrency && totalBalance >= 0 ? currencySign(activeCurrency) + totalBalance.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) : `${currencySign('USD')} 0`
                            }}</p>
                    </div>
                </div>
                <div class="info__item total-pnl">
                    <div class="left">
                        <img :src="staticBase+`misc/gem.png`">
                    </div>
                    <div class="right">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalPnl }}</h4>
                        <p class="value"
                           :class="pnl < 0 ? 'red-gradient' : pnl >= 0 ? 'green-gradient' : 'green-gradient'">
                            {{
                                activeCurrency ? currencySign(activeCurrency) + pnl.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) : `${currencySign('USD')} 0`
                            }}
                        </p>
                        <span>* {{ tokens.fullScreenDashboard.usingCurrentRate }}</span>
                    </div>
                </div>
                <div class="info__item profitable-orders">
                    <div class="left">
                        <img :src="staticBase+`misc/target.png`">
                    </div>
                    <div class="right">
                        <h4 class="title">{{ tokens.fullScreenDashboard.profitableOrders }}</h4>
                        <p class="value green-gradient">
                            {{ dashboardData.stats.cnt_profit ? dashboardData.stats.cnt_profit : "0" }} / {{ cntTotal }}</p>
                    </div>
                </div>
                <div class="info__item roi" :class="{hide: hideTotalDeposits}">
                    <div class="left">
                        <img :src="staticBase+`misc/totalDeposit.png`">
                    </div>
                    <div class="right">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalDeposits }}</h4>
                        <p class="value roi-value"
                           :class="dashboardData.stats.deposits < 0 ? 'red-gradient' : 'green-gradient'">
                            {{
                                activeCurrency && totalDeposits > 0 ? currencySign(activeCurrency) + totalDeposits.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) : `${currencySign('USD')} 0`
                            }}
                        </p>
                    </div>
                </div>
                <div class="info__item total-roi" :class="{hide: !roiOnDashboard}">
                    <div class="left">
                        <img :src="staticBase+`misc/gem.png`">
                    </div>
                    <div class="right">
                        <h4 class="title">{{ tokens.fullScreenDashboard.returnOnInvestment }}</h4>
                        <p class="value">
                            {{ (((pnl / totalDeposits) * 100).toFixed(3) || 0) + '%' }}
                        </p>
                    </div>
                </div>
                <div class="info__item total-withdrawal" :class="{hide: !showTotalWithdrawals}">
                    <div class="left">
                        <img :src="staticBase+`misc/gem.png`">
                    </div>
                    <div class="right">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalWithdrawals }}</h4>
                        <p class="value">
                            {{ totalWithdrawals && (currencySign(activeCurrency) + 
                                totalWithdrawals.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }))
                            }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="chart-line">
                <div class="chart-line-head">
                    <h4>{{ tokens.fullScreenDashboard.tradingResults }}</h4>
                    <div class="time">
                        <button class="time-item fs-small" :class="{active: timeframe === 'Week'}"
                                @click="changeTimeframe('Week',7)">{{ tokens.fullScreenDashboard.week }}
                        </button>
                        <button class="time-item fs-small" :class="{active: timeframe === 'Month'}"
                                @click="changeTimeframe('Month',30)">{{ tokens.fullScreenDashboard.month }}
                        </button>
                    </div>
                </div>
                <div style="display: grid; height: 100%"> <!-- hack for highchart height -->
                    <highcharts :options="chartOptionsLine" ref="lineChart"/>
                </div>
            </div>
            <div class="chart-pie">
                <h4>{{ tokens.fullScreenDashboard.successRate }}</h4>
                <div style="display: grid; height: 100%"> <!-- hack for highchart height -->
                    <highcharts :options="chartOptionsPie" ref="pieChart"/>
                </div>
            </div>
            <div class="account" v-for="account in accounts ? accounts : ''"
                 :key="account.id"
                 :class="{
                        active: account && activeAccount.id === account.id,
                        real: account && !account.demo,
                        demo: account && account.demo
                    }">
                <div class="head">
                    <h3>{{ tokens.fullScreenDashboard.account }}</h3>
                    <div class="balances">
                        <img :src="plate[account.id]">
                        <span class="sign balance">{{ currencySign(account.currency) }}</span>
                    </div>
                </div>
                <div class="body">
                    <div class="info">
                        <h4>{{ tokens.fullScreenDashboard.balance }}</h4>
                        <p class="value"
                           :class="account.balance < 0 ? 'red-gradient' : (account.balance >= 0 ? 'green-gradient' : '')">
                            {{
                                currencySign(account.currency) + ' ' + (account.balance > 0 ? account.balance : 0).toLocaleString(undefined, {
                                    minimumFractionDigits: account.precision,
                                    maximumFractionDigits: account.precision
                                })
                            }}</p>
                    </div>
                    <div class="info" v-show="parseInt(showLeverage) === 1">
                        <h4>{{ tokens.fullScreenDashboard.leverage }}</h4>
                        <p class="value"
                           :class="account.leverage < 0 ? 'red-gradient' : (account.leverage >= 0 ? 'green-gradient' : '')">
                            {{ '1:' + account.leverage }}</p>
                    </div>
                    <div class="info">
                        <h4>{{ tokens.fullScreenDashboard.credit }}</h4>
                        <p class="value"
                           :class="account.credit < 0 ? 'red-gradient' : (account.credit >= 0 ? 'green-gradient' : '')">
                            {{
                                currencySign(account.currency) + ' ' + (account.credit).toLocaleString(undefined, {
                                    minimumFractionDigits: account.precision,
                                    maximumFractionDigits: account.precision
                                })
                            }}</p>
                    </div>
                </div>
                <div class="footer">
                    <button class="fs" @click="makeActive(account.id)">{{
                            tokens.fullScreenDashboard.tradeNow
                        }}
                    </button>
                </div>
            </div>
        </template>
        <template v-else class="theme_3-design">
            <div class="main-info">
                <div class="account-info info__items-container">
                    <div class="info__item total-balance">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalBalance }}</h4>
                        <div class="box">
                            <img :src="staticBase+`currencies/${activeCurrency}.png`">
                            <div class="values">
                                <p class="value converted-currency">
                                    {{
                                        activeCurrency && totalBalance >= 0 ? currencySign(activeCurrency) + totalBalance.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }) : `${currencySign('USD')} 0`
                                    }}</p>
                                <p class="value-usd"
                                   :class="getUsd(totalBalance) < 0 ? 'theme_3-red' : getUsd(totalBalance) > 0 ? 'theme_3-green' : ''">
                                    ${{
                                        getBalanceUsd().toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="info__item roi">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalDeposits }}</h4>
                        <div class="box">
                            <img :src="staticBase+`currencies/${activeCurrency}.png`">
                            <div class="values">
                                <p class="value converted-currency">
                                    {{
                                        activeCurrency && totalDeposits > 0 ? currencySign(activeCurrency) + totalDeposits.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }) : `${currencySign('USD')} 0`
                                    }}
                                </p>
                                <p class="value-usd"
                                   :class="getBalanceUsd() < 0 ? 'theme_3-red' : getUsd(totalBalance) > 0 ? 'theme_3-green' : ''">
                                    ${{
                                        getUsd(totalDeposits).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="info__item profitable-orders">
                        <div class="box">
                            <img :src="staticBase+`new-design/1.png`">
                            <h4 class="title">{{ tokens.fullScreenDashboard.profitableOrders }}</h4>
                        </div>
                        <p class="value green-gradient">
                            {{ dashboardData.stats.cnt_profit ? dashboardData.stats.cnt_profit : "0" }} |
                            {{ cntTotal }}</p>
                    </div>
                    <div class="info__item total-pnl">
                        <div class="box">
                            <img :src="staticBase+`new-design/2.png`">
                            <h4 class="title">{{ tokens.fullScreenDashboard.totalPnl }}</h4>
                        </div>
                        <div class="box currency">
                            <img :src="staticBase+`currencies/${activeCurrency}.png`">
                            <p class="value"
                               :class="pnl < 0 ? 'theme_3-red' : pnl >= 0 ? 'theme_3-green' : ''">
                                {{
                                    activeCurrency ? pnl.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) : `0`
                                }}
                            </p>
                        </div>
                    </div>
                    <div v-if="roiOnDashboard" class="info__item total-roi">
                        <h4 class="title">{{ tokens.fullScreenDashboard.returnOnInvestment }}</h4>
                        <div class="box">
                            <div class="values">
                                <p class="value converted-currency">
                                    {{ (((Number.isFinite(pnl / totalDeposits) ? (pnl / totalDeposits) : 0) * 100).toFixed(3)) + '%' }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="showTotalWithdrawals" class="info__item total-withdrawal">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalWithdrawals }}</h4>
                        <div class="box">
                            <img :src="staticBase+`currencies/${activeCurrency}.png`">
                            <div class="values">
                                <p class="value converted-currency" >
                                   {{ totalWithdrawals && (currencySign(activeCurrency) + 
                                        totalWithdrawals.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }))
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart">
                    <h4 class="title">{{ tokens.fullScreenDashboard.successRate }}</h4>
                    <div class="theme_3-pie-chart" style="display: grid; height: 100%">
                        <highcharts :options="theme_3PieChart" ref="theme_3PieChart"/>
                    </div>
                </div>
            </div>
            <div class="assets-stats">
                <div class="stat btc">
                    <div class="top">
                        <div class="symbol symbol-BTCUSD"></div>
                        <div class="currency-info">
                            <span>Bitcoin</span>
                            <div class="price">USD {{
                                    symbols['BTCUSD']?.quote.ask.toFixed(symbols['BTCUSD'].precision)
                                }}
                            </div>
                        </div>
                        <span>BTC</span>
                    </div>
                    <div class="bottom">
                        <highcharts :options="chartBTCUSD" ref="chartBTCUSD"/>
                        <div class="img-content">
                            <img src="" alt="">
                            <span class="pct"
                                  :class="{
                                        'theme_3-red': symbols['BTCUSD']?.quote && symbols['BTCUSD']?.quote.changePct < 0,
                                        'theme_3-green': symbols['BTCUSD']?.quote && 0 < symbols['BTCUSD']?.quote.changePct,
                                     }">
                                {{
                                    (symbols['BTCUSD']?.quote && symbols['BTCUSD']?.quote.changePct > 0 ? '+' : '') + (symbols['BTCUSD']?.quote && symbols['BTCUSD']?.quote.changePct ? symbols['BTCUSD']?.quote.changePct : 0).toFixed(2) + '%'
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="stat trx">
                    <div class="top">
                        <div class="symbol symbol-TRXUSD"></div>
                        <div class="currency-info">
                            <span>Tron</span>
                            <div class="price">USD {{
                                    symbols['TRXUSD']?.quote.ask.toFixed(symbols['TRXUSD'].precision)
                                }}
                            </div>
                        </div>
                        <span>TRX</span>
                    </div>
                    <div class="bottom">
                        <highcharts :options="chartTRXUSD" ref="chartTRXUSD"/>
                        <div class="img-content">
                            <img src="" alt="">
                            <span class="pct"
                                  :class="{
                                        'theme_3-red': symbols['TRXUSD']?.quote && symbols['TRXUSD']?.quote.changePct < 0,
                                        'theme_3-green': symbols['TRXUSD']?.quote && 0 < symbols['TRXUSD']?.quote.changePct,
                                     }">
                                {{
                                    (symbols['TRXUSD']?.quote && symbols['TRXUSD']?.quote.changePct > 0 ? '+' : '') + (symbols['TRXUSD']?.quote && symbols['TRXUSD']?.quote.changePct ? symbols['TRXUSD']?.quote.changePct : 0).toFixed(2) + '%'
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="stat sol">
                    <div class="top">
                        <div class="symbol symbol-SOLUSD"></div>
                        <div class="currency-info">
                            <span>Solana</span>
                            <div class="price">USD {{
                                    symbols['SOLUSD']?.quote.ask.toFixed(symbols['SOLUSD'].precision)
                                }}
                            </div>
                        </div>
                        <span>SOL</span>
                    </div>
                    <div class="bottom">
                        <highcharts :options="chartSOLUSD" ref="chartSOLUSD"/>
                        <div class="img-content">
                            <img src="" alt="">
                            <span class="pct"
                                  :class="{
                                        'theme_3-red': symbols['SOLUSD']?.quote && symbols['SOLUSD']?.quote.changePct < 0,
                                        'theme_3-green': symbols['SOLUSD']?.quote && 0 < symbols['SOLUSD']?.quote.changePct,
                                     }">
                                {{
                                    (symbols['SOLUSD']?.quote && symbols['SOLUSD']?.quote.changePct > 0 ? '+' : '') + (symbols['SOLUSD']?.quote && symbols['SOLUSD']?.quote.changePct ? symbols['SOLUSD']?.quote.changePct : 0).toFixed(2) + '%'
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="stat bnb">
                    <div class="top">
                        <div class="symbol symbol-BNBUSD"></div>
                        <div class="currency-info">
                            <span>Binance</span>
                            <div class="price">USD {{
                                    symbols['BNBUSD']?.quote.ask.toFixed(symbols['BNBUSD'].precision)
                                }}
                            </div>
                        </div>
                        <span>BNB</span>
                    </div>
                    <div class="bottom">
                        <highcharts :options="chartBNBUSD" ref="chartBNBUSD"/>
                        <div class="img-content">
                            <img src="" alt="">
                            <span class="pct"
                                  :class="{
                                        'theme_3-red': symbols['BNBUSD']?.quote && symbols['BNBUSD']?.quote.changePct < 0,
                                        'theme_3-green': symbols['BNBUSD']?.quote && 0 < symbols['BNBUSD']?.quote.changePct,
                                     }">
                                {{
                                    (symbols['BNBUSD']?.quote && symbols['BNBUSD']?.quote.changePct > 0 ? '+' : '') + (symbols['BNBUSD']?.quote && symbols['BNBUSD']?.quote.changePct ? symbols['BNBUSD']?.quote.changePct : 0).toFixed(2) + '%'
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart-line">
                <div class="chart-line-head">
                    <div class="time">
                        <button class="time-item fs-small" :class="{active: timeframe === 'Week'}"
                                @click="changeTimeframe('Week',7)">{{ tokens.fullScreenDashboard.week }}
                        </button>
                        <button class="time-item fs-small" :class="{active: timeframe === 'Month'}"
                                @click="changeTimeframe('Month',30)">{{ tokens.fullScreenDashboard.month }}
                        </button>
                    </div>
                </div>
                <div style="display: grid; height: 100%"> <!-- hack for highchart height -->
                    <highcharts :options="chartOptionsLine" ref="lineChart"/>
                </div>
            </div>
            <div class="accounts">
                <div class="account" v-for="account in accounts ? accounts : ''"
                     :key="account.id"
                     :class="{
                        active: account && activeAccount.id === account.id,
                        real: account && !account.demo,
                        demo: account && account.demo
                    }">
                    <div class="head">
                        <img :src="staticBase+`currencies/${account.currency}.png`" alt="">
                        <h3>{{ account.currency }} {{ tokens.fullScreenDashboard.account }}</h3>
                    </div>
                    <div class="body">
                        <div class="info">
                            <h4>{{ tokens.fullScreenDashboard.balance }}</h4>
                            <p class="value"
                               :class="account.balance < 0 ? 'red-gradient' : (account.balance >= 0 ? 'green-gradient' : '')">
                                {{
                                    currencySign(account.currency) + ' ' + (account.balance > 0 ? account.balance : 0).toLocaleString(undefined, {
                                        minimumFractionDigits: account.precision,
                                        maximumFractionDigits: account.precision
                                    })
                                }}</p>
                            <p class="theme_3-green">
                                ${{
                                    getUsd(account.balance, account.currency).toLocaleString(undefined, {
                                        minimumFractionDigits: account.precision,
                                        maximumFractionDigits: account.precision
                                    })
                                }}
                            </p>
                        </div>
                        <div class="info" v-show="parseInt(showLeverage) === 1">
                            <h4>{{ tokens.fullScreenDashboard.leverage }}</h4>
                            <p class="value"
                               :class="account.leverage < 0 ? 'red-gradient' : (account.leverage >= 0 ? 'green-gradient' : '')">
                                {{ '1:' + account.leverage }}</p>
                        </div>
                        <div class="info">
                            <h4>{{ tokens.fullScreenDashboard.credit }}</h4>
                            <p class="value"
                               :class="account.credit < 0 ? 'red-gradient' : (account.credit >= 0 ? 'green-gradient' : '')">
                                {{
                                    currencySign(account.currency) + ' ' + (account.credit).toLocaleString(undefined, {
                                        minimumFractionDigits: account.precision,
                                        maximumFractionDigits: account.precision
                                    })
                                }}</p>
                        </div>
                    </div>
                    <div class="footer">
                        <button class="fs" @click="makeActive(account.id)">{{
                                tokens.fullScreenDashboard.tradeNow
                            }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="more-info">
                <div class="other-total-balance">
                    <img :src="staticBase+`new-design/total-balance.svg`">
                    <div class="values">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalBalance }}</h4>
                        <p class="value converted-currency">
                            {{
                                activeCurrency && totalBalance >= 0 ? currencySign(activeCurrency) + totalBalance.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) : `${currencySign('USD')} 0`
                            }}</p>
                        <p class="value-usd"
                           :class="getUsd(totalBalance) < 0 ? 'theme_3-red' : getUsd(totalBalance) > 0 ? 'theme_3-green' : ''">
                            ${{
                                getUsd(totalBalance).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }}</p>
                    </div>
                </div>
                <div class="total-pnl">
                    <img :src="staticBase+`new-design/other-total-pnl.svg`">
                    <div class="values">
                        <h4 class="title">{{ tokens.fullScreenDashboard.totalPnl }}</h4>
                        <div class="box currency">
                            <img :src="staticBase+`currencies/${activeCurrency}.png`">
                            <p class="value"
                               :class="pnl < 0 ? 'theme_3-red' : pnl >= 0 ? 'theme_3-green' : ''">
                                {{
                                    activeCurrency ? pnl.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) : `0`
                                }}
                            </p>
                        </div>
                        <p class="value-usd"
                           :class="getUsd(pnl) < 0 ? 'theme_3-red' : getUsd(pnl) > 0 ? 'theme_3-green' : ''">
                            ${{
                                getUsd(pnl).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }}</p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign, isMobile, localStorage} from '@/common/helpers'
import {PLATES_FOLDER, BRAND, SHOW_LEVERAGE, THEME_3_DESIGN, HIDE_TOTAL_DEPOSITS, ROI_ON_DASHBOARD, SHOW_TOTAL_WITHDRAWALS} from "@/common/config"
import {ACCOUNT_ACTIVE, ACTIVE_CURRENCY, MODAL} from "@/store/mutations.type"
import {DASHBOARD_BALANCE, GET_HISTORY_ASSET} from "@/store/actions.type"

export default {
    name: 'Dashboard',
    mounted() {
        let dashboardBalance = this.dashboardData.balance_chart
        let data = []
        for (let i = 0; i < dashboardBalance.length; i++) {
            data[i] = dashboardBalance[i][1]
        }
        this.chartOptionsLine.series[0].data = data
        this.foundMinMax()
        this.dashboardChartInit()
        if (this.theme_3_Design) {
            this.setAssetHistory()
            this.$refs.theme_3PieChart?.chart?.reflow()
            this.initTheme_3PieChart()
        }

        this.chartOptionsPie.legend.itemStyle.color = this.brand === 'coinxe' ? '#000000' : '#ffffff'

        if (this.theme_3_Design) {
            this.chartOptionsLine.plotOptions.series = {
                fillColor: {
                    linearGradient: [0, 0, 50, 300],
                    stops: [
                        [0, '#C28066'],
                        [1, 'rgba(227,202,116,0.7)']
                    ]
                },
            }
            this.chartOptionsLine.series[0].marker.fillColor = '#C28066'
        }
    },
    data() {
        return {
            chartData: {
                labels: ['January', 'February', 'March'],
                datasets: [{data: [40, 20, 12]}]
            },
            chartOptions: {
                responsive: true
            },
            isMobile: isMobile(),
            brand: BRAND,
            platesFolder: PLATES_FOLDER,
            showLeverage: SHOW_LEVERAGE,
            time: 7,
            timeframeData: [],
            timeframe: 'Week',
            balanceChart: [],
            allTime: [],
            chartBTCUSD: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    height: 50,
                    maxWidth: 300,
                    type: 'areaspline',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        fillColor: {
                            linearGradient: [0, 0, 50, 300],
                            stops: [
                                [0, 'rgba(188, 255, 233, 0.4)'],
                                [1, 'rgba(0,0,0,0)']
                            ]
                        },

                    }
                },
                legend: false,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                enableMouseTracking: true,
                tooltip: {
                    enabled: false
                },
                series: [{
                    name: '60 min',
                    data: [],
                    marker: {
                        enable: false,
                        fillColor: 'transparent',
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                }]
            },
            chartTRXUSD: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    height: 50,
                    maxWidth: 300,
                    type: 'areaspline',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        fillColor: {
                            linearGradient: [0, 0, 50, 300],
                            stops: [
                                [0, 'rgba(188, 255, 233, 0.4)'],
                                [1, 'rgba(0,0,0,0)']
                            ]
                        },

                    }
                },
                legend: false,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                enableMouseTracking: true,
                tooltip: {
                    enabled: false
                },
                series: [{
                    name: '60 min',
                    data: [],
                    marker: {
                        enable: false,
                        fillColor: 'transparent',
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                }]
            },
            chartSOLUSD: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    height: 50,
                    maxWidth: 300,
                    type: 'areaspline',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        fillColor: {
                            linearGradient: [0, 0, 50, 300],
                            stops: [
                                [0, 'rgba(188, 255, 233, 0.4)'],
                                [1, 'rgba(0,0,0,0)']
                            ]
                        },

                    }
                },
                legend: false,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                enableMouseTracking: true,
                tooltip: {
                    enabled: false
                },
                series: [{
                    name: '60 min',
                    data: [],
                    marker: {
                        enable: false,
                        fillColor: 'transparent',
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                }]
            },
            chartBNBUSD: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    height: 50,
                    maxWidth: 300,
                    type: 'areaspline',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        fillColor: {
                            linearGradient: [0, 0, 50, 300],
                            stops: [
                                [0, 'rgba(188, 255, 233, 0.4)'],
                                [1, 'rgba(0,0,0,0)']
                            ]
                        },

                    }
                },
                legend: false,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                enableMouseTracking: true,
                tooltip: {
                    enabled: false
                },
                series: [{
                    name: '60 min',
                    data: [],
                    marker: {
                        enable: false,
                        fillColor: 'transparent',
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                }]
            },
            theme_3PieChart: {
                credits: false,
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    backgroundColor: 'transparent',
                    height: 250,
                },
                title: {
                    text: 'adsad',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: -15,
                    style: {"color": "#ffffff", "fontSize": window.innerWidth > 1400 ? "1vw" : "16px"}
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    style: {
                        fontSize: window.innerWidth > 1400 ? ".8vw" : "12px",
                    }
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        borderWidth: 0,
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        },
                    }
                },
                legend: {
                    enabled: true,
                    itemStyle: {"fontWeight": "normal", "fontSize": window.innerWidth > 1400 ? ".7vw" : "12px"},
                    layout: 'vertical',
                    itemMarginTop: 5,
                },
                series: [{
                    name: 'Orders',
                    colorByPoint: true,
                    innerSize: '85%',
                    showInLegend: true,
                    data: [
                        {
                            name: '',
                            y: 1,
                            color: '#F7931A',

                        },
                        {
                            name: '',
                            y: 1,
                            color: '#ffffff',
                        },
                        {
                            name: '',
                            y: 1,
                            color: '#30E0A1',

                        },
                    ]
                }]
            },
            chartOptionsLine: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    type: 'areaspline',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        fillColor: {
                            linearGradient: [0, 0, 50, 300],
                            stops: [
                                [0, '#1d98f9'],
                                [1, 'rgba(24,31,45,0.5)']
                            ]
                        },

                    }
                },
                legend: true,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                    categories: []
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                // innerSize: '60%',
                enableMouseTracking: true,

                tooltip: {
                    headerFormat: '{point.x}<br>',
                    pointFormat: '<strong>$ {point.y}',
                    shared: true,
                    style: {
                        fontSize: window.innerWidth > 1400 ? ".8vw" : "12px",
                    }
                },
                series: [{
                    name: '$',
                    data: [],
                    marker: {
                        enable: false,
                        fillColor: '#91BDFF',
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    },
                }]
            },
            chartOptionsPie: {
                credits: false,
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    backgroundColor: 'transparent',
                },
                title: {
                    text: 'adsad',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 0,
                    style: {"color": "#92B8F1", "fontSize": window.innerWidth > 1400 ? "1vw" : "12px"}
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    style: {
                        fontSize: window.innerWidth > 1400 ? ".8vw" : "12px",
                    }
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        borderWidth: 0,
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        }
                    }
                },
                legend: {
                    enabled: true,
                    itemStyle: {"fontWeight": "normal", "fontSize": window.innerWidth > 1400 ? ".7vw" : "12px"}
                },
                series: [{
                    name: 'Orders',
                    colorByPoint: true,
                    innerSize: '60%',
                    showInLegend: true,

                    data: [
                        {
                            name: '',
                            y: 1,
                            color: {
                                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                                stops: [
                                    [0, '#DDE120'], // start
                                    [1, '#338C27'] // end
                                ]
                            },
                        },
                        {
                            name: '',
                            y: 1,
                            color: {
                                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                                stops: [
                                    [0, '#B41717'], // start
                                    [1, '#2A6DBC'] // end
                                ]
                            },

                        }
                    ]
                }]
            },
            theme_3_Design: THEME_3_DESIGN,
            hideTotalDeposits : HIDE_TOTAL_DEPOSITS,
            roiOnDashboard: ROI_ON_DASHBOARD,
            showTotalWithdrawals: SHOW_TOTAL_WITHDRAWALS,
        }
    },
    methods: {
        currencySign,
        close() {
            this.$store.commit(MODAL, {
                fullscreen: false
            })
        },
        changeTimeframe(timeframe, time) {
            if (this.time !== time) {
                this.time = time
                this.timeframe = timeframe
                this.$store.dispatch(DASHBOARD_BALANCE, this.time * 24)

                this.chartOptionsLine.series[0].data = this.getTimeframeData
                this.foundMinMax()
            }
        },
        foundMinMax() {
            this.chartOptionsLine.yAxis.min = Math.min.apply(null, this.chartOptionsLine.series[0].data)
            this.chartOptionsLine.yAxis.max = Math.max.apply(null, this.chartOptionsLine.series[0].data)
        },
        makeActive(id) {
            this.$store.commit(MODAL, {
                fullscreen: false
            })
            localStorage.setItem('activeAccount', id)
            this.$store.commit(ACCOUNT_ACTIVE, id)
        },
        dashboardChartInit() {
            this.chartOptionsPie.series[0].data[0].y = this.dashboardData.stats.cnt_profit ? parseInt(this.dashboardData.stats.cnt_profit) : 0
            this.chartOptionsPie.series[0].data[1].y = this.cntTotal ? ((this.cntTotal - this.dashboardData.stats.cnt_profit) === 0) ? 0 : ((this.cntTotal) - (this.dashboardData.stats.cnt_profit)) : 1
            let result = (this.chartOptionsPie.series[0].data[0].y / this.cntTotal === 0) ? 0 : (this.chartOptionsPie.series[0].data[0].y / this.cntTotal) * 100

            this.chartOptionsPie.title.text = isNaN(result) ? "0%" : result.toFixed(1) + "%"
            this.chartOptionsLine.xAxis.categories = this.getTimeData

            this.chartOptionsPie.series[0].data[0].name = this.tokens.fullScreenDashboard.closedWithProfit
            this.chartOptionsPie.series[0].data[1].name = this.tokens.fullScreenDashboard.closedWithLoss

            this.chartOptionsPie.legend.itemStyle.color = '#235fb7'
        },
        initTheme_3PieChart() {
            let pending = 0
            for (const item in this.orders) {
                if (this.orders[item].type === 2 || this.orders[item].type === 5) {
                    pending++
                }
            }

            this.theme_3PieChart.series[0].data[0].y = pending
            this.theme_3PieChart.series[0].data[1].y = this.cntTotal
                ? (this.cntTotal - this.dashboardData.stats.cnt_profit) === 0
                    ? 0
                    : this.cntTotal - this.dashboardData.stats.cnt_profit
                : 1
            this.theme_3PieChart.series[0].data[2].y = this.dashboardData.stats.cnt_profit
                ? parseInt(this.dashboardData.stats.cnt_profit)
                : 0

            let result =
                this.cntTotal === 0
                    ? 0
                    : (this.theme_3PieChart.series[0].data[2].y / this.cntTotal) * 100
            result /= pending === 0 ? 1 : 0
            this.theme_3PieChart.title.text = isNaN(result) ? "0%" : result.toFixed(1) + "%"
            this.chartOptionsLine.xAxis.categories = this.getTimeData

            this.theme_3PieChart.series[0].data[0].name = this.tokens.widgetOrders.pending
            this.theme_3PieChart.series[0].data[1].name = this.tokens.fullScreenDashboard.noOrderYet
            this.theme_3PieChart.series[0].data[2].name = this.tokens.fullScreenDashboard.closedWithProfit

            this.theme_3PieChart.legend.itemStyle.color = this.lightTheme ? '#000' : '#fff'
            this.theme_3PieChart.title.style.color = this.lightTheme ? '#000' : '#fff'


            if(this.cntTotal !== 0 && this.theme_3PieChart.series[0].data[0].y === 0 || this.theme_3PieChart.series[0].data[2].y !== 0) {
                this.theme_3PieChart.series[0].data[1].color = '#B41717'
                this.theme_3PieChart.series[0].data[1].name = this.tokens.fullScreenDashboard.closedWithLoss
            }
        },
        isOneAccountCurrency(accounts, accountsValue) {
            if (accounts.length !== accountsValue.length) {
                return false
            }

            return accounts.every(account => account === this.activeCurrency)
        },
        getBalanceUsd() {
            return this.user.balance_total || 0
        },
        convToActiveDashCurrency(value) {
            if (this.activeCurrency !== 'USD') {
                return value / this.conversions[this.activeCurrency]
            }
            return value
        },
        convertToUSD(value, accountsCurrency, accountValues) {
            value = 0
            let acc

            for (let i = 0; i < accountValues.length; i++) {
                acc = 1

                if (accountsCurrency[i] !== 'USD') {
                    acc = this.conversions[accountsCurrency[i]]
                    value += acc * accountValues[i]
                } else {
                    acc = this.conversions[accountsCurrency[i]]
                    value += accountValues[i] / acc
                }
            }
            return value
        },
        getUsd(value, currency) {
            if (!currency) {
                currency = this.activeCurrency
            }

            return value * this.conversions[currency]?.toFixed(4)
        },
        setAssetHistory() {
            for (const item in this.historyAssets) {
                let data = []

                for (let j = 0; j < this.historyAssets[item].length; j++) {
                    if (this.historyAssets[item][j] === undefined) {
                        break
                    }

                    data[j] = this.historyAssets[item][j][3]
                }

                this.$set(this[`chart${item}`].series[0], 'data', data)
                this.$set(this[`chart${item}`].yAxis, 'min', Math.min.apply(null, data))
                this.$set(this[`chart${item}`].yAxis, 'max', Math.max.apply(null, data))
                this.$refs[`chart${item}`]?.chart?.reflow()
            }
        }
    },
    computed: {
        ...mapGetters(['conversions', 'user', 'tokens', 'modals', 'activeAccount', 'accounts', 'dashboardData', 'symbols', 'orders', 'activeCurrency', 'symbols', 'historyAssets', 'orders', 'stakingAccounts', 'staticBase', 'lightTheme']),
        plate() {
            let plate = []
            for (const account in this.accounts) {
                let metal = ['beginner', 'student', 'basic', 'bronze', 'bronze_plus', 'silver', 'silver_plus', 'gold', 'platinum', 'platinum_plus', 'vip', 'top', 'diamond','islamic','business','enterprise','regular','arbitrage','basicml','shift','custom','professional','brilliant','diamonds','individual','longterm','royalclub','cx','cx_2','cx_3'].includes(this.activeAccount.group) ? this.activeAccount.group : 'main'
                plate[this.accounts[account].id] = this.staticBase + this.platesFolder + '/' + metal + '_plate.png'
            }
            return plate
        },
        getTimeframeData() {

            let dashboardBalance = this.dashboardData.balance_chart
            let data = []
            for (let i = 0; i < dashboardBalance.length; i++) {
                data[i] = dashboardBalance[i][1]
            }
            return data
        },
        getTimeData() {
            let dashboardBalance = this.dashboardData.balance_chart
            let timeData = []
            for (let i = 0; i < dashboardBalance.length; i++) {
                timeData[i] = new Date(dashboardBalance[i][0] - 1000 * 60 * 60).toLocaleString()
            }
            return timeData
        },
        totalBalance() {
            if (!Object.keys(this.dashboardData.stats).length && !Object.keys(this.dashboardData.stats.accounts).length) {
                return 0
            }

            if (this.activeCurrency === 'USD') return this.user.balance_total;

            let accBalance = [].concat(
                ...Object.values(this.accounts).map(acc => acc.balance),
                ...Object.values(this.stakingAccounts).map(acc => acc.balance)
            )
            let accountsCur = [].concat(
                ...Object.values(this.accounts).map(acc => acc.currency),
                ...Object.values(this.stakingAccounts).map(acc => acc.currency)
            )
            let balanceTotal = accBalance.reduce((total, balance) => total + balance, 0)

            if (this.isOneAccountCurrency(accountsCur, accBalance) && this.activeCurrency === accountsCur[0]) {
                return balanceTotal
            } else {
                balanceTotal = this.convertToUSD(balanceTotal, accountsCur, accBalance)
                return this.convToActiveDashCurrency(balanceTotal)
            }
        },
        totalDeposits() {
            if (this.activeCurrency !== 'USD') {
                return this.convToActiveDashCurrency(this.dashboardData.stats.deposits)
            }
            return this.dashboardData.stats.deposits
        },
        totalWithdrawals(){
            if (this.activeCurrency !== 'USD') {
                return this.convToActiveDashCurrency(this.dashboardData.stats.total_withdrawals)
            }
            return this.dashboardData.stats.total_withdrawals
        },
        pnl() {
            let pnl = []
            let accountsCur = []
            let accountId = Object.values(this.accounts).map(acc => acc.id)
            let dashAccId = []
            let pnlTotal = 0

            if (this.dashboardData.stats && this.dashboardData.stats.accounts) {
                dashAccId = Object.values(this.dashboardData.stats.accounts).map(acc => acc.account_id)
            }

            for (let i = 0; i < accountId.length; i++) {
                const index = dashAccId.indexOf(accountId[i])
                if (index !== -1) {
                    pnl.push(this.dashboardData.stats.accounts[index].profit)
                    accountsCur.push(this.accounts[accountId[i]].currency)
                }
            }

            if (this.isOneAccountCurrency(accountsCur, pnl)) {
                return this.dashboardData.stats.profit
            } else if (!this.symbols[this.activeCurrency + 'USD'] && !this.symbols['USD' + this.activeCurrency] && this.activeCurrency !== 'USD') {
                return 0
            } else {
                pnlTotal = this.convertToUSD(pnlTotal, accountsCur, pnl)
                return this.convToActiveDashCurrency(pnlTotal)
            }
        },
        amount() {

            let amountTotal = 0
            let amount = []
            let accountsCur = []
            let accountId = Object.values(this.accounts).map(acc => acc.id)
            let dashAccId = []

            if (this.dashboardData.stats && this.dashboardData.stats.accounts) {
                dashAccId = Object.values(this.dashboardData.stats.accounts).map(acc => acc.account_id)
            }

            for (let i = 0; i < accountId.length; i++) {
                const index = dashAccId.indexOf(accountId[i])
                if (index !== -1) {
                    amount.push(this.dashboardData.stats.accounts[index].amount)
                    accountsCur.push(this.accounts[accountId[i]].currency)
                }
            }

            if (this.isOneAccountCurrency(accountsCur, amount)) {
                return amountTotal
            } else if (!this.symbols[this.activeCurrency + 'USD'] && !this.symbols['USD' + this.activeCurrency] && this.activeCurrency !== 'USD') {
                return 0
            } else {
                amountTotal = this.convertToUSD(amountTotal, accountsCur, amount)
                return this.convToActiveDashCurrency(amountTotal)
            }
        },
        cntTotal() {
            let cntTotal = 0

            for (const cntStat in this.dashboardData.stats.accounts) {
                cntTotal += this.dashboardData.stats.accounts[cntStat].cnt_total
            }

            return cntTotal
        },
    },
    watch: {
        modals: {
            deep: true,
            handler() {
                if (this.modals.fullscreen) {
                    this.$refs.lineChart?.chart?.reflow();
                    this.$refs.pieChart?.chart?.reflow();
                    if (this.theme_3_Design) {
                        this.$store.dispatch(GET_HISTORY_ASSET, ['BTCUSD', 'TRXUSD', 'SOLUSD', 'BNBUSD'])
                        this.$refs.theme_3PieChart?.chart?.reflow();
                        this.initTheme_3PieChart()
                    }
                }
            }
        },
        historyAssets: {
            deep: true,
            handler() {
                this.setAssetHistory()
            }
        },
        dashboardData: {
            deep: true,
            handler() {
                let dashboardBalance = this.dashboardData.balance_chart
                let data = []
                for (let i = 0; i < dashboardBalance.length; i++) {
                    data[i] = dashboardBalance[i][1]
                }

                this.chartOptionsLine.series[0].data = data
                this.foundMinMax()
                this.dashboardChartInit()
            }
        },
        activeAccount: {
            handler() {
                if (this.activeAccount !== undefined) {
                    this.$store.commit(ACTIVE_CURRENCY, localStorage.activeCurrency)

                }
            }
        },
        lightTheme() {
            this.initTheme_3PieChart()
            this.theme_3PieChart.legend.itemStyle.color = this.lightTheme ? '#000' : '#fff'
            this.theme_3PieChart.title.style.color = this.lightTheme ? '#000' : '#fff';
        },
        tokens() {
            this.initTheme_3PieChart()
        },
    }
}
</script>

<style scoped>
.dashboard {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto auto 300px auto;
    gap: 30px;
    align-items: stretch;
}

.dashboard > * {
    border-radius: 10px;
    padding: 20px;
    background: var(--background-dark-mid);
    overflow: hidden;
}

.dashboard .info__items-container,
.theme_3-design .info__items-container{
    grid-column: 1/5;
    grid-row: 1/3;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    background: transparent;
    padding: 0 !important;
    gap: 30px;
    max-height: 550px;
}

.info__items-container > .info__item {
    border-radius: 10px;
    padding: 20px 15px 35px;
    background: var(--background-dark-mid);
    overflow: hidden;
    grid-column: span 1;
    grid-row: span 1;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.info__items-container > :nth-child(1):nth-last-child(3) {
  grid-column: span 2;
}

.info__item .left {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
}

.info__item .left img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.info__item .right {
    text-align: center;
}

.info__item.total-pnl {
    position: relative;
}

.info__item.total-pnl .right span {
    position: absolute;
    bottom: 5px;
    right: 50%;
    width: 100%;
    font-weight: 300;
    transform: translateX(50%);
}

.info__item .title {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: var(--white-to-black);
    margin-bottom: 10px;
    white-space: nowrap;
}

.info__item .value {
    font-weight: 500;
    font-size: 30px;
    color: var(--white-to-black);
    white-space: nowrap;
}

.info__item .roi-value {
    background: linear-gradient(180deg, #E3FF72 0%, #3FBC2A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

.dashboard .chart-pie {
    grid-column: 5/9;
    grid-row: 1/3;
    max-height: 550px;
}

.fullscreen.mobile .chart-pie {
    display: flex;
}

.dashboard .chart-line h4,
.dashboard .chart-pie h4 {
    font-weight: 400;
    font-size: 24px;
    color: var(--white-to-black);
}

.dashboard .chart-line {
    grid-column: 1/9;
    grid-row: 3/4;
}

.chart-line .chart-line-head {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-items: center stretch;
    place-content: space-between;
}

.chart-line .time {
    display: flex;
}

.chart-line .time-item {
    margin-right: 8px;
    padding: 3px 10px;
    transition: all .3s ease-in-out;
    cursor: pointer;
    font-size: 14px;
    color: var(--white-to-black);
    font-weight: 500;
    border: .5px solid transparent;
}

.chart-line .time-item.active {
    background-color: #2B834E;
    border: .1vw solid var(--fs-border);
}

.chart-line .time-item.active h5 {
    font-weight: 400;
}

.chart-line .time-item:last-child {
    margin-right: 0;
}

.dashboard .account {
    grid-column: span 2;
}

.dashboard .account.active {
    -webkit-box-shadow: 4px 4px 19px 11px rgba(43, 131, 78, 0.35);
    -moz-box-shadow: 4px 4px 19px 11px rgba(43, 131, 78, 0.35);
    box-shadow: 4px 4px 19px 11px rgba(43, 131, 78, 0.35);
}

.account .sign {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0F111D;
    font-weight: bold;
    font-size: 1.4em;
    margin-right: 15px;
}

.account.real .balance {
    background-color: #C4C4C4;
}

.account .balances {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(2, 25px);
}

.account .balances img {
    width: 24px;
    height: 24px;
}


.account .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.account .head h3 {
    font-weight: 600;
    font-size: 20px;
    color: var(--white-to-black);
}

.account .body .info {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 15px;
    margin-bottom: 14px;
}

.account .info h4 {
    font-weight: 300;
    font-size: 18px;
    color: var(--white-to-black);
}

.account .info .value {
    font-weight: 400;
    font-size: 18px;
    text-align: right;
}

.account .green-gradient {
    background: linear-gradient(180deg, #E3FF72 0%, #3FBC2A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

.account .red-gradient {
    background: linear-gradient(180deg, #ff7282 0%, #bc2a36 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

.account .footer button {
    display: block;
    padding: 10px 0;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    color: var(--white);
    text-align: center;
    text-decoration: none;
}

.account .footer span.active {
    font-size: 16px;
    display: block;
    text-align: center;
    color: rgb(43, 131, 78);
}

.theme_3-design .info__item {
    align-items: flex-start;
    gap: 0;
    background: transparent;
}

.theme_3-design .info__item:nth-child(odd){
    padding-right: 30px;
    border-right: 1px solid #34384C;
    border-radius: 0;
}

.theme_3-design .title {
    color: var(--white-to-black);
}

.theme_3-design .chart-line {
    border-radius: 20px !important;
}

.theme_3-design .time-item {
    padding: 3px 15px;
    background: transparent;
    border-radius: 50px;
}

.theme_3-design .time-item.active {
    background: #141518;
}

body.light-theme .theme_3-design .time-item.active {
    background: var(--theme_3-background);
}

@media (min-width: 1000px) {
    .dashboard {
        grid-template-rows: auto auto 15vw auto;
        gap: 1.5vw;
    }

    .dashboard > * {
        border-radius: .5vw;
        padding: 1vh;
    }

    .dashboard .info__items-container{
        gap: 1.5vw;
    }

    .info__items-container > .info__item {
        padding: 1vw .5vw;
    }

    .info__item .left {
        width: 2.8vw;
        height: 2.8vw;
    }

    .info__item .total-pnl .right span {
        bottom: .3vw;
        font-size: .7vw;
    }

    .info__item .title {
        font-size: .8vw;
        margin-bottom: .5vw;
    }

    .info__item .value {
        font-size: 1.5vw;
    }

    .chart-line .time-item {
        margin-right: .4vw;
        padding: .15vw .5vw;
        font-size: .7vw;
        border: .1vw solid transparent;
    }

    .chart-line .time-item .active {
        border: .1vw solid var(--fs-border);
    }

    .dashboard .chart-line h4,
    .dashboard .chart-pie h4 {
        font-size: 1vw;
    }

    .dashboard .account .active {
        -webkit-box-shadow: .2vw .2vw .9vw .5vw rgba(43, 131, 78, 0.35);
        -moz-box-shadow: .2vw .2vw .9vw .5vw rgba(43, 131, 78, 0.35);
        box-shadow: .2vw .2vw .9vw .5vw rgba(43, 131, 78, 0.35);
    }

    .account .balances img {
        width: 1.25vw;
        height: 1.25vw;
    }

    .account .sign {
        width: 1.3vw;
        height: 1.3vw;
        font-size: 1.3vw;
        margin-right: .7vw;
    }

    .account .balances {
        gap: .3vw;
        grid-template-columns: repeat(2, 1.3vw);
    }

    .account .balances img {
        width: 1.25vw;
        height: 1.25vw;
    }

    .account .head {
        margin-bottom: .4vw;
    }

    .account .head h3 {
        font-size: 1vw;
    }

    .account .body .info {
        gap: .7vw;
        margin-bottom: .7vw;
    }

    .account .info h4 {
        font-size: .9vw;
    }

    .account .info .value {
        font-size: .9vw;
    }

    .account .footer button {
        padding: .5vw 0;
        font-size: .9vw;
    }

    .account .footer span.active {
        font-size: .8vw;
    }

    .theme_3-design .time-item {
        padding: .15vw 1.5vw;
        font-size: 1.5vw;
        border-radius: 5vw;
    }
}

@media (max-width: 1400px) {
    .dashboard {
        grid-template-rows: auto auto 250px auto;
    }

    .info__items-container > .info__item {
        grid-template-columns: repeat(2, auto);
    }

    .info__item .value {
        font-size: 22px;
    }

    .info__item .left {
        width: 40px;
        height: 40px;
    }
}

/* ================================ mobile */
.dashboard.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    gap: 20px;
}

.dashboard.mobile > * {
    grid-column: 1/2;
    grid-row: span 1;
}

.dashboard.mobile h4 {
    font-size: 18px;
}

.dashboard.mobile .chart-line,
.dashboard.mobile .chart-pie {
    height: 250px;
}


.theme_3-design .main-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: var(--theme_3-background-third);
    border-radius: 23px;
    padding: 30px;
    grid-gap: 20px;
}

.theme_3-design .box {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 10px;
}

.theme_3-design .account.active {
    border: 1px solid #11CABE;
}

.theme_3-design .account-info {
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    gap: 25px;
    grid-column: auto;
    grid-row: auto;
    color: var(--white-to-black)
}

.theme_3-design .account-info img {
    object-fit: contain;
    width: 36px;
}

.theme_3-design .total-balance img,
.theme_3-design .roi img {
    width: 28px;
}

.theme_3-design .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.theme_3-design .profitable-orders h4,
.theme_3-design .total-pnl h4 {
    color: var(--whitesmoke-to-lightgrey);
    margin-bottom: 15px;
    font-size: 18px;
}

.theme_3-design .profitable-orders .box,
.theme_3-design .total-pnl .box {
    align-items: center;
    margin-bottom: 10px;
}

.theme_3-design .box:last-child {
    margin: 0;
}

.theme_3-design .profitable-orders h4,
.theme_3-design .total-pnl h4 {
    margin: 0;
}

.theme_3-design .profitable-orders p {
    padding-left: 40px;
    font-size: 27px;
    font-weight: 400;
}

.theme_3-design .converted-currency {
    font-weight: 600;
    font-size: 27px;
}

.theme_3-design .total-pnl .currency img {
    width: 22px;
}

.theme_3-design .total-pnl .currency {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}

.theme_3-design .value-usd {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
}

.theme_3-design .chart {
    padding-left: 50px;
    border-left: 1px solid #34384C;
}

.assets-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 25px;
    place-items: center;
    margin-top: 50px;
}

.stat {
    padding: 25px;
    background: #1E1F25;
    backdrop-filter: blur(2px);
    border-radius: 12px;
    width: 100%;
}

.theme_3-design .stat {
    background: var(--theme_3-background-third);
}

.stat.btc {
    border-left: 2px solid #f8921a;
}

.stat.trx {
    border-left: 2px solid #d03329;
}

.stat.sol {
    border-left: 2px solid #5583cc;
}

.stat.bnb {
    border-left: 2px solid #ffbd0b;
}

.stat .top {
    display: grid;
    grid-template-columns: repeat(2, max-content) 1fr;
    gap: 10px;
    margin-bottom: 20px;
}

.stat .top span {
    font-size: 16px;
    color: #A5ADCF;
    font-weight: 400;
    text-align: right;
}

.stat .top img {
    width: 45px;
    height: 45px;
    object-fit: cover;
}

.stat .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: var(--white-to-black);
}

.stat .bottom {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.stat .img-content {
    font-weight: 600;
    font-size: 18px;
}

.theme_3-design .accounts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px 40px;
}

.theme_3-design .account {
    background: var(--theme_3-background-third);
    border-radius: 12px;
    padding: 25px 20px;
}

.theme_3-design .account .head {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 10px;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
    color: var(--white-to-black);
}

.theme_3-design .account .head img {
    width: 24px;
    height: 24px;
}

.theme_3-design .account .body h4 {
    font-weight: 400;
    font-size: 16px;
    color: var(--whitesmoke-to-lightgrey);
}

.theme_3-design .account .body p {
    font-weight: 400;
    font-size: 20px;
    color: var(--white-to-black);
}

.theme_3-design .account .info {
    margin-bottom: 10px;
}

.theme_3-design .account .info p:last-child {
    font-size: 12px;
}

.theme_3-design .footer button {
    height: 50px;
    background: linear-gradient(265.56deg, #30E0A1 -0.27%, #03A443 98.59%);
    font-weight: 600;
}

.theme_3-design .chart-line {
    background: var(--theme_3-background-third);
    border-radius: 2px;
    padding: 15px;
    margin: 15px 0;
}

.theme_3-design .more-info {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 40px;
    margin-top: 20px;
}

.more-info .other-total-balance,
.more-info .total-pnl {
    background: #1E1F25;
    mix-blend-mode: normal;
    border-radius: 12px;
    display: grid;
    place-content: center center;
    color: var(--white-to-black);
    padding: 40px;
    grid-template-columns: repeat(2, max-content);
    gap: 15px;
}

.theme_3-design .more-info .total-pnl,
.theme_3-design .more-info .other-total-balance {
    background: var(--theme_3-background-third);
    color: var(--whitesmoke-to-lightgrey);
}

.more-info .total-pnl h4 {
    color: var(--white-to-black);
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
}

.theme_3-pie-chart {
    position: relative;
}

.theme_3-pie-chart::after {
    content: '';
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 10px dashed rgba(47, 50, 65, 0.5);
    background: rgba(47, 50, 65, 0.5);
    display: none;
}

.theme_3-design .symbol {
    transform: scale(.7);
}

@media (max-width: 1200px) {
    .theme_3-design .chart {
        padding-left: 20px;
    }

    .theme_3-design .main-info {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 999px) {
    .theme_3-design .account-info {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 0;
    }

    .info__item .value {
        font-size: 17px;
    }

    .theme_3-design .info__item {
        padding: 0 0 25px;
        overflow: visible;
    }
}

</style>
