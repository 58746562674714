<template>
    <div class="profile-settings">
        <div class="profile-settings-item">
            <div class="item-name label__largeprominent">{{tokens.newDesign.avatar}}</div>
            <div class="profile-settings-inner">
                <div class="profile-settings-avatar">
                    <BaseMonogram :monogramStyles="'monogram-square'"/>
                </div>
                <div class="profile-settings-info body__extrasmall">
                   {{tokens.newDesign.maxFileSize}}
                </div>
                <div class="profile-settings-buttons">
                    <!-- need to add possibility to delete photo -->
                    <UploadPhotoButton :text="tokens.newDesign.uploadPhoto" :type="'upload'"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import UploadPhotoButton from "@/new-design/features/Personal/UpdatePersonalAvatar/ui/UploadPhotoButton";
import {THEME_3_DESIGN, BRAND, REST_ENDPOINT, STATIC_BASE} from "@/common/config"
import BaseMonogram from "@/new-design/shared/ui/Icons/BaseMonogram.vue"

export default {
    name: "UpdatePersonalAvatar",
    components: {UploadPhotoButton, BaseMonogram},
    data() {
        return {
            apiBase: REST_ENDPOINT,
            brand: BRAND,
            theme_3_Design: THEME_3_DESIGN,
            staticBase: STATIC_BASE
        }
    },
    computed: {
        ...mapGetters(['user', 'tokens']),
    }
}
</script>

<style scoped>
.profile-settings-item {
    display: grid;
    grid-template-columns: minmax(auto, 100px) 1fr;
    padding: 16px 0;
    gap: 16px;

}

.profile-settings-inner {
    display: grid;
    grid-template-columns: 70px minmax(165px, 222px);
    grid-template-rows: 1fr 1fr;
    grid-gap: 15px;
}

.profile-settings-avatar {
    width: 70px;
    height: 70px;
    grid-row: 1/3;
}

.profile-settings-avatar img {
    max-width: 70px;
    max-height: 70px;
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: center;
}

.profile-settings-buttons {
    display: flex;
    gap: 10px;
}

@media (max-width: 768px) {
    .profile-settings-item {
        gap: 12px;
    }

    .profile-settings-inner {
        grid-gap: 12px;
    }
}
</style>
