<template>
    <div v-if="!hidePsiDssCertificate" class="certificate">
        <a href="https://usa.visa.com/support/small-business/security-compliance.html#1" target="_blank"><img
            class="visa" :src="`${staticBase}new-design/certificates/visa.svg`"/></a>
        <a href="https://www.mastercard.us/en-us/business/overview/safety-and-security/security-recommendations/site-data-protection-PCI.html"
           target="_blank"><img class="mastercard" :src="`${staticBase}new-design/certificates/master-card.svg`"/></a>
        <a href="https://stripe.com/en-ca/guides/pci-compliance" target="_blank"><img class="stripe"
                                                                                      :src="`${staticBase}new-design/certificates/stripe.svg`"/></a>
        <img @click="pciDssModal()" class="pcidss" :src="`${staticBase}new-design/certificates/pridss.svg`"/>
        <a v-if="['stage','skybitholdings'].includes(brand)" href="https://www.blockchain.com/" target="_blank">
            <img class="blockchain" :src="`${staticBase}new-design/certificates/blockchain.svg`"/>
        </a>
    </div>
</template>

<script>
import {HIDE_PCI_DSS_CERTIFICATE, STATIC_BASE,BRAND} from "@/common/config"
import {MODAL} from "@/store/mutations.type";

export default {
    name: "BaseCertificate",
    data() {
        return {
            hidePsiDssCertificate: HIDE_PCI_DSS_CERTIFICATE,
            staticBase: STATIC_BASE,
            brand: BRAND
        }
    },
    methods: {
        pciDssModal() {
            this.$store.commit(MODAL, {
                pciDssModal: true
            })
        },
    }
}
</script>

<style scoped>
.certificate {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100px);
    place-items: center center;
    place-content: center;
    grid-gap: 60px;
    margin: 24px 0 auto 0;
    padding: 32px 0;
}

.certificate>*:not(:last-child) {
    border-right: 1px solid var(--System-Surface5);
    padding: 0 30px;
}

.certificate img {
    cursor: pointer;
    width: 100px;
    height: 24px;
    object-fit: contain;
}

@media (max-width: 1000px) {
    .certificate {
        grid-gap: 30px;
    }
    .certificate a {
        padding: 0 15px;
    }
}

@media (max-width: 768px) {
    .certificate {
        grid-template-columns: repeat(4, 80px);
        grid-gap: 10px;
    }
    .certificate a {
        padding: 0;
    }
    .certificate img {
       width: 80px;
    }
}

</style>
