<template>
    <div>
        <div class="fullscreen-page-title">
            {{ tokens.fullScreen.verification.toUpperCase() }}
        </div>
        <div class="row form-row" v-if="!theme_3_Design">
            <div class="col"
                 v-for="(docData, i) in documentsData"
                 :key="'docData'+i"
            >
                <div class="document"
                     :class="{
                        over: docData.over && [0, 1].indexOf(docData.status) === -1
                     }"
                     :title="docData.title"
                     v-cloak
                     @drop.prevent="e => addFile(docData.type, e)"
                     @dragover.prevent
                     @dragenter="over(docData.type, true)"
                     @dragleave="over(docData.type, false)"
                     @dragover="over(docData.type, true)"
                >
                    <div class="document-inner">
                        <!--                        <i class="material-icons" :class="docData.cssClass">{{ docData.icon }}</i>-->
                        <div class="img-inner">
                            <!--                            <img v-show="docData.cssClass !== 'wait-fs' && docData.cssClass !== ''" class="state" :src="docData.cssClass === 'green-fs' ? 'image/accept.svg' : 'image/error.svg'" alt="">-->
                            <img class="icon" :class="docData.cssClass" :src="staticBase+`misc/${docData.icon}.svg`" alt="">
                        </div>
                        <div class="docname" :class="docData.cssClass">{{ docData.text }}</div>
                    </div>
                    <div class="tip" :class="{hide: [0, 1].indexOf(docData.status) !== -1}">
                        {{ tokens.fullScreenVerification.dragAndDrop }}
                    </div>
                    <input type="file" :title="docData.title" @change="documentSelected($event, docData.type)"
                           :class="{hide: [-1, 2].indexOf(docData.status) === -1}"/>
                </div>
            </div>
        </div>
        <div class="row form-row" v-else>
            <div class="col"
                 v-for="(docData, i) in documentsData"
                 :key="'docData'+i"
            >
                <div class="document"
                     :class="{
                        over: docData.over && [0, 1].indexOf(docData.status) === -1,
                        [docData.cssClass]: !!docData.cssClass
                     }"
                     :title="docData.title"
                     v-cloak
                     @drop.prevent="e => addFile(docData.type, e)"
                     @dragover.prevent
                     @dragenter="over(docData.type, true)"
                     @dragleave="over(docData.type, false)"
                     @dragover="over(docData.type, true)"
                >
                    <div class="document-inner">
                        <!--                        <i class="material-icons" :class="docData.cssClass">{{ docData.icon }}</i>-->
                        <div class="img-inner">
                            <!--                            <img v-show="docData.cssClass !== 'wait-fs' && docData.cssClass !== ''" class="state" :src="docData.cssClass === 'green-fs' ? 'image/accept.svg' : 'image/error.svg'" alt="">-->
                            <img class="icon" :class="docData.cssClass" :src="staticBase+`new-design/verification/${getVerificationState(docData)}.svg`" alt="">
                            <img v-if="docData.status !== 0 && docData.status !== 1  && theme_3_Design" class="plus" :src="staticBase+`new-design/verification/plus.svg`" alt="">
                            <img v-if="docData.status === 1 && theme_3_Design" class="confirmed" :src="staticBase+`new-design/verification/confirmed.svg`" alt="">
                            <img v-if="docData.status === 0 && theme_3_Design" class="review" :src="staticBase+`new-design/verification/review.svg`" alt="">
                        </div>
                        <div class="docname" :class="docData.cssClass">{{ docData.text }}</div>
                    </div>
                    <div class="tip" :class="{hide: [0, 1].indexOf(docData.status) !== -1}">
                        {{ tokens.fullScreenVerification.dragAndDrop }}
                    </div>
                    <input type="file" :title="docData.title" @change="documentSelected($event, docData.type)"
                           :class="{hide: [-1, 2].indexOf(docData.status) === -1}"/>
                </div>
            </div>
        </div>
        <div v-if="!disableDocUploadedLine" class="progress-bar">
            <div class="empty">
                <div class="filled" :style="{width: searchSuccessfully / documentsData.length * 100 + '%'}"></div>
<!--                <div class="progress-bullet" v-if="theme_3_Design">-->
<!--                    <span v-for="(item, index) in documentsData" :key="index">{{index}}</span>-->
<!--                </div>-->
            </div>
            <p>{{ progressBarState }}</p>
        </div>
        <div v-if="questions.length>0 && !disableQuestions" class="questions">
            <div class="row">
                <div class="form-row fs">
                    <div class="form-title">{{ tokens.fullScreenVerification.questionnaire.toUpperCase() }}</div>
                </div>
            </div>

            <div class="row">
                <div class="questions-item">
                    <div v-for="question in questions" :key="question.id">
                        <div class="form-row fs">
                            <label class="fs">{{ question.question }}</label>
                            <input type="text" class="form-control fs" v-model="question.answer">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row fs">
                <button class="fs-normal" v-on:click="sendAnswers">{{
                        tokens.fullScreenVerification.sendAnswers
                    }}
                </button>
            </div>
        </div>
        <div class="form-row fs" style="margin-top: 20px">
            <div class="form-title">{{ tokens.fullScreenVerification.documentsList }}</div>
        </div>
        <div class="table-cont">
            <table class="cabinet fs">
                <thead>
                <tr>
                    <th class="fs">{{ tokens.fullScreenVerification.document.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenVerification.timeUploaded.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenVerification.timeProcessed.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenVerification.status.toUpperCase() }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="document in documents"
                    :key="document.id"
                    class="fs"
                >
                    <td class="fs">{{ tokens.fullScreenVerification[newVerificationText ? 'types2' : 'types'][document.type] }}</td>
                    <td class="fs">{{ new Date(document.time_created * 1000).toLocaleString() }}</td>
                    <td class="fs">
                        {{ document.time_processed ? new Date(document.time_processed * 1000).toLocaleString() : '-' }}
                    </td>
                    <td class="fs" :class="tokens.statuses[document.status]">{{ tokens.statuses[document.status] }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {NOTIFICATION} from "@/store/mutations.type"
import {DOCUMENT_UPLOAD} from "@/store/actions.type"
import {
    THEME_3_DESIGN,
    BRAND,
    KYC_DOCUMENTS,
    KYC_DOCUMENTS_ICONS,
    NEW_KYC_DOCUMENT_TEXT,
    HIDE_DOC_UPLOADED_LINE, STATIC_BASE, DISABLE_QUESTIONS
} from "@/common/config"
import RestService from "@/common/rest.service"

export default {
    name: "Verification",
    data() {
        return {
            kycDocuments: KYC_DOCUMENTS,
            kycDocumentsIcons: KYC_DOCUMENTS_ICONS,
            theme_3_Design: THEME_3_DESIGN,
            newVerificationText: NEW_KYC_DOCUMENT_TEXT,
            disableDocUploadedLine: HIDE_DOC_UPLOADED_LINE,
            staticBase: STATIC_BASE,
            disableQuestions: DISABLE_QUESTIONS,
        }
    },
    methods: {
        addFile(type, e) {
            this.documentsData[type].over = false

            if (!e.dataTransfer.files || [0, 1].indexOf(this.documentsData[type].status) !== -1) {
                return
            }

            let data = new FormData()

            data.append('file', e.dataTransfer.files[0])
            data.append('data', JSON.stringify({
                type
            }))

            this.$store.dispatch(DOCUMENT_UPLOAD, data)
        },
        over(type, val) {
            this.documentsData[type].over = val
        },
        documentSelected(e, type) {
            let data = new FormData()

            data.append('file', e.target.files[0])
            data.append('data', JSON.stringify({
                type
            }))

            this.$store.dispatch(DOCUMENT_UPLOAD, data)

            e.target.value = ''
        },
        sendAnswers() {
            let answersArray = []
            for (let n in this.questions) {
                answersArray.push({question_id: this.questions[n].id, answer: this.questions[n].answer})
            }

            RestService.post('/kyc/questions', {answers: answersArray})
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Answers have been saved')
                })
        },
        getVerificationState(doc) {
            return doc.icon
        }
    },
    computed: {
        ...mapGetters(['documents', 'tokens', 'questions']),
        documentsData: function () {
            let resultingArray = [],
                docs = this.documents,
                icons = this.kycDocumentsIcons,
                over = false,
                icon = '', status = '', cssClass = '', text = '', type = ''

            switch (BRAND) {
                case "corlexcorp":
                case "crypwa":
                    icons = ['prof', 'home', 'cardFront', 'file', 'selfile']
                    break
                case "fingarante":
                case "crypfund":
                case "dev":
                    icons = ['prof', 'home', 'file', 'selfile']
                    break
            }

            this.kycDocuments.forEach((value,i) => {
                let typeToken = this.tokens.fullScreenVerification[this.newVerificationText ? 'types2' : 'types'][value]

                let docsOfType = docs.filter(doc => doc.type === value)
                docsOfType.sort((a, b) => a.id - b.id)
                let lastDocOfType = docsOfType[docsOfType.length - 1]

                status = lastDocOfType ? lastDocOfType.status : -1
                icon = icons[i]
                type = value
                switch (status) {
                    case -1:
                        cssClass = ''
                        text = this.tokens.fullScreenVerification.upload.toUpperCase() + ' ' + typeToken.toUpperCase()
                        break
                    case 0:
                        cssClass = 'wait-fs'
                        text = typeToken.toUpperCase() + ' - ' + this.tokens.fullScreenVerification.isUnderReview.toUpperCase()
                        break
                    case 1:
                        cssClass = 'green-fs'
                        text = typeToken.toUpperCase() + ' - ' + this.tokens.fullScreenVerification.isConfirmed.toUpperCase()
                        break
                    case 2:
                        cssClass = 'red-fs'
                        text = typeToken.toUpperCase() + ' - ' + this.tokens.fullScreenVerification.uploadAnotherDocument.toUpperCase()
                        break
                }
                let resultingDoc = {icon, over, status, cssClass, text, type}

                resultingArray.push(resultingDoc)
            })

            return resultingArray
        },
        searchSuccessfully() {
            let seachedDocuments = [],
                docs = this.documents,
                over = false,
                icon = '', status = ''


            this.kycDocuments.forEach((value, i, key) => {
                let docsOfType = docs.filter(doc => doc.type === key[i])
                docsOfType.sort((a, b) => a.id - b.id)
                let lastDocOfType = docsOfType[docsOfType.length - 1]

                status = lastDocOfType ? lastDocOfType.status : -1

                let resultingDoc = {icon, over, status}

                seachedDocuments.push(resultingDoc)

            })

            let successfully = 0

            seachedDocuments.forEach(el => {
                if (el.status === 1) {
                    successfully++
                }
            })

            return successfully
        },

        progressBarState() {
            return `${this.searchSuccessfully} ${this.tokens.fullScreenVerification.progressBarOf} ${this.documentsData.length} ${this.tokens.fullScreenVerification.progressBarText}`
        }
    }
}
</script>

<style scoped>
.row {
    flex-direction: row !important;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 0;
}

.col {
    flex: 0 0 32%;
    margin-bottom: 30px;
}

.questions {
    margin: 30px 0;
}

.progress-bar .empty {
    height: 15px;
    border: 1px solid var(--fs-border);
    border-radius: 10px;
    position: relative;
}

.progress-bar .filled {
    height: 100%;
    background: linear-gradient(95deg, #60BD35 25%, rgba(135, 194, 60, 0.35) 90%);
    border-radius: 10px;
}

.progress-bar p {
    font-size: 1em;
    font-weight: 400;
    margin-top: 10px;
}

.document {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    background: var(--background-dark-mid);
    display: grid;
    grid-template-rows: repeat(2, max-content);
    place-content: center space-evenly;
    position: relative;
    overflow: hidden;
    gap: 10px;
}

.img-inner {
    position: relative;
}

.document-inner {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 10px;
    place-items: center center;
    margin: 0 auto;
}

.document.over, .document.over i {
    color: var(--white)
}

.document i {
    font-size: 6em;
    color: var(--font)
}

.document .docname {
    font-weight: 400;
    font-size: 1.2em;
    text-align: center;
    color: #989996;
}
.theme_3-design .document .docname {
    font-size: 1em;
}

.document .docname.green-fs {
    color: var(--white);
}

.document .docname.red-fs {
    color: #AB0E0E;
}

.document .docname.wait-fs {
    color: #E67E0A;
}

.theme_3-design .docname.green-fs,
.theme_3-design .docname.wait-fs {
    color: #11CABE;
}

.document input {
    position: absolute;
    width: 150%;
    height: 150%;
    top: -50%;
    left: -50%;
    cursor: pointer;
}

.tip {
    color: var(--white-to-black);
    text-align: center;
    margin: 0 auto;
    opacity: .6;
}
.theme_3-design .tip {
    font-size: .7em;
}

input, select, textarea {
    width: 100%;
    font-size: 1.2em;
}

textarea {
    font-family: inherit;
}

.questions-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.questions-item > div {
    flex: 0 0 45%;
}

.img-inner .state {
    position: absolute;
    top: 30px;
    left: 40px;
    width: 30px;
    height: 30px;
    z-index: 1;
}

.document-inner .icon.green-fs {
    filter: invert(2) sepia(5) saturate(5) hue-rotate(35deg);
}

.document-inner .icon.red-fs {
    filter: none;
}

.document-inner .icon.wait-fs {
    filter: invert(0) sepia(5) saturate(15) hue-rotate(12deg);
}

.theme_3-design .document-inner .icon {
    filter: none;
}

.theme_3-design .document.green-fs,
.theme_3-design .document.wait-fs {
    border: 2px solid #11CABE;
}

.document-inner .icon {
    filter: invert(0) sepia(10) saturate(0) hue-rotate(15deg);
}

.document {
    transition: all 0.5s ease-in-out;
}

.col:hover .document img {
    filter: invert(0) sepia(10) saturate(1) hue-rotate(185deg);
}

.col:hover .document .docname {
    color: var(--white-to-black);
}

.col:hover .document .tip {
    opacity: .85;
}

@media (min-width: 1000px) {
    .img-inner .state {
        position: absolute;
        top: 1.5vw;
        left: 2vw;
        width: 1.5vw;
        height: 1.5vw;
        z-index: 1;
    }

    .document {
        height: 7.5vw;
        padding: 1vw 0;
        gap: .5vw;
    }

    .theme_3-design .document {
        height: 12.5vw;
    }

    .document-inner img {
        width: 3vw;
        height: 3vw;
        object-fit: contain;
    }

    .theme_3-design .document-inner img {
        width: 6vw;
        height: 6vw;
    }

    .plus {
        width: 3vw !important;
        height: 3vw !important;
    }

    .document-inner {
        gap: .5vw;
    }

    .tip {
        font-size: .8vw;
    }

    .document-inner .docname {
        font-size: .8vw;
        max-width: 8.5vw;
    }

    .theme_3-design .document-inner .docname {
        max-width: 100%;
    }

    .col {
        margin-bottom: 2vw;
    }

    .questions {
        margin: 1.5vw 0;
    }

    .progress-bar .empty {
        height: .8vw;
        border-radius: 1vw;
    }

    .theme_3-design .progress-bar .empty {
        height: 2vw;
    }

    .progress-bar .filled {
        border-radius: 1vw;
    }

    .progress-bar p {
        margin-top: .6vw;
        font-size: 1vw;
        margin-bottom: 0.5vw;
    }
}

@media (max-width: 1000px) {
    .progress-bar {
        margin-top: 30px;
    }

    .img-inner .state {
        top: 35px;
        left: 50px;
    }

    .col {
        flex: 0 0 100%;
    }

    .form-row .col:last-child {
        margin-bottom: 0;
    }

    .questions-item > div {
        flex: 0 0 100%;
    }

    .document {
        grid-template-rows: repeat(2, auto);
        gap: 30px;
        height: 130px;
    }
    .theme_3-design .document {
        gap: 10px;
    }

    .docname {
        max-width: 150px;
    }
}

.theme_3-design .questions-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.theme_3-design .questions button {
    width: calc(50% - 7px);
    margin-top: 15px;
}

.theme_3-design .progress-bar {
    font-weight: 600;
    font-size: 20px;
    color: #A5ADCF;
}

.theme_3-design label {
    font-weight: 600;
    font-size: 22px;
    color: #A5ADCF;
}

.progress-bullet {
    position: absolute;
    top: 0;
    width: 100%;
}

.theme_3-design .empty {
    background: #4A5680;
    border: none;
}

.theme_3-design .filled {
    background: linear-gradient(95deg, rgba(19, 199, 186, 1) 25%, rgba(63, 248, 245, 1), rgba(15, 103, 234, 1) 90%);
}

.theme_3-design .document {
    border: 2px dashed #F3F3F3;
    border-radius: 12px;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    color: #A5ADCF;
}

.theme_3-design .document-inner {
    grid-template-columns: 1fr;
}

.review,
.confirmed,
.plus {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
}

@media (max-width: 999px) {
    .theme_3-design .questions-item {
        grid-template-columns: 1fr;
    }

    .theme_3-design .document-inner .icon {
        width: 75px;
        height: 50px;
    }

    .document {
        height: 180px;
    }
    .theme_3-design .document {
        height: 250px;
    }
}

</style>
