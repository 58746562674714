<template>
    <div class="modal-wrapper"
    :class="{ hide: !modals.otp }">
        <div class="modal">
            <div class="title-wrapper">
                <BaseModalTitle>
                    <template #left>
                        <div class="modal-title-left">
                            <i class="material-icons">logout</i>
                            <span class="title__medium">{{ tokens.loginModal.twoFactorAuth }}</span>
                        </div>
                    </template>
                </BaseModalTitle>
            </div>
            <div class="modal-box">
                <BaseInput
                    v-model="otp"
                    :title="tokens.fullScreenSettings.twoFactorCode"
                    name="email"
                    autocomplete="off"
                    :placeholder="tokens.loginModal.enterTwoFactor"
                    @keyup.native.enter="login" />
            </div>
            <div class="modal-footer">
                <div class="modal-goback">
                    <span class="label__medium">
                        {{ tokens.loginModal.goTo }}
                    </span>
                    <BaseButtonSmall
                        :buttonText="tokens.loginModal.login"
                        :buttonStyle="'tonal-secondary'" 
                        @click.native="modals.otp = false"/>
                </div>
                <BaseButton
                    :buttonStyle="'filled-primary'"
                    :buttonText="tokens.forgotPasswordModal.submit"
                    @click.native="login"
                    @keydown.native.enter="login" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {LOGIN} from "@/store/actions.type"
import BaseModalTitle from "@/new-design/shared/ui/titles/BaseModalTitle.vue"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"

export default {
    name: "TwoFactorAuthenticationModal",
    components: {BaseModalTitle, BaseButton, BaseInput, BaseButtonSmall},
    props: ['email', 'password'],
    data() {
        return {
            otp: '',
        }
    },
    methods: {
        login() {
            this.$store.dispatch(LOGIN, {
                email: this.email.trim(),
                password: this.password.trim(),
                otp: this.otp
            })
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals'])
    }
}
</script>

<style scoped>
.new-design.mobile.banner-is-active .modal-wrapper {
    height: calc(100% - 44px);
}

.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    top: auto;
}

.modal {
    width: 620px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 25px 28px;
    z-index: 1100;
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
    background: var(--System-Surface1);
    position: relative;
}

.title-wrapper {
    margin-bottom: 34px;
}

.modal-title-left {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-title-left i {
    font-size: 18px;
}

.modal-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-goback{
    display: flex;
    align-items: center;
    gap: 10px;
}

@media(max-width: 768px){
    .modal{
        margin: 15px;
    }
}
</style>
