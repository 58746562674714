<!--todo make as single base component-->

<template>
    <div class="deposit-request">
        <h5 class="title title__medium">
            {{ tokens.fullScreenDeposit.last }}
            {{ tokens.fullScreenDeposit.deposits }}
        </h5>
        <div v-if="deposits && Object.keys(deposits).length" 
            class="deposit-panel"
            ref="deposits" 
            @scroll="loadMore()"
        >
            <table class="table-desktop">
                <thead>
                <tr>
                    <th class="label__small">{{ tokens.fullScreenDeposit.amount.toUpperCase() }}</th>
                    <th class="label__small">{{ tokens.fullScreenDeposit.currency.toUpperCase() }}</th>
                    <th class="nomobile label__small">{{ tokens.fullScreenDeposit.time.toUpperCase() }}</th>
                    <th class="label__small info">{{ tokens.fullScreenDeposit.info.toUpperCase() }}</th>
                    <th class="label__small">{{ tokens.fullScreenDeposit.status.toUpperCase() }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="deposit in deposits.slice(0,loaded)"
                    :key="deposit.id"
                >
                    <td class="body__small">{{
                            currencySign(deposit.currency) + deposit.amount.toFixed(['BTC', 'ETH', 'TRX', 'ETM'].indexOf(deposit.currency) !== -1 ? 8 : 2)
                        }}
                    </td>
                    <td>{{ deposit.currency }}</td>
                    <td class="nomobile body__small">{{ new Date(deposit.time_created * 1000).toLocaleString() }}</td>
                    <td class="info body__small">{{ deposit.comment || '-' }}</td>
                    <td class="status body__small" :class="tokens.statuses[deposit.status]">{{ tokens.statuses[deposit.status] }}</td>
                </tr>
                </tbody>
            </table>
            <div class="table-mobile">
                <DepositRequest
                    v-for="deposit in deposits.slice(0,loaded)"
                    :key="deposit.id"
                    :deposit="deposit"
                />
            </div>
        </div>
        <BasePanel v-else class="info-panel">
            <template #content>
                <div class="info-content">
                    <h5 class="info-title title__medium">{{ tokens.newDesign.withdrawalRequests }}</h5>
                    <p class="info-desc body_small">
                        {{ tokens.newDesign.withdrawalRequestsText1 }} <br>
                        {{tokens.newDesign.withdrawalRequestsText2}}
                    </p>
                </div>
            </template>
        </BasePanel>
        <div class="hollow-dots-spinner-wrapper">
            <div class="hollow-dots-spinner" v-if="dataIsLoaded && loaded < deposits.length">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {currencySign} from "@/common/helpers"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import DepositRequest from "@/new-design/entities/Deposit/ui/DepositRequest.vue"

export default {
    name: "DepositRequests",
    components: {DepositRequest, BasePanel},
    data() {
        return {
            dataIsLoaded: false,
            loaded: 15,
        }
    },
    methods: {
        currencySign,
        loadMore(){
            if (!this.$refs['deposits']) {
                return
            }
            let loadData = this.loaded
            if (this.$refs['deposits'].scrollTop + this.$refs['deposits'].clientHeight + 1 >= this.$refs['deposits'].scrollHeight) {
                this.dataIsLoaded = true
                setTimeout(() => {
                    if(loadData <= this.deposits.length) {
                        this.loaded += 5
                    }

                    if(loadData + 5 >= this.deposits.length){
                        this.loaded = this.deposits.length
                    }
                    this.dataIsLoaded = false
                }, 1500)
            }
        },
    },
    computed: {
        ...mapGetters(['tokens', 'deposits'])
    }
}
</script>

<style scoped>
.title {
    margin-bottom: 12px;
    margin-top: 40px;
    color: var(--System-OnSurface);
}

table {
    width: 100%;
    position: relative;
    background: var(--System-Surface, #161B24);
    border-collapse: collapse;
    border: 1px solid var(--System-Surface5);

}

thead tr th:hover {
    cursor: pointer;
    background: var(--System-Surface3, #252B36);
}

tr:not(thead  tr):hover {
    background: var(--System-Surface3, #252B36);
}

td, th {
    white-space: nowrap;
    text-align: left;
    padding: 7px 7px 6px;
    background: var(--System-Surface);
}

th {
    position: sticky;
    top: 0px;
    border: 1px solid var(--System-Surface5);
    font-weight: 500;
    text-transform: capitalize;
}

.status {
    display: flex;
    align-items: center;
    gap: 4px;
}

th::after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

th::before {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

td {
    color: var(--System-OnSurface);
    border-right: 1px solid var(--System-Surface5);
}

td:last-child {
    border: none;
}

td:last-child span {
    cursor: pointer;
}

td:last-child span {
    margin-right: 15px;
}

td:last-child span:last-child {
    margin-right: 0;
}

td:first-child img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
}

td .symbol {
    zoom: 0.21875;
    margin-right: 23px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px;
}

td span:has(i) {
    display: flex;
    align-items: center;
}

td:has(i){
    display: flex;
    align-items: center;
}

td i {
    font-size: 16px;
    color: var(--System-Primary);
    height: 100%;
}

.status,
.info {
    color: var(--System-OnSurfaceVariant);
}

.Failure {
    color: var(--Custom-RedFixed)
}

.Approved {
    color: var(--Custom-GreenFixed)
}

thead tr th:first-child,
td:has(span.symbol){
    position: sticky;
    left: 0;
    z-index: 5;
}

thead tr th:first-child{
    top: 0;
    z-index: 6;
}

thead tr th:first-child::after,
td:has(span.symbol)::after{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    right: -1px;
    top: 0;
}

thead tr th:first-child::before,
td:has(span.symbol)::before{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    left: -1px;
    top: 0;
}

.sort-button{
    display: inline-block;
    position: relative;
    width: 10px;
    height: 20px;
    transform: translateY(2px);
    margin-top: -3px;
    margin-bottom: -4px;
}

.sort-button::after,
.sort-button::before{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: var(--System-Outline);
    transition: color .2s ease;
}

.sort-button::before{
    content: 'arrow_drop_up';
    top: 35%;
}

.sort-button::after{
    content: 'arrow_drop_down';
    top: 65%;
}

.sort-button.up::before,
.sort-button.down::after {
    color: var(--System-Primary);
}

.sort-button.down::before,
.sort-button.up::after {
    color: var(--System-OutlineVariant);
}

.deposit-panel {
    height: 300px;
    overflow: auto;
}

.info {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hollow-dots-spinner-wrapper {
    height: 50px;
    position: relative;
}

.hollow-dots-spinner, .hollow-dots-spinner * {
    box-sizing: border-box;
}

.hollow-dots-spinner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 15px;
    width: calc(30px * 3);
    margin: 20px auto 0;
}

.hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    border: calc(15px / 5) solid #fff;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.table-mobile{
    display: none;
}

@media (max-width: 768px) {
    .table-desktop{
        display: none;
    }

    .table-mobile{
        display: block;
    }

   .deposit-panel {
        display: flex;
        padding: 12px;
        border: 1px solid var(--System-OutlineVariant);
        border-radius: var(--Corner-small);
        flex-direction: column;
    }
}
</style>
