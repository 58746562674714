<template>
    <div class="privacy-page">
        <div class="privacy-page__header">
            <i class="material-symbols-outlined back-arrow" @click="$emit('close')">arrow_back</i>
            <BasePageTitle :title="tokens.newDesign.verification" />
        </div>
        <BasePanel >
            <template #content>
                <div class="privacy-page__inner">
                    <div class="privacy-page__request">
                        <span class="body__medium"> 
                            For additional details, <br>
                            we will send the information to your email
                        </span>
                        <BaseButtonSmall 
                            :button-text="'Request Legal Information'"
                            :icon="'forward_to_inbox'" 
                            :button-style="'outlined-primary'"
                            @click.native="requestInfo"
                        />
                    </div>
                    <div class="privacy-page__content">
                        <h2 class="title__large">Privacy Policy</h2>
                        <p class="body__medium">Denali Trading Group, LLC has adopted this policy with recognition that protecting the privacy and security of the personal information we obtain about our customers is an important responsibility. We also know that you expect us to service you in an accurate and efficient manner. To do so, we must collect and maintain certain personal information about you. We want you to know what information we collect and how we use and safeguard that information. For any questions or concerns regarding your privacy, please contact us at Denali Trading Group, LLC at +15163211835</p>
                        <h3 class="title__medium">WHAT INFORMATION WE COLLECT</h3>
                        <p class="body__medium">We collect certain nonpublic personal identifying information about you (such as your name, address, social security number, etc.) from information that you provide on applications or other forms as well as communications (electronic, telephone, written or in person) with you or your authorized representatives (such as your attorney, accountant, etc.). We collect information about your brokerage accounts and transactions (such as purchases, sales, account balances, inquiries, etc.). We also collect your phone number and consent status to provide you with SMS services. This information is used solely for the purpose of delivering text messages related to the campaign you have opted into. You have the right to access, correct, or delete your personal information.</p>
                        <h3 class="title__medium">WHAT INFORMATION WE DISCLOSE</h3>
                        <p class="body__medium">We do not sell, share, or disclose your nonpublic personal information to non-affiliated third party financial companies. We will not disclose the nonpublic personal information we collect about our customers to anyone except as necessary as follows: (i) in furtherance of our business relationship with them and then only to those persons necessary to effect the transactions and provide the services that they authorize (such as broker-dealers, custodians, independent managers etc.); (ii) to persons assessing our compliance with industry standards (e.g., professional licensing authorities, etc.); (iii) our attorneys, accountants, and auditors; or (iv) as otherwise provided by law. We will not share your opt-in to an SMS campaign with any third party for purposes unrelated to providing you with the services of that campaign. We may share your Personal Data, including your SMS opt-in or consent status, with third parties that help us provide our messaging services, including but not limited to platform providers, phone companies, and any other vendors who assist us in the delivery of text messages.</p>
                        <p class="body__medium">We are permitted by law to disclose the nonpublic personal information about you to governmental agencies and other third parties in certain circumstances (such as third parties that perform administrative or marketing services on our behalf or for joint marketing programs), however we will not do so. These third parties are prohibited to use or share the information for any other purpose. If you decide at some point to either terminate our services or become an inactive customer, we will continue to adhere to our privacy policy, as may be amended from time to time.</p>
                        <h3 class="title__medium">SECURITY OF YOUR INFORMATION</h3>
                        <p class="body__medium">We restrict access to your nonpublic personal information to those employees who need to know that information to service your account. We maintain physical, electronic and procedural safeguards that comply with applicable federal or state standards to protect your nonpublic personal information. We are committed to protecting your personal information and have implemented appropriate technical and organizational measures to ensure its security.</p>
                        <h3 class="title__medium">CHANGES TO OUR PRIVACY POLICY OR RELATIONSHIP WITH YOU</h3>
                        <p class="body__medium">Our policy about obtaining and disclosing information may change from time to time. We will provide you notice of any material change to this policy before we implement the change.  </p>
                    </div>
                </div>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RestService from "@/common/rest.service";
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle.vue"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"

export default {
    name: "PrivacyPolicy",
    components: { BasePageTitle, BasePanel , BaseButtonSmall},
    computed: {
        ...mapGetters(['tokens'])
    },
    methods:{
        requestInfo(){
            RestService.get('/users/privacy_policy')
        }
    }
}
</script>

<style scoped>
.privacy-page {
    position: absolute;
    inset: 0;
    z-index: 10;
    background: var(--System-Surface);
    min-height: max-content;
    padding-bottom: 80px;
}

.privacy-page__header{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: default;
    i{
        margin-top: 5px;
        color: var(--System-OnSurfaceVariant);
    }
}

.back-arrow{
    cursor: pointer;
}

.panel {
    padding: 16px;
}

.privacy-page__request { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;
    span{
      color: var(--System-OnSurface);
    }
}

.privacy-page__content { 
    border-top: 1px solid var(--System-OutlineVariant);
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    h2{
        color: var(--System-OnSurface);
    }
    h3{
        margin-bottom: -12px;
    }
}

@media (max-width: 1000px) {
    .privacy-page__request {
        flex-direction: column;
        align-items: normal;
        gap: 16px;
    }
}
</style>
