<template>
    <div @click="activeSelect = false">
        <div v-show="withdrawal">
            <div class="fullscreen-page-title">
                {{ tokens.fullScreen.withdrawal.toUpperCase() }}
            </div>
            <div class="row fs flex-start" :class="{hide: !user.enable_withdrawal}">
                <div class="form-row fs">
                    <div class="form-title">
                        {{ tokens.fullScreenWithdrawal.requestNewWithdrawal }}
                    </div>
                </div>
                <div class="col fs">

                    <div class="form-row fs">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.amount }}</label>
                        <div class="input-box">
                            <input v-model="amount" class="form-control fs"/>
    <!--                        <div class="tip">-->
    <!--                            <span>{{ tokens.fullScreenWithdrawal.currentBalance }} {{ currencySign(activeAccount.currency)+activeAccount.balance.toFixed(activeAccount.precision) }}</span>-->
    <!--                            <span class="all" v-on:click="amount = activeAccount.balance > 0 ? activeAccount.balance : 0">{{ tokens.fullScreenWithdrawal.withdrawAll }}</span>-->
    <!--                        </div>-->
                            <select class="currency form-control" v-model="activeCurrency"  v-if="withdrawalCurrencies.length">
                               <option v-for="(currency, i) in withdrawalCurrencies" :key="i" >{{currency}}</option>
                            </select>
                            <div v-else class="currency">
                                {{currencySign(activeAccount.currency)}}
                            </div>
                        </div>
                    </div>
                    <div class="form-row fs">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.withdrawalDetails }}</label>
                        <select class="form-control fs" v-model="method" :class="{activeSelect: activeSelect}"
                                @click.stop="activeSelect = !activeSelect">
                            <option v-for="(value, key) in getWithdrawalOptions" :key="key" :value="key">
                                {{ bitcoinWithdrawalText && key === 'bitcoin' ? tokens.fullScreenWithdrawal[bitcoinWithdrawalText] : tokens.fullScreenWithdrawal[key] }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-row fs">
                    <div class="form-title">
                        {{ tokens.fullScreenWithdrawal.withdrawalDetails.toUpperCase() }}
                    </div>
                </div>
                <div class="col fs">

                    <div class="form-row fs"
                         v-bind:class="{hide: method !== 'bitcoin' && method !== 'ethereum' && method !== 'usdt' && method !== 'usdc' && method !== 'matic' && method !== 'bnb'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.address }}</label>
                        <input type="text" class="form-control fs" v-model="methods.btc.wallet">
                    </div>
                    <div class="form-row fs"
                         v-bind:class="{hide: method !== 'zelle'}">
                        <label class="fs">Phone Number or E-mail</label>
                        <input type="text" class="form-control fs" v-model="methods.zelle.contact">
                    </div>
                    <div class="form-row fs"
                         v-bind:class="{hide: method !== 'zelle'}">
                        <label class="fs">First Name and Last Name Zelle</label>
                        <input type="text" class="form-control fs" v-model="methods.zelle.name">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('account')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.bankAccount }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.account">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('holder')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.bankHolder }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.holder">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('iban')" class="form-row" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.bankIban }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.iban">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('swift')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.bankSwift }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.swift">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('recipientAddress')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.recipientAddress }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.recipientAddress">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('routingNumber')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.routingNumber }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.routingNumber">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('bankBeneficiary')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.bankBeneficiary }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.bankBeneficiary">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('bankAddress')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.bankAddress  }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.bankAddress">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('bankCode')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.bankCode }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.bankCode">
                    </div>
                    <div v-if="bankWithdrawalFields.includes('desc')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.desc }}</label>
                        <input type="text" class="form-control fs" v-model="methods.bank.desc">
                    </div>
                    <div class="form-row fs" v-bind:class="{hide: method !== 'card'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.cardNumber }}</label>
                        <input type="text" class="form-control fs" v-model="methods.card.number">
                    </div>
                    <div class="form-row fs" v-bind:class="{hide: method !== 'card'}">
                        <label class="fs">{{ tokens.fullScreenWithdrawal.cardHolder }}</label>
                        <input type="text" class="form-control fs" v-model="methods.card.holder">
                    </div>
                    <div class="form-row fs"
                        :class="{hide: method !== 'card'}"
                    >
                        <label class="fs">{{ 'Bank Name' }}</label>
                        <input type="text" class="form-control fs" v-model="methods.card.bankName">
                    </div>
                </div>
            </div>
            <div class="form-row pad fs-bottom" :class="{hide: !user.enable_withdrawal}">
                <button class="fs-normal" v-on:click="requestWithdrawal">{{
                        tokens.fullScreenWithdrawal.requestWithdrawal
                    }}
                </button>
            </div>
            <div class="table-cont">
                <div class="form-row fs">
                    <div class="form-title">
                        {{ tokens.fullScreenWithdrawal.withdrawalRequests }}
                    </div>
                </div>
                <table class="cabinet fs">
                    <thead>
                    <tr>
                        <th class="fs">{{ tokens.fullScreenWithdrawal.time.toUpperCase() }}</th>
                        <th class="fs">{{ tokens.fullScreenWithdrawal.amount.toUpperCase() }}</th>
                        <th class="fs">{{ tokens.fullScreenWithdrawal.currency.toUpperCase() }}</th>
                        <th class="fs">{{ tokens.fullScreenWithdrawal.info.toUpperCase() }}</th>
                        <th class="fs">{{ tokens.fullScreenWithdrawal.status.toUpperCase() }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="withdrawal in withdrawals.slice(0,10)"
                        :key="withdrawal.id"
                    >
                        <td class="fs">{{ new Date(withdrawal.time_created * 1000).toLocaleString() }}</td>
                        <td class="fs">{{
                                currencySign(withdrawal.currency) + Math.abs(withdrawal.amount).toFixed(activeAccount.precision)
                            }}
                        </td>
                        <td colspan="fs">{{ withdrawal.currency }}</td>
                        <td class="fs">{{ withdrawal.comment }}</td>
                        <td class="fs"
                            :class="tokens.statuses[withdrawal.status]">
                            {{ tokens.statuses[withdrawal.status] }}
                            <i v-if="withdrawal.status === 0 && !hideWithdrawal"
                               @click="confirmCancelWithdrawal(withdrawal.id)"
                               class="material-icons cancel-withdrawal" title="Cancel withdrawal request">cancel</i>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="!withdrawal" class="pad">
            <div class="pad-item">
                <h2>
                    Please contact your account manager for withdrawal details
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign} from "@/common/helpers"
import {WITHDRAWAL_REQUEST} from "@/store/actions.type"
import {NOTIFICATION_ERROR, MODAL, MODAL_WITHDRAWAL_CANCEL} from "@/store/mutations.type"
import {
    CLOSE_WITHDRAWAL,
    MINIMUM_WITHDRAWAL,
    THEME_3_DESIGN,
    WITHDRAWAL_OPTIONS,
    WITHDRAWAL_CURRENCY,
    BITCOIN_WITHDRAWAL,
    BANK_WITHDRAWAL_FIELDS,
    BRAND,
    DEFAULT_WITHDRAWAL_METHOD
} from "@/common/config"

export default {
    name: "Withdrawal",
    data() {
        return {
            amount: '',
            hideWithdrawal: CLOSE_WITHDRAWAL,
            method: WITHDRAWAL_OPTIONS.bank ? 'bank' : 'bitcoin',
            activeSelect: false,
            activeCurrency: '',
            theme_3_Design: THEME_3_DESIGN,
            bankWithdrawalFields: BANK_WITHDRAWAL_FIELDS,
            methods: {
                btc: {
                    wallet: ''
                },
                bank: {
                    account: '',
                    holder: '',
                    iban: '',
                    swift: '',
                    recipientAddress: '',
                    routingNumber: '',
                    bankBeneficiary: '',
                    bankAddress: '',
                    bankCode: '',
                    desc: '',
                },
                card: {
                    number: '',
                    holder: '',
                    bankName: ''
                },
                zelle: {
                    contact: '',
                    name: ''
                }
            },
            withdrawal: true,
            withdrawalOptions: WITHDRAWAL_OPTIONS,
            withdrawalCurrencies: WITHDRAWAL_CURRENCY,
            bitcoinWithdrawalText: BITCOIN_WITHDRAWAL,
            brand: BRAND,
            defaultWithdrawalMethod: DEFAULT_WITHDRAWAL_METHOD
        }
    },
    methods: {
        confirmCancelWithdrawal(id) {
            this.$store.commit(MODAL_WITHDRAWAL_CANCEL, id)
            this.$store.commit(MODAL, {
                withdrawalCancel: true
            })
        },
        requestWithdrawal() {
            let amount = String(this.amount)
            amount = amount.includes(',') ? amount.replace(/,/, '.') : amount
            amount = parseFloat(amount)

            for (let n in this.withdrawals) {
                if (this.withdrawals[n].status === 0) {
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_ONE_PENDING_WITHDRAWAL')
                    return
                }
            }

            if (isNaN(parseFloat(amount))) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                return
            }

            if (this.method === 'bitcoin') {
                if (amount * this.symbols['BTCUSD'].quote.bid < MINIMUM_WITHDRAWAL) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                    return
                }
            } else if (this.method === 'bank' || this.method === 'card') {
                if (amount < MINIMUM_WITHDRAWAL) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                    return
                }
            }

            let details = ''
            switch (this.method) {
                case 'bitcoin':
                    details = this.methods.btc.wallet
                    break
                case 'bank':
                    details =
                        `Account: ${this.methods.bank.account}\nAccount holder: ${this.methods.bank.holder}${this.methods.bank.iban ? `\nIBAN: ` + this.methods.bank.iban : ''}${this.methods.bank.swift ? '\nSWIFT: ' + this.methods.bank.swift : ''}${this.methods.bank.recipientAddress ? '\nRecipient Address: ' + this.methods.bank.recipientAddress : ''}${this.methods.bank.routingNumber ? '\nRouting Number: ' + this.methods.bank.routingNumber : ''}${this.methods.bank.bankBeneficiary ? '\nBank of Beneficiary Name: ' + this.methods.bank.bankBeneficiary : ''}${this.methods.bank.bankAddress ? '\nBank Address: ' + this.methods.bank.bankAddress : ''}${this.methods.bank.bankCode ? '\nBank code (BSB): ' + this.methods.bank.bankCode : ''}${this.methods.bank.desc ? '\nDescription: ' + this.methods.bank.desc : ''}`
                    break
                case 'card':
                    details =
                        `Card: ${this.methods.card.number}\nCard holder: ${this.methods.card.holder}${this.methods.card.bankName ? '\nBank Name: ' + this.methods.card.bankName : ''}`
                    break
                case 'ethereum':
                    details = `ETH: ${this.methods.btc.wallet}`
                    break
                case 'usdt':
                    details = `USDT: ${this.methods.btc.wallet}`
                    break
                case 'USDC':
                    details = `USDC: ${this.methods.btc.wallet}`
                    break
                case 'matic':
                    details = `MATIC: ${this.methods.btc.wallet}`
                    break
                case 'bnb':
                    details = `BNB: ${this.methods.btc.wallet}`
                    break
                case 'zelle':
                    details = `Phone/Eamil:${this.methods.zelle.contact}\nFull Name Zelle: ${this.methods.zelle.name}`
                    break
            }

            this.$store.dispatch(WITHDRAWAL_REQUEST, {
                wallet: details,
                amount: amount,
                account_id: this.activeAccount.id,
                product: this.activeAccount.product,
                currency: this.activeAccount.currency
            })

            this.methods.btc.wallet = ''
            this.methods.bank.account = ''
            this.methods.bank.holder = ''
            this.methods.bank.iban = ''
            this.methods.bank.swift = ''
            this.methods.bank.recipientAddress = ''
            this.methods.bank.routingNumber = ''
            this.methods.bank.bankBeneficiary = ''
            this.methods.bank.bankAddress = ''
            this.methods.bank.bankCode = ''
            this.methods.bank.desc = ''
            this.methods.card.number = ''
            this.methods.card.holder = ''
            this.methods.card.bankName = ''
            this.methods.zelle.name = ''
            this.methods.zelle.contact = ''
        },
        setWithdrawalMethod(WithdrawalOptions) {
            this.$set(this, 'withdrawal', true)
            if (!Object.keys(WithdrawalOptions).length) {
                this.$set(this, 'withdrawal', false)
            } else {
                this.$set(this, 'method', this.defaultWithdrawalMethod || Object.values(WithdrawalOptions)[0])
            }
        },
        currencySign,
    },
    computed: {
        ...mapGetters(['withdrawals', 'activeAccount', 'realAccount', 'symbols', 'user', 'tokens', 'withdrawalList']),
        getWithdrawalOptions() {
            const newWithdrawalOptions = Object.values(this.withdrawalList).reduce((acc, cur) => {
                const field = cur.name
                if (this.withdrawalOptions[field]) {
                    acc[field] = this.withdrawalOptions[field]
                }
                return acc
            }, {})

            this.setWithdrawalMethod(newWithdrawalOptions)
            return newWithdrawalOptions
        }
    },
    activeAccount: {
        handler() {
            if (this.activeAccount !== undefined) {
                this.withdrawalCurrencies = []
                this.withdrawalCurrencies = WITHDRAWAL_CURRENCY
                if (this.withdrawalCurrencies.length) {
                    this.withdrawalCurrencies.push(this.activeAccount.currency)
                }
                this.activeCurrency = this.withdrawalCurrencies[1] || this.activeAccount.currency
            }
        }
    }

}
</script>

<style scoped>
.row.fs {
    grid-template-columns: 1fr;
}

.col {
    flex: 1 1 40%
}

.col.fs {
    margin-bottom: .5vw;
}

input, select {
    width: 100%;
}

.tip {
    font-size: 1.1em;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 5px;
}

.all {
    margin-left: 20px;
    color: var(--white);
    border-bottom: 1px dashed var(--white);
    cursor: pointer;
    white-space: nowrap;
}

.pad {
    margin-bottom: 50px;
}

.cancel-withdrawal {
    vertical-align: middle;
    cursor: pointer;
}

.withdrawal-off {
    background: var(--background-dark-mid);
    font-size: 18px;
    color: var(--white);
    text-align: center;
}

table.fs {
    min-width: 450px;
}

.form-row {
    position: relative;
}

.form-control.currency {
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    right: 0;
    width: 50px !important;
    height: 25px !important;
    border-radius: 5px !important;
    padding: 5px !important;
    text-align: center;
}

.input-box {
    position: relative;
    width: 100%;
}

.form-row .currency {
    position: absolute;
    top: 50%;
    right: 20px;
    font-size: 1.4em;
    color: var(--white);
    transform: translateY(-50%);
}

.theme_3-design .form-row .currency{
    color: var(--white-to-black);
}

@media (min-width: 1000px) {
    .fs-bottom {
        margin-bottom: 2vw;
    }

    .withdrawal-off {
        padding: 7vw 12vw;
        border-radius: 0.5vw;
        font-size: 1vw;
    }
}

@media (max-width: 1000px) {
    .col.fs {
        margin-bottom: 20px;
    }
}

/* ----------NEW DASH---------- */

.theme_3-design .fullscreen-page-title{
    display: none;
}

.theme_3-design .col {
    gap: 30px 80px;
}

.theme_3-design .form-control,
.theme_3-design button {
    padding: 24px 32px;
    height: 70px;
    border-radius: 100px;
}

.theme_3-design .form-control {
    background: #34384C;
    border: 1px solid #34384C;
}

.theme_3-design .form-row label {
    font-size: 16px;
    line-height: 24px;
    color: var(--theme_3-color-label)
}

.theme_3-design .form-title {
    font-size: 24px;
    font-weight: 600;
}

.theme_3-design .form-title,
.theme_3-design .form-row label {
    padding-left: 35px;
}

.theme_3-design button {
    background: linear-gradient(265.56deg, #246CF9 -0.3%, #1E68F6 -0.25%, #0047D0 95%);
    gap: 10px;
    text-align: center;
    border: none;
    font-weight: 600;
    font-size: 18px;
    width: 46%;
}

.pad-item {
    display: grid;
    grid-template-columns: 1fr;
    min-height: 70px;
    padding: 35px 24px;
    background: var(--theme_3-background-third);
    color: var(--whitesmoke-to-lightgrey);
    border-radius: 12px;
    text-align: center;
}

@media (min-width: 1921px) and (min-resolution: 1dppx) {
    .theme_3-design .col {
        gap: 3.2vw 8.6vw;
    }

    .theme_3-design .form-control,
    .theme_3-design button {
        padding: 1.5vw 3.4vw;
        height: auto;
        border-radius: 10vw;
    }

    .theme_3-design .form-control {
        border: .1vw solid #34384C;
    }

    .theme_3-design .form-row label {
        font-size: 1.5vw;
        line-height: 2.5vw;
    }

    .theme_3-design .form-title {
        font-size: 2vw;
    }

    .theme_3-design .form-title,
    .theme_3-design .form-row label {
        padding-left: 3.7vw;
    }

    .theme_3-design button {
        gap: 1vw;
        font-size: 1.9vw;
    }
}

@media (max-width: 999px) {
    .theme_3-design button {
        width: 100%;
    }
}
</style>
