<template>
    <div class="live-chat-page">
        <BasePageTitle
            :title="tokens.footer.liveChat"
        >
            <template #left-icon>
                <span class="circle online"></span>
            </template>
        </BasePageTitle>
        <LiveChat/>

    </div>
</template>

<script>
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle.vue"
import LiveChat from "@/new-design/features/LiveChat/Chat/ui/LiveChat.vue"
import {mapGetters} from "vuex"

export default {
    name: "LiveChatPage",
    components: {LiveChat, BasePageTitle},
    computed: {
        ...mapGetters(['tokens'])
    }
}
</script>

<style scoped>
.live-chat-page {
    display: grid;
    grid-template-rows: max-content 1fr;
}

.page-title {
    border-bottom: 1px solid var(--border);
    padding: 16px 20px;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
}

.circle.online {
    background: #22A06B;
}
</style>
