<template>
    <div class="select" :class="{ selected: value }" @click="toggler()">
        <span class="title__small">
            <slot name="title"/>
        </span>
        <i class="material-symbols-outlined" :class="{open: state}">arrow_drop_down</i>
    </div>
</template>

<script>
import toggler from "@/new-design/shared/mixins/toggler"

export default {
    name: 'BaseSelectButton',
    mixins: [toggler],
    props: {
        value: {
            type: String
        }
    },
}
</script>

<style scoped>
.select {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 4px 10px;
    background: var(--System-Surface1);
    border-radius: 4px;
    cursor: pointer;
    width: max-content;
    color: var(--System-OnSurfaceVariant);
    transition: all .3s ease;
}

.select.selected {
    color: var(--System-Primary);
    background: var(--System-Surface5);
}

.select:hover {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.select i {
    font-size: 18px;
    display: block;
    margin-bottom: 2px;
    transition: transform .3s ease;
}

.select i.open {
        transform: rotate(180deg);
}
</style>
