<template>
    <div class="modal-wrapper"
         v-if="modals.symbols"
         v-on:click.stop="hideModal">
        <div class="modal" v-on:click.stop>
            <div class="markets">
                <div class="item"
                     v-for="(vals, market) in counts"
                     :key="market"
                     :class="{active: market === tabActive}"
                     v-on:click="tabActive = market"
                >
                    <div class="row">
                        <i class="material-icons">{{ vals ? vals.ico : 'offline_bolt' }}</i>
                        <span class="name">{{ tokens.markets[market] }}</span>
                    </div>
                    <div class="row">
                        <span class="num">{{ vals.count || 0 }}</span>
                    </div>
                </div>
                <div class="item"
                     :class="{active: 'favorite' === tabActive}"
                     v-on:click="tabActive = 'favorite'"
                >
                    <div class="row">
                        <i class="material-icons">stars</i>
                        <span class="name">{{ tokens.symbolsModal.watchlist }}</span>
                    </div>
                    <div class="row">
                        <div class="num">{{ symbolsFavorite.length }}</div>
                    </div>
                </div>
            </div>
            <div class="details shadow">
                <input class="search" :placeholder="tokens.symbolsModal.searchByName" v-model="search" />
                <i class="material-icons search-ico">search</i>
                <table>
                    <thead>
                    <tr>
                        <th>{{ tokens.symbolsModal.asset }}</th>
                        <th>{{ tokens.symbolsModal.price }}</th>
                        <th @click="toggleSort" class="toggle-sort">{{ tokens.symbolsModal.changePct }} <i class="material-icons">{{ sort === 'asc' ? 'arrow_drop_up' : (sort === 'desc' ? 'arrow_drop_down' : '') }}</i> </th>
                        <th></th>
                    </tr>
                    </thead>
                </table>
                <div class="scroll">
                    <table class="symbols">
                        <tbody>
                        <tr
                            v-for="symbol in symbolsVisible"
                            :key="symbol.symbol"
                            :class="{hide: (symbol.market !== tabActive && !('favorite' === tabActive && symbolsFavorite.indexOf(symbol.symbol) !== -1)) || (search && !symbol.symbol.toLowerCase().includes(search.toLowerCase()) && !symbol.alias.toLowerCase().includes(search.toLowerCase())) || symbol.symbol.includes('demo') || !symbol.visible}"
                            v-on:click="addSymbol(symbol.symbol)"
                        >
                            <td>
                                <div class="row" :class="sessionState[symbol.symbol] ? '' : 'close'">
<!--                                    <img :src="staticBase+symbol.symbol.toUpperCase()+'.png'" />-->
                                    <span class="symbol" :class="'symbol-'+symbol.symbol.toUpperCase()" />
                                    <span class="name" :class="sessionState[symbol.symbol] ? '' : 'close'">{{ symbol.alias }}</span>
                                </div>
                            </td>
                            <td class="price" :class="sessionState[symbol.symbol] ? '' : 'close'">
                                {{ (symbol.quote && symbol.quote.bid ? parseFloat(symbol.quote.bid) : 0).toFixed(symbol.precision) }}
                            </td>
                            <td :class="{
                                red: symbol.quote && symbol.quote.changePct < 0,
                                green: symbol.quote && 0 < symbol.quote.changePct,
                                hide: !sessionState[symbol.symbol]
                             }" class="payout">
                                {{ symbol.quote && symbol.quote.changePct ? symbol.quote.changePct.toFixed(2)+'%' : '-'}}
                            </td>
                          <td :class="{hide: sessionState[symbol.symbol]}" class="payout close">
                            Closed
                          </td>
                            <td>
                                <i class="material-icons fav"
                                    :class="{active: symbolsFavorite.indexOf(symbol.symbol) !== -1}"
                                    v-on:click.stop="toggleFavorite(symbol.symbol)"
                                >star</i>
                            </td><td>
                            <i class="material-icons fav" @click.stop="showAssetsInfoModal(symbol.symbol)">info_outlined</i>
                        </td>

                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {
        MODAL,
        SYMBOL_ACTIVE_UPDATE,
        SYMBOL_FAVORITE,
        SYMBOL_SHOW,
        MODAL_ASSETS_INFO
    } from '@/store/mutations.type'
    import {checkSession} from '@/common/helpers'

    export default {
        name: "SymbolsModal",
        mounted() {
            const setTime = () => {
                let now = Date.now()

                this.time = now
                setTimeout(setTime, Math.ceil(now/60000)*60000 - now)
            }

            let now = Date.now()
            setTimeout(setTime, Math.ceil(now/60000)*60000 - now)
        },
        data() {
            return {
                tabActive: 'forex',
                search: '',
                sort: '',
                symbolsVisible: [],
                time: Date.now()
            }
        },
        methods: {
            hideModal() {
                this.$store.commit(MODAL, {
                    symbols: false
                })
            },
            showAssetsInfoModal(symbol){
                this.$store.commit(MODAL_ASSETS_INFO, {
                    symbol,
                })
                this.$store.commit(MODAL, {
                    assetsInfo: true,
                })
                this.$store.commit(MODAL, {
                    symbols: false
                })
            },
            toggleFavorite(symbol) {
                this.$store.commit(SYMBOL_FAVORITE, {
                    action: this.symbolsFavorite.indexOf(symbol) === -1 ? 'add' : 'remove',
                    symbol
                })
            },
            addSymbol(symbol) {
                if (this.modalSymbols.action === 'add') {
                    this.$store.commit(SYMBOL_SHOW, {
                        symbol,
                        frame: 1
                    })

                    this.$store.commit(MODAL, {
                        symbols: false
                    })
                } else {
                    if (!this.modalSymbols.id) {
                        this.$store.commit(SYMBOL_SHOW, {
                            symbol,
                            gridId: this.modalSymbols.gridId,
                            frame: 1
                        })
                    } else {
                        this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                            id: this.modalSymbols.id,
                            symbol
                        })
                    }

                    this.$store.commit(MODAL, {
                        symbols: false
                    })
                }
            },
            toggleSort() {
                switch (this.sort) {
                    case '':
                        this.sort = 'desc'
                        return
                    case 'desc':
                        this.sort = 'asc'
                        return
                    case 'asc':
                        this.sort = ''
                        return
                }
            },
            makeSort() {
                this.symbolsVisible = []

                for(let n in this.symbols) {
                    this.symbolsVisible.push(this.symbols[n])
                }

                if (this.sort === 'desc') {
                    this.symbolsVisible.sort((a,b) => { return b.quote.changePct - a.quote.changePct })
                    return
                }

                if (this.sort === 'asc') {
                    this.symbolsVisible.sort((a,b) => { return a.quote.changePct - b.quote.changePct })
                }
            }
        },
        computed: {
            ...mapGetters(['modals', 'symbols', 'sessions', 'symbolGroups', 'symbolsFavorite', 'modalSymbols', 'tokens','staticBase']),
            counts() {
                let list = {}

                for(let n in this.symbolGroups) {
                    list[this.symbolGroups[n]] = {
                        count: 0,
                        ico: 'language'
                    }
                }

                for(let n in this.symbols) {
                    if(!this.symbols[n].symbol.includes('demo')) {
                        list[this.symbols[n].market].count++
                    }
                }

                if (list.forex) {
                    list.forex.ico = 'language'
                }

                if (list.commodities) {
                    list.commodities.ico = 'offline_bolt'
                }

                if (list.crypto) {
                    list.crypto.ico = 'group_work'
                }

                if (list.stocks) {
                    list.stocks.ico = 'contactless'
                }

                if (list.indices) {
                    list.indices.ico = 'explore'
                }

                if (list.metals) {
                    list.metals.ico = 'view_stream'
                }

                if (list.nft) {
                    list.nft.ico = 'collections'
                }

                return list
            },
            sessionState() {
                let ret = {}

                for(let sym in this.sessions) {
                    ret[sym] = checkSession(this.sessions[sym], this.time)
                }

                return ret
            }
        },
        watch: {
            sort() {
                this.makeSort()
            },
            modals: {
                deep: true,
                handler(val) {
                    if (val.symbols) {
                        this.makeSort()
                    } else {
                        this.symbolsVisible = []
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .modal {
        position: fixed;
        top: 81px;
        left: 50%;
        width: 850px;
        margin-left: -400px;
        height: calc(100vh - 81px);
        display: flex;
        align-items: stretch;
        padding: 25px;
    }

    .markets {
        flex: 0 0 200px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .markets .item {
        padding-left: 15px;
        cursor: pointer;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        transition: background-color ease-in-out 0.3s;
    }

    .markets .item i {
        font-size: 20px;
        margin-right: 10px;
    }

    .markets .item .name {
        color: var(--white-to-black);
        font-size: 1.3em;
        font-weight: 500;
        padding: 18px 0;
        transition: border-bottom-color ease-in-out 0.3s;
        border-bottom: 1px solid var(--border);
    }

    .markets .item .num {
        padding: 2px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: var(--background-light);
        color: var(--white);
    }

    .markets .item:hover {
        background-color: var(--background);
    }

    .markets .item.active {
        background-color: var(--background-light);
    }
    .light-theme .markets .item.active {
        background-color: var(--theme_3-color-hover);
    }

    .markets .item:hover + .item .name,.markets .item:hover + .item .row:last-child {
        border-bottom-color: transparent;
    }

    .markets .item .row:last-child {
        flex: 1 1 auto;
        justify-content: flex-end;
        transition: border-bottom-color ease-in-out 0.3s;
        border-bottom: 1px solid var(--border);
        padding-right: 15px;
    }

    .details {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding-left: 15px;
        position: relative;
    }

    .search {
        border: 1px solid var(--border);
        background-color: transparent;
        border-radius: 3px;
        padding: 6px 8px 6px 30px;
    }

    .search-ico {
        position: absolute;
        top: 5px;
        left: 22px;
        font-size: 18px;
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
    }

    td span.symbol {
        transform: scale(0.36);
        left: -20px;
        top: -9px;
    }

    .symbols img {
        height: 30px;
        width: 30px;
        margin: 0 8px;
    }

    .symbols .name {
        font-size: 1.3em;
        color: var(--white-to-black);
        font-weight: 500;
    }

    .symbols .price {
        color: var(--white-to-black);
        font-size: 1.2em;
    }

    .symbols .payout {
        color: var(--green);
        font-size: 1.2em;
        font-weight: 600;
    }

    td, th {
        padding: 10px 0;
        text-align: center;
    }

    td:first-child, th:first-child {
        width: 200px;
    }

    th, td {
        border-bottom: 1px solid var(--border);
        transition: background-color ease-in-out 0.3s;
    }

    .fav {
        cursor: pointer;
    }

    .fav.active, .fav.active:hover {
        color: var(--brand)
    }

    .fav:hover {
        color: var(--white)
    }

    .symbols tr:hover td {
        cursor: pointer;
        background-color: var(--background-light);
    }
    .light-theme .symbols tr:hover td {
        background-color: var(--theme_3-color-hover);
    }

    .toggle-sort {
        cursor: pointer;
        position: relative;
    }

    .toggle-sort i {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .row.close.name, .price.close, .payout.close{
      color: #9f9f9f;
    }

    .name.close{
      color: #9f9f9f;
    }

    .price.clsoe{
      color: #9f9f9f;
    }

    @media (max-width: 1024px) {
        .modal {
            top: 66px;
            width: 100%;
            left: 0;
            margin-left: 0;
            padding: 10px;
            flex-direction: column;
            height: calc(100vh - 66px);
        }

        .markets {
            flex: 0 0 70px;
            flex-direction: row;
            justify-content: space-between;
            overflow-x: scroll;
        }

        .markets .item {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1 1 10%;
            padding: 5px 0 15px;
        }

        .markets .item .row:first-child {
            flex-direction: column;
            padding: 8px 8px 0;
        }

        .markets .item .name {
            font-size: 0.7em;
            padding: 8px 0;
            border-bottom: none;
        }

        .markets .item .row:last-child {
            padding-right: 0;
            border-bottom: none;
            justify-content: center;
        }

        .markets .item i {
            margin-right: 0;
        }

        .details {
            padding-left: 0;
            overflow-y: scroll;
        }

        .search-ico {
            left: 5px;
        }
    }

    @media (max-height: 400px) {
        .markets .item {
            padding: 3px 15px;
            align-items: stretch;
            overflow: hidden;
        }

        .markets .item .row {
            align-items: center;
        }

        .markets .item .name {
            padding: 2px 0;
        }
    }
</style>
