<template>
    <BasePanel class="bithide">
        <template #content>
            <h5 class="title title__medium">{{ tokens.newDesign.chooseCryptoWallet }}</h5>
            <SinglePspMethod
                :currencies="bithidePspMethod.tabs.map((i) => i.name) || []"
                :method="bithidePspMethod || {}"
                @update="makeDeposit"
            />
            <div class="address-viewer">
                <div class="title-view">
                    <h4 class="sub-title body__medium">{{ tokens.newDesign.depositAddress }}</h4>
                    <p class="desc body__small">{{ tokens.newDesign.scanTheQr }}</p>
                </div>
                <div class="qr-pad">
                    <div v-if="address" class="wallet-address">
                        <div class="qr" @click="openQrCodeModal(address)">
                            <QrcodeVue :value="address" :size="100" />
                        </div>
                        <div class="info">
                            <div class="wallet">
                                <p class="desc body__medium">{{ activeTab }} {{ tokens.newDesign.wallet }}</p>
                                <p class="desc body__small">{{ address }}</p>
                            </div>
                            <BaseButtonSmall @click.native="copyAddress(address)" :button-text="tokens.newDesign.copy"
                                :icon="'content_copy'" :button-style="'outlined-primary'" />
                        </div>
                    </div>
                    <div v-else class="desc-info">
                        <p class="body__medium wallet-info">
                            {{ 'Please choose currency' }}
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </BasePanel>
</template>

<script>
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import { cryptoPspList } from "@/common/crypto-psp-list"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"
import QrcodeVue from "qrcode.vue"
import { MODAL, MODAL_QR_CODE, NOTIFICATION, NOTIFICATION_ERROR, DEPOSIT_ADDRESS } from "@/store/mutations.type"
import { mapGetters } from "vuex"
import { STATIC_BASE } from "@/common/config";
import { DEPOSIT_REQUEST } from "@/store/actions.type"
import SinglePspMethod from "@/new-design/features/Deposits/BankCardMethods/ui/SinglePspMethod.vue"

export default {
    name: "BitHideTab",
    components: { BaseButtonSmall, BasePanel, QrcodeVue, SinglePspMethod },
    mounted(){
        if(this.activeAccount.id){
            this.$store.commit(DEPOSIT_ADDRESS, "")
        }
    },
    data() {
        return {
            selectedActiveTab: '',
            pspListCrypto: cryptoPspList,
            staticBase: STATIC_BASE,
        }
    },
    computed: {
        ...mapGetters(['pspList', 'tokens', 'depositAddress', 'activeAccount', 'modalFullscreen']),
        bithidePspMethod() {
            return {
                ...this.pspListCrypto.find((i) => i.alias === 'bithide'),
                ...this.pspList.find((i) => i.name === 'bithide')
            }
        },
        activeTab: {
            get() {
                return this.selectedActiveTab ||
                    (this.bithidePspMethod.tabs.length ?
                        this.bithidePspMethod.tabs[0].name : false);
            },
            set(value) {
                this.selectedActiveTab = value;
            }
        },
        address() {
            return (this.activeTab && this.depositAddress) || "";
        }
    },
    methods: {
        makeDeposit(data) {
            this.activeTab = data.info.currency
            this.$store.dispatch(DEPOSIT_REQUEST, {
                psp: 'bithide',
                amount: data.info.amount,
                currency: data.info.currency === 'USDT' ? 'USD' : data.info.currency
            }).catch(() => {
                this.$store.commit(NOTIFICATION_ERROR, "Something went wrong. Please try again later.")
            })
        },
        copyAddress(address) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    this.$store.commit(NOTIFICATION, this.newDesign.addressCopied)
                })
                .catch(() => {
                })
        },
        openQrCodeModal(value) {
            this.$store.commit(MODAL, {
                qrCode: true
            })
            this.$store.commit(MODAL_QR_CODE, value)
        }
    },
}
</script>

<style scoped>
.bithide {
    padding: 16px;
}

.sub-title,
.title {
    color: var(--System-OnSurface);
    text-align: left;
}

.cryptocurrencies {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 0 16px;
    white-space: nowrap;
    flex-wrap: wrap;
}

.title-view {
    padding: 16px 0;
}

.sub-title {
    margin-bottom: 4px;
    text-align: left;
}

.desc {
    color: var(--System-OnSurfaceVariant);
    text-align: left;
}

.qr-pad {
    padding: 16px;
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Surface1);
}

.wallet-address {
    display: flex;
    gap: 16px;
}

.qr {
    padding: 10px;
    border-radius: 2px;
    background: var(--white);
}

.info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.wallet p:first-child {
    margin-bottom: 4px;
}

.wallet p:last-child {
    word-break: break-word;
}

.button-small {
    height: fit-content;
}

.wallet-info {
    text-align: left;
}

.desc-info {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--System-OnSurfaceVariant);
    width: 100%;
}

.border-top {
    border-top: 1px solid var(--System-OnSurfaceVariant);
}
</style>
