<!--todo make as single base component-->

<template>
    <div class="withdrawal-panels">
        <h5 class="title title__medium">
            {{ tokens.fullScreenWithdrawal.withdrawalRequests }}
        </h5>
        <div v-if="withdrawals && Object.keys(withdrawals).length" 
            class="withdrawal-panel" 
            ref="withdrawals" 
            @scroll="loadMore()"
        >
            <table class="table-desktop">
                <thead>
                <tr>
                    <th>{{ tokens.fullScreenWithdrawal.amount }}</th>
                    <th>{{ tokens.fullScreenWithdrawal.currency }}</th>
                    <th>{{ tokens.fullScreenWithdrawal.time }}</th>
                    <th>{{ tokens.fullScreenWithdrawal.info }}</th>
                    <th>{{ tokens.fullScreenWithdrawal.status }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="withdrawal in withdrawals.slice(0,loaded)"
                    :key="withdrawal.id"
                >
                    <td>{{
                            currencySign(withdrawal.currency) + Math.abs(withdrawal.amount).toFixed(activeAccount.precision)
                        }}
                    </td>
                    <td>{{ withdrawal.currency }}</td>
                    <td>{{ new Date(withdrawal.time_created * 1000).toLocaleString() }}</td>
                    <td>{{ withdrawal.comment }}</td>
                    <td :class="tokens.statuses[withdrawal.status]">
                        <div class="status">
                            {{ tokens.statuses[withdrawal.status] }}
                            <i v-if="withdrawal.status === 0 && !hideWithdrawal"
                            @click="confirmCancelWithdrawal(withdrawal.id)"
                            class="material-icons cancel-withdrawal" title="Cancel withdrawal request">cancel</i>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="table-mobile">
                <WithdrawalRequest
                    v-for="withdrawal in withdrawals.slice(0,loaded)"
                    :key="withdrawal.id"
                    :withdrawal="withdrawal"
                />
            </div>
        </div>
        <BasePanel v-else class="info-panel">
            <template #content>
                <div class="info-content">
                    <h5 class="info-title title__medium">{{tokens.newDesign.withdrawalRequests}}</h5>
                    <p class="info-desc body_small">
                        {{tokens.newDesign.withdrawalRequestsTest1}} <br>
                        {{tokens.newDesign.withdrawalRequestsTest2}}
                    </p>
                </div>
            </template>
        </BasePanel>
        <div class="hollow-dots-spinner-wrapper">
            <div class="hollow-dots-spinner" v-if="dataIsLoaded && loaded < withdrawals.length">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL, MODAL_WITHDRAWAL_CANCEL} from "@/store/mutations.type"
import {CLOSE_WITHDRAWAL} from "@/common/config"
import {currencySign} from "@/common/helpers"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue";
import WithdrawalRequest from "@/new-design/entities/Withdrawal/ui/WithdrawalRequest.vue";

export default {
    name: "WithdrawalRequests",
    components: {WithdrawalRequest, BasePanel},
    data() {
       return {
           withdrawal: true,
           hideWithdrawal: CLOSE_WITHDRAWAL,
           dataIsLoaded: false,
           loaded: 15,
       }
    },
    methods: {
        confirmCancelWithdrawal(id) {
            this.$store.commit(MODAL_WITHDRAWAL_CANCEL, id)
            this.$store.commit(MODAL, {
                withdrawalCancel: true
            })
        },
        loadMore(){
            if (!this.$refs['withdrawals']) {
                return
            }
            let loadData = this.loaded
            if (this.$refs['withdrawals'].scrollTop + this.$refs['withdrawals'].clientHeight + 1 >= this.$refs['withdrawals'].scrollHeight) {
                this.dataIsLoaded = true
                setTimeout(() => {
                    if(loadData <= this.withdrawals.length) {
                        this.loaded += 5
                    }

                    if(loadData + 5 >= this.withdrawals.length){
                        this.loaded = this.withdrawals.length
                    }
                    this.dataIsLoaded = false
                }, 1500)
            }
        },
        currencySign
    },
    computed: {
        ...mapGetters(['withdrawals','tokens','activeAccount'])
    }
}
</script>

<style scoped>
.title {
    margin-bottom: 12px;
    margin-top: 40px;
    color: var(--System-OnSurfaceVariant);
}

.withdrawal-panel {
    height: 300px;
    overflow: auto;
}

.info-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    padding: 32px 0;
}

.info-title {
    color: var(--System-OnSurfaceVariant);
    font-weight: 500;
}

.info-desc {
    color: var(--System-OnSurfaceVariant);
    text-align: center;
    font-weight: 400;
}

table {
    width: 100%;
    position: relative;
    background: var(--System-Surface, #161B24);
    border-collapse: collapse;
    border: 1px solid var(--System-Surface5);

}

thead tr th:hover {
    cursor: pointer;
    background: var(--System-Surface3, #252B36);
}

tr:not(thead  tr):hover {
    background: var(--System-Surface3, #252B36);
}

td, th {
    white-space: nowrap;
    text-align: left;
    padding: 7px 7px 6px;
    background: var(--System-Surface);
}

th {
    position: sticky;
    top: 0px;
    border: 1px solid var(--System-Surface5);
    font-weight: 500;
    text-transform: capitalize;
}

.status {
    display: flex;
    align-items: center;
    gap: 4px;
}

th::after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

th::before {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

td {
    color: var(--System-OnSurface);
    border-right: 1px solid var(--System-Surface5);
}

td:last-child {
    border: none;
}

td:last-child span {
    cursor: pointer;
}

td:last-child span {
    margin-right: 15px;
}

td:last-child span:last-child {
    margin-right: 0;
}

td:first-child img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
}

td .symbol {
    zoom: 0.21875;
    margin-right: 23px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px;
}

td span:has(i) {
    display: flex;
    align-items: center;
}

td:has(i){
    display: flex;
    align-items: center;
}

td i {
    font-size: 16px;
    color: var(--System-Primary);
    height: 100%;
}

.red {
    color: var(--Custom-RedFixed)
}

.green {
    color: var(--Custom-GreenFixed)
}

thead tr th:first-child,
td:has(span.symbol){
    position: sticky;
    left: 0;
    z-index: 5;
}

thead tr th:first-child{
    top: 0;
    z-index: 6;
}

thead tr th:first-child::after,
td:has(span.symbol)::after{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    right: -1px;
    top: 0;
}

thead tr th:first-child::before,
td:has(span.symbol)::before{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    left: -1px;
    top: 0;
}

.sort-button{
    display: inline-block;
    position: relative;
    width: 10px;
    height: 20px;
    transform: translateY(2px);
    margin-top: -3px;
    margin-bottom: -4px;
}

.sort-button::after,
.sort-button::before{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: var(--System-Outline);
    transition: color .2s ease;
}

.sort-button::before{
    content: 'arrow_drop_up';
    top: 35%;
}

.sort-button::after{
    content: 'arrow_drop_down';
    top: 65%;
}

.sort-button.up::before,
.sort-button.down::after {
    color: var(--System-Primary);
}

.sort-button.down::before,
.sort-button.up::after {
    color: var(--System-OutlineVariant);
}

.cancel-withdrawal{
    cursor: pointer;
}

@-moz-document url-prefix() {
    td:first-child {
        position: relative;
        padding-left: 28px !important;;
    }
}

.hollow-dots-spinner-wrapper {
    height: 50px;
    position: relative;
}

.hollow-dots-spinner, .hollow-dots-spinner * {
    box-sizing: border-box;
}

.hollow-dots-spinner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 15px;
    width: calc(30px * 3);
    margin: 20px auto 0;
}

.hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    border: calc(15px / 5) solid #fff;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.table-mobile{
    display: none;
}

@media (max-width: 768px) {
    .table-desktop{
        display: none;
    }

    .table-mobile{
        display: block;
    }

    .withdrawal-panel {
        display: flex;
        padding: 12px;
        border: 1px solid var(--System-OutlineVariant);
        border-radius: var(--Corner-small);
        flex-direction: column;
    }
}
</style>
