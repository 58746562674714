<template>
    <div class="chart">
        <div class="chart-inner">
            <highcharts :options="theme_3PieChart" ref="theme_3PieChart" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isMobile } from '@/common/helpers'
import { BRAND } from "@/common/config"
export default {
    name: "BasePieChart",
    mounted() {
        this.$refs.theme_3PieChart?.chart?.reflow()
        this.initTheme_3PieChart()
    },
    data() {
        return {
            isMobile: isMobile(),
            brand: BRAND,
            theme_3PieChart: {
                credits: false,
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    backgroundColor: 'transparent',
                    height: 200,
                    marginRight: 150
                },
                title: {
                    text: '',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 15,
                    x: -70,
                    style: { "color": '#E3E2E9', "fontSize": "12px" }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    style: {
                        fontSize: window.innerWidth > 1400 ? ".8vw" : "12px",
                    }
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        borderWidth: 0,
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        },
                        size: '75%',
                    }
                },
                legend: {
                    enabled: true,
                    itemStyle: { "fontWeight": "normal", "fontSize": "11px", },
                    layout: 'vertical',
                    itemMarginTop: 5,
                    align: 'right',
                    verticalAlign: 'middle',
                    x: -70,
                    itemHoverStyle: { "color": "#999999" }
                },
                series: [{
                    name: 'Orders',
                    colorByPoint: true,
                    innerSize: '75%',
                    showInLegend: true,
                    data: [
                        {
                            name: '',
                            y: 1,
                            color: '#98A5BD',

                        },
                        {
                            name: '',
                            y: 1,
                            color: '#5397C7',
                        },
                        {
                            name: '',
                            y: 1,
                            color: '#B83D54',

                        },
                    ]
                }]
            },
        }
    },
    methods: {
        initTheme_3PieChart() {
            let pending = 0
            for (const item in this.orders) {
                if (this.orders[item].type === 2 || this.orders[item].type === 5) {
                    pending++
                }
            }

            this.theme_3PieChart.series[0].data[0].y = pending
            this.theme_3PieChart.series[0].data[1].y = this.cntTotal
                ? (this.cntTotal - this.dashboardData.stats.cnt_profit) === 0
                    ? 0
                    : this.cntTotal - this.dashboardData.stats.cnt_profit
                : 1
            this.theme_3PieChart.series[0].data[2].y = this.dashboardData.stats.cnt_profit
                ? parseInt(this.dashboardData.stats.cnt_profit)
                : 0

            let result =
                this.cntTotal === 0
                    ? 0
                    : (this.theme_3PieChart.series[0].data[2].y / this.cntTotal) * 100
            result /= pending === 0 ? 1 : 0
            this.theme_3PieChart.title.text = isNaN(result) ? "0%" : result.toFixed(1) + "%"

            this.theme_3PieChart.series[0].data[0].name = this.tokens.widgetOrders.pending
            this.theme_3PieChart.series[0].data[1].name = this.tokens.fullScreenDashboard.noOrderYet
            this.theme_3PieChart.series[0].data[2].name = this.tokens.fullScreenDashboard.closedWithProfit

            this.theme_3PieChart.legend.itemStyle.color = this.lightTheme ? '#000' : '#fff'
            this.theme_3PieChart.title.style.color = this.lightTheme ? '#000' : '#fff'


            if (this.cntTotal !== 0 && this.theme_3PieChart.series[0].data[0].y === 0 || this.theme_3PieChart.series[0].data[2].y !== 0) {
                // this.theme_3PieChart.series[0].data[1].color = '#B41717'
                this.theme_3PieChart.series[0].data[1].name = this.tokens.fullScreenDashboard.closedWithLoss
            }
        },
        updateTheme_3PieChart() {
            if (this.modals.fullscreen) {
                this.$refs.theme_3PieChart?.chart?.reflow();
                this.initTheme_3PieChart()
            }
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals', 'accounts', 'dashboardData', 'lightTheme']),
        cntTotal() {
            let cntTotal = 0

            for (const cntStat in this.dashboardData.stats.accounts) {
                cntTotal += this.dashboardData.stats.accounts[cntStat].cnt_total
            }

            return cntTotal
        },
    },
    watch: {
        modals: {
            deep: true,
            handler() {
                this.updateTheme_3PieChart()
            }
        },
        lightTheme: {
            deep: true,
            handler() {
                this.updateTheme_3PieChart()
            }
        },
        tokens: {
            deep: true,
            handler() {
                this.updateTheme_3PieChart()
            }
        },
    },
}
</script>

<style scoped>
.chart {
    min-height: 152px;
}

.chart-inner {
    width: 450px;
    height: 200px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .chart-inner{
        position: relative;
        left: -50px;
    }
}
</style>