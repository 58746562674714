<template>
        <div class="orders-pane" :class="{close: !ordersPane}">
            <div class="tabs" :class="{'border-bottom': !ordersPane}">
                <BaseTabs
                    :activeTab="getKeyByValue(tabActive)"
                    :tabs="[
                        tokens.ordersPane.ordersActive.toUpperCase(),
                        tokens.ordersPane.ordersHistory.toUpperCase()
                    ]"
                    :tabStyle="{
                        'font-size': '1.1em',
                        'padding': '4px 20px',
                        'max-width': 'max-content',
                        'border-bottom': '1px solid transparent',
                    }"
                    @updateActiveTab="activateTab(tabs[$event])"
                />
            </div>
            <div class="tables-container" id="tables-container" :class="{hide: !ordersPane}">
                <OrdersTable  :class="{hide: tabActive !== 'active'}" />
                <HistoryTable :class="{hide: tabActive !== 'history'}" :active="tabActive === 'history'" />
            </div>
            <i v-if="ordersPane" class="material-icons expander pointer" @click="closeOrdersPane()">remove</i>
            <i v-else class="material-symbols-outlined expander pointer" @click.stop="openOrdersPane()">expand_content</i>
        </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ORDERS_PANE, HISTORY_TABLE_IS_ACTIVE} from '@/store/mutations.type'
import OrdersTable from '@/new-design/not-sorted/new/OrdersTable.vue'
import HistoryTable from '@/new-design/not-sorted/new/HistoryTable.vue'
import BaseTabs from "@/new-design/shared/ui/tabs/BaseTabs"

export default {
    name: 'OrdersPane',
    data() {
        return {
            tabs: {
                0: 'active',
                1: 'history'
            },
            tabActive: 'active'
        }
    },
    components: {HistoryTable, OrdersTable, BaseTabs},
    methods: {
        closeOrdersPane() {
            this.$store.commit(ORDERS_PANE, false)
        },
        openOrdersPane() {
            this.$store.commit(ORDERS_PANE, true)
        },
        activateTab(name) {
            this.tabActive = name
            if(name === 'history') {
                this.$store.commit(HISTORY_TABLE_IS_ACTIVE, true)
            } else {
                this.$store.commit(HISTORY_TABLE_IS_ACTIVE, false)
            }
        },
        getKeyByValue (value) {
            return Object.keys(this.tabs).find(key => this.tabs[key] === value);
        }
    },
    computed: {
        ...mapGetters(['ordersPane','tokens'])
    }
}
</script>

<style scoped>
    .orders-pane {
        flex: 1 1 110px;
        min-height: 110px;
        width: 100%;
        padding: 5px;
        border-left: 1px solid var(--System-OutlineVariant);
        position: relative;
        display: grid;
        grid-template-rows: auto 1fr;
        margin-bottom: -6px;
        z-index: 1;
    }

    .tabs {
        display: flex;
        align-items: flex-end;
        margin: 0;
    }

    .tables-container {
        overflow-y: auto;
    }

    .expander {
        position: absolute;
        right: 14px;
        top: 4px;
        font-size: 30px;
        cursor: pointer;
    }

    .close {
        min-height: auto;
        flex: none
    }

    .border-bottom {
        border-bottom: 1px solid var(--System-Surface5);
    }

    @media (max-width: 520px) {
        .orders-pane{
            /* width:93vw; */
        }
    }
</style>
